import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { css, StyleSheet } from "aphrodite";
import classnames from "classnames";
import { Button } from "react-materialize";

import COLORS from "../../utils/colors";

const styles = StyleSheet.create({
  About_container: {
    position: 'relative',
    marginBottom: '0',
    display: 'flex',
    minHeight: '800px',
    backgroundColor: COLORS.primaryBlue,
  },
  About_innerContainer: {
    marginTop: '100px',
    marginBottom: '100px',
    fontSize: '26px',
    color: COLORS.white,
  },
  About_registerButton: {
    backgroundColor: COLORS.white,
    color: COLORS.black,
  },
});

class About extends Component {
  render() {
    const {
      auth: {
        isAuthenticated,
      },
    } = this.props;

    return (
      <div className={css(styles.About_container)}>
        <div className={classnames(css(styles.About_innerContainer), "row container")}>
          The <b>Riot Record</b> is the best place to show your friends, family and others exactly how deep your Panthers pride goes!
          <br />
          <br />
          Every game in Panthers history is in our database, so create a profile today and start logging your Panthers experience - the more games you’ve attended, the more badges you’ll earn!
          <br />
          <br />
          Be sure to check into every game you’ve attended, mark your seat location and see who else may have been at the game you didn’t even know about - every star on the seating chart is another Panthers fan who was at the game with you!
          <br />
          <br />
          For each game you record yourself as having attended, you’ll start to earn badges, like for seeing Christian McCaffrey rush for 1,000 yards with your own eyes, attending a game at historic stadiums and even for how many Roaring Riot tailgates you’ve been to!
          <br />
          <br />
          Be sure to check out the leaderboards to see where you rank - and we’ll keep track of your personal stats as well.
          <br />
          <br />
          You <b>Riot</b> - Now You Have A <b>Record</b>.
          <br />
          <br />
          {isAuthenticated ? (
            <Link to="/games">
              <Button className={classnames(css(styles.About_registerButton), "btn btn-large hoverable white accent-3")}>
                Check into some games!
              </Button>
            </Link>
          ) : (
            <Link to="/register">
              <Button className={classnames(css(styles.About_registerButton), "btn btn-large hoverable white accent-3")}>
                Create an account
              </Button>
            </Link>
          )}
        </div>
      </div>
    );
  }
}

About.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(About);
