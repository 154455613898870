import React, { Component } from "react";
import { css, StyleSheet } from "aphrodite";
import classnames from "classnames";

import COLORS from "../../utils/colors";

const styles = StyleSheet.create({
  Privacy_container: {
    position: 'relative',
    marginBottom: '0',
    minHeight: '800px',
    backgroundColor: COLORS.primaryBlue,
  },
  Privacy_innerContainer: {
    marginTop: '100px',
    marginBottom: '100px',
    fontSize: '14px',
    color: COLORS.white,
  },
});

class Privacy extends Component {
  render() {
    return (
      <div className={css(styles.Privacy_container)}>
        <div className={classnames(css(styles.Privacy_innerContainer), "row container")}>
          <h2>Privacy Policy</h2>
          This document was last updated on October 14, 2019.
          <br />
          <br />
          This Privacy Policy governs the manner in which The Riot Record. (collectively, “Company”, “our”, “us”, or “we”) collects,
          uses, maintains and discloses information collected from users (each, a ”User”) of the https://www.theriotrecord.com website (”Site”).
          This Policy also applies to any of our other websites that post this Policy. By using the Site, you agree that you have read, understood,
          and accept all of the terms and conditions of this Privacy Policy.
          <br />
          <br />
          Any capitalized terms not defined herein shall have the meaning set forth in our Terms of Service (“Terms of Use”).
          <br />
          <br />
          <h5>Collection of your Personal Information</h5>
          In order to better provide you with products and services offered on our Site, we may collect personally identifiable information, such as your:
          <br />
          <br />
          First and Last Name
          <br />
          E-mail Address
          <br />
          <br />
          We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site,
          fill out a form, and in connection with other activities, services, features or resources we make available on our Site.
          Users may be asked for, as appropriate, email address. Users may, however, visit our Site anonymously. We will collect personal identification information
          from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it
          may prevent them from engaging in certain Site related activities.
          <br />
          <br />
          <h5>Non-personal identification information</h5>
          We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name,
          the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other
          similar information. We may also collect non-personal identification information about you from other sources, such as information collected from using third party service.
          We may add this to information we get from you on this Site.
          <br />
          <br />
          <h5>Web browser cookies</h5>
          We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer or mobile device until you delete them) to help you
          personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer.
          Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
          <br />
          <br />
          We use two broad categories of cookies:
          <br />
          (1) first party cookies, served directly by us to your computer or mobile device, which are used only by us to recognize your computer or mobile device when it revisits our Site; and
          <br />
          (2) third party cookies, which are served by service providers on our Site, and can be used by such service providers to recognize your computer or mobile device when it visits other websites.
          <br />
          <br />
          One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page.
          This simplifies the process of recording your personal information, such as mailing addresses, shipping addresses, and so on. When you return to the Site, the information you previously provided can be retrieved,
          so you can easily use the features that you customized.
          <br />
          <br />
          Our Site uses the following types of cookies for the purposes set out below:
          <br />
          <br />
          Essential Cookies
          <br />
          These cookies are essential to provide you with services available through our Site and to enable you to use some of its features. Without these cookies, the services that you have asked for may not be able to be provided or fully functional, and we only use these cookies to provide you with those services.
          <br />
          <br />
          Functionality Cookies
          <br />
          These cookies allow our Site to remember choices you make when you use our Site, such as remembering your language preferences, remembering your login details and remembering the changes you make other parts of our Site which you can customize. The purpose of these cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you visit our Site
          <br />
          <br />
          Analytics and Performance Cookies
          <br />
          These cookies are used to collect information about traffic to our Site and how users use our Site. The information gathered does not identify any individual visitor. We use this information to help operate our Site more efficiently and to monitor the level of activity on our Site.
          <br />
          <br />
          We use Google Analytics for this purpose. Google Analytics uses its own cookies. It is only used to improve how our Site works. You can find out more information about Google Analytics cookies here: https://developers.google.com/analytics/resources/concepts/gaConceptsCookies
          <br />
          You can find out more about how Google protects your data here: www.google.com/analytics/learn/privacy.html.}
          <br />
          You can prevent the use of Google Analytics relating to your use of our Site by downloading and installing the browser plugin available via this link: https://tools.google.com/dlpage/gaoptout?hl=en-US.}
          <br />
          You have the ability to accept or decline cookies. You can disable these pursuant to your web browser’s instructions (usually located within the “settings,” “help” “tools” or “edit” facility). Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Site’s services or websites you visit.
          <br />
          <br />
          <h5>Use of Information</h5>
          Below is a description of all of the ways we intend to use your information:
          <br />
          <br />
          We use your personal information to operate, maintain, and improve the Site, products, and our Services.
          <br />
          We use your personal information to respond to and provide and improve customer service.
          <br />
          We use your personal information to send information including confirmations, technical notices, updates, security alerts, and support and administrative messages.
          <br />
          With your consent, we use your personal information to communicate about promotions, upcoming events, and other news, including news about products and services offered by us and our selected partners.
          <br />
          We use your personal information to conduct security investigations and risk assessments.
          <br />
          We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.
          <br />
          We may use feedback you provide to improve our products and services.
          <br />
          We use your personal information as described in the ”Sharing of your Personal Information” section below.
          <br />
          <br />
          <h5>Sharing of Your Personal Information</h5>
          We may share personal information as follows:
          <br />
          <br />
          We may share personal information with your express consent.
          <br />
          We may share personal information when we do a business deal, or negotiate a business deal, involving the sale or transfer of all or a part of our business or assets. These deals can include any merger, financing, acquisition, or bankruptcy transaction or proceeding.
          <br />
          We may share personal information for legal, protection, compliance and safety purposes.
          <br />
          We may share information to comply with applicable laws.
          <br />
          We may share information to respond to law enforcement, officials, regulatory agencies and other lawful requests, subpoenas and legal processes.
          <br />
          We may share information to protect the rights and property of us, our agents, customers, and others. This includes enforcing our agreements, policies, and terms of use.
          <br />
          We may share information in an emergency. This includes protecting the safety of our employees and agents, our customers, or any person.
          <br />
          We may share your information with certain service providers to help provide our Services.
          <br />
          We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.
          <br />
          We may share your information with the third party service providers to help us operate our business and the Site or administer activities on your behalf provided that you have given us your permission.
          <br />
          We do not sell, trade, or rent Users personal identification information to others.
          <br />
          <br />
          <h5>Information Choices and Changes</h5>
          You may send requests about personal information to our Contact Information below regarding the following:
          <br />
          <br />
          Opt-out.
          <br />
          You may contact us anytime to opt-out of: (i) direct marketing communications; and (ii) any new processing of your personal information that we may carry out beyond the original purpose. Please note that your use of some of the Site and the Services may be ineffective upon opt-out. Our marketing emails also tell you how to “opt-out.” If you opt out, we may still send you non-marketing emails on an as-needed basis.
          <br />
          <br />
          Access.
          <br />
          You may access the information we hold about you at any time by contacting us directly.
          <br />
          <br />
          Amend.
          <br />
          You can also contact us to update or correct any inaccuracies in your personal information.
          <br />
          <br />
          Move.
          <br />
          Your personal information is portable – i.e. you to have the flexibility to move your data to other service providers as you wish.
          <br />
          <br />
          Erase and forget.
          <br />
          In certain situations, for example when the information we hold about you is no longer relevant or is incorrect, you can request that we erase your data.
          <br />
          <br />
          If you wish to exercise any of these rights, please contact us using the details in Contact Information section below. In your request, please make clear: (i) what personal information is concerned; and (ii) which of the above rights you would like to enforce. For your protection, we may only implement requests with respect to the personal information associated with the particular email address that you use to send us your request, and we may need to verify your identity before implementing your request. We will try to comply with your request as soon as reasonably practicable and in any event, within one month of your request.
          <br />
          <br />
          If you are in the European Economic Area, United Kingdom and/or Switzerland at the time of data collection, you have the right to lodge a complaint to a Data Protection Authority about our collection and use of your data. For more information, please contact your local data protection authority or agency.
          <br />
          <br />
          <h5>Tracking User Behavior</h5>
          We may keep track of the websites and pages our users visit on the Site in order to determine what services are the most popular.
          <br />
          <br />
          <h5>Security of your Personal Information</h5>
          When personal information is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol. We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.
          <br />
          <br />
          We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.
          <br />
          <br />
          <h5>Retention</h5>
          We will only retain your personal information for as long as is reasonably required for you to use the Site and the Services unless a longer retention period is required or permitted by law (for example for regulatory purposes).
          <br />
          <br />
          <h5>Minors</h5>
          We do not knowingly collect personally identifiable information from minors. If you are under the age of eighteen, you must ask your parent or guardian for permission to use this website. If you believe that a child under the age of 13 has provided information to us, please notify us at justinkchen14@gmail.com. If we inadvertently collect information of children under 13, we will delete that information immediately upon notice. We are not liable for any damages that may result from the user’s misrepresentation of age.
          <br />
          <br />
          <h5>External Data Storage Sites</h5>
          Your information, including personal information that we collect from you, may be transferred to, stored at and processed by us and our affiliates and other third parties outside the country in which you reside, including, but not limited to the United States, where data protection and privacy regulations may not offer the same level of protection as in other parts of the world. By using our Site, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your information is treated securely and in accordance with this Policy.
          <br />
          <br />
          <h5>Updates</h5>
          The Riot Record has the discretion to update this privacy policy at any time. When we do, we will post a notification on the main page of our Site. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
          <br />
          <br />
          <h5>Your acceptance of these terms</h5>
          By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
          <br />
          <br />
          <h5>Contacting us</h5>
          If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at: justinkchen14@gmail.com.
        </div>
      </div>
    );
  }
}

export default Privacy;
