import WINDOW_DIMENSIONS from '../utils/windowDimensions';

import {
  UPDATE_WINDOW_DIMENSION,
  UPDATE_SCROLL_POSITION,
} from "./types";

const { MOBILE, TABLET, TABLET_LANDSCAPE } = WINDOW_DIMENSIONS;

export const calculateScrollPercent = () => {
  const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
  return (window.scrollY / scrollHeight) * 100;
}

export const updateWindowDimension = () =>
  (dispatch) =>
    dispatch({
      type: UPDATE_WINDOW_DIMENSION,
      payload: {
        width: window.outerWidth,
        height: window.outerHeight,
        isMobile: window.outerWidth <= MOBILE,
        isTablet: window.outerWidth <= TABLET,
        isTabletLandscape: window.outerWidth <= TABLET_LANDSCAPE,
      },
    });

export const updateScrollPosition = () =>
  (dispatch) =>
    dispatch({
      type: UPDATE_SCROLL_POSITION,
      payload: {
        scrollPercent: calculateScrollPercent(),
      },
    });
