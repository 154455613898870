import React, { Component } from "react";
import { Link } from "react-router-dom";
import { css, StyleSheet } from "aphrodite";
import classnames from "classnames";
import { Button } from "react-materialize";

import COLORS from "../../utils/colors";

const styles = StyleSheet.create({
  SuccessfulConnection_container: {
    position: 'relative',
    marginBottom: '0',
    display: 'flex',
    minHeight: '800px',
    backgroundColor: COLORS.primaryBlue,
  },
  SuccessfulConnection_innerContainer: {
    marginTop: '100px',
    marginBottom: '100px',
    fontSize: '26px',
    color: COLORS.white,
  },
  SuccessfulConnection_backButton: {
    backgroundColor: COLORS.white,
    color: COLORS.black,
  },
});

class SuccessfulConnection extends Component {
  render() {
    return (
      <div className={css(styles.SuccessfulConnection_container)}>
        <div className={classnames(css(styles.SuccessfulConnection_innerContainer), "row container")}>
          You have successfully connected your social account.
          <br />
          <br />
          <Link to="/">
            <Button className={classnames(css(styles.SuccessfulConnection_backButton), "btn btn-large hoverable white accent-3")}>
              Go Back to Home
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default SuccessfulConnection;
