import { StyleSheet } from "aphrodite";
import COLORS from "../../utils/colors";
import WINDOW_DIMENSIONS from "../../utils/windowDimensions";

const { TABLET_LANDSCAPE_MEDIA_QUERY, MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

export default StyleSheet.create({
  Games_container: {
    position: 'relative',
    marginBottom: '0',
    display: 'flex',
    minHeight: '800px',
    backgroundColor: COLORS.primaryBlue,
  },
  Games_header: {
    fontSize: '32px',
    fontWeight: 600,
    color: COLORS.white,
    marginTop: '50px',
  },
  Games_stadiumBanner: {
    fontSize: '32px',
    fontWeight: 600,
    border: `2px solid ${COLORS.white}`,
    color: COLORS.white,
    padding: '15px',
    textAlign: 'center',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      fontSize: '24px',
    },
  },
  Games_dropdown: {
    color: COLORS.white,
  },
  Games_stadiumImageContainer: {
    position: 'relative',
  },
  Games_stadiumImage: {
    width: '100%',
  },
  Games_boxScoreInfo: {
    margin: '40px 0',
    padding: '20px',
    backgroundColor: COLORS.white,
  },
  Games_instructionOverlay: {
    textAlign: 'center',
    color: COLORS.white,
    marginTop: '5px',
    marginBottom: '5px',
  },
  Games_checkinButton: {
    textAlign: 'center',
  },
  Games_instructions: {
    marginBottom: '100px',
  },
  Games_collectionItem:{
    cursor: 'pointer',
    color: COLORS.white,
    backgroundColor: COLORS.primaryBlue,
    ':hover': {
      color: COLORS.gray,
      backgroundColor: COLORS.lightGray,
    },
  },
  Games_collectionItemActive: {
    backgroundColor: COLORS.gray,
  },
  Games_checkbox: {
    textAlign: 'right',
    width: '100%',
    marginBottom: '10px',
    marginTop: '-10px',
    display: 'flex',
    justifyContent: 'center',
  },
  Games_checkboxLabel: {
    color: COLORS.white,
    marginLeft: '-10px',
  },
  Games_extraStatsContainer: {
    marginTop: '20px',
  },
  Games_profilePicture: {
    position: 'relative',
    height: '100px',
    width: '100px',
    margin: 'auto',
    backgroundColor: COLORS.primaryBlue,
    borderRadius: '4px',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    border: `1px solid ${COLORS.primaryBlue}`,
    minWidth: '100px',
    [MOBILE_MEDIA_QUERY]: {
      height: '75px',
      width: '75px',
      minWidth: '75px',
    },
  },
  Games_attendeeBox: {
    whiteSpace: 'nowrap',
    overFlowX: 'hidden',
  },
  Games_memoryCarousel: {
    backgroundColor: 'rgb(0, 0, 0, 0.1)',
  },
  Games_paginationBar: {
    textAlign :'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }, 
  Games_paginationBarArrow: {
    cursor: 'pointer',
  },
  Games_disabled: {
    color: COLORS.lightGray,
  },
  Games_pictureFrameMenu: {
    position: 'absolute',
    bottom: '20px',
    left: '20px',
  },
  Games_pictureFrameMenuDropdown: {
    width: '200px',
  },
  Games_menuOption: {
    display: 'flex',
    alignItems: 'center',
  },
  Games_menuIcon: {
    marginRight: '10px',
  },
  Games_closeButton: {
    cursor: 'pointer',
  },
  Games_memoryImageContainer: {
    height: '80vh',
    width: '100%',
    position: 'relative',
  },
  Games_memoryImage: {
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  Games_taggingOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: 'rgb(0, 0, 0, 0.2)',
  },
  Games_tagCloseButton: {
    backgroundColor: COLORS.white,
    width: 'fit-content',
    padding: '10px 20px',
    bottom: '20px',
    right: '30px',
    position: 'absolute',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  Games_tag: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    padding: '40px',
    borderRadius: '4px',
    backgroundColor: `rgb(0, 0, 0, 0.01)`,
    color: `rgb(0, 0, 0, 0.01)`,
    ':hover': {
      backgroundColor: `rgb(0, 0, 0, 0.1)`,
      color: COLORS.black,
    }
  },
  Games_tagPreview: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    padding: '40px',
    borderRadius: '4px',
    backgroundColor: `rgb(0, 0, 0, 0.2)`,
    color: `rgb(0, 0, 0, 0.2)`,
  },
  Games_tagDeleteIcon: {
    cursor: 'pointer',
    color: `rgb(0, 0, 0, 0.01)`,
    ':hover': {
      backgroundColor: `rgb(0, 0, 0, 0.1)`,
      color: COLORS.black,
    }
  },
});