import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { css, StyleSheet } from "aphrodite";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Button } from "react-materialize";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { fetchBoxScoreById } from "../../actions/boxScoreActions";
import { fetchUser } from "../../actions/userActions";
import COLORS from "../../utils/colors";
import { TEAM_ABBREVIATION_MAP } from "../../utils/constants";
import { badgeIcons } from "../../utils/badgeHelpers";
import WINDOW_DIMENSIONS from "../../utils/windowDimensions";
import GameMap from "../gameMap/GameMap";
import grayClaw from "../../img/gray-claw.png";
import largeBackgroundPattern from "../../img/backgrounds/large-background-pattern.png";

const { TABLET_LANDSCAPE_MEDIA_QUERY, MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  Dashboard_container: {
    position: 'relative',
    marginBottom: '0',
    display: 'flex',
  },
  Dashboard_heroContainer: {
    position: 'relative',
    padding: '50px',
    borderRight: `4px solid ${COLORS.white}`,
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      border: 'none',
      padding: '15px',
    },
  },
  Dashboard_noPadding: {
    padding: '0',
  },
  Dashboard_sideStats: {
    position: 'relative',
    top: '0',
    minHeight: '560px',
  },
  Dashboard_userName: {
    color: COLORS.white,
    textAlign: 'center',
    width: '100%',
    fontSize: '24px',
    fontWeight: 600,
    padding: '0 10px',
  },
  Dashboard_gameCount: {
    color: COLORS.white,
    textAlign: 'center',
    width: '100%',
    fontSize: '24px',
    fontWeight: 600,
    padding: '0 10px',
  },
  Dashboard_smallText: {
    fontSize: '10px',
    letterSpacing: '2px',
    color: COLORS.white,
    textAlign: 'center',
    width: '100%',
  },
  Dashboard_extraBadges: {
    fontSize: '12px',
    letterSpacing: '2px',
    color: COLORS.white,
    textAlign: 'center',
    width: '100%',
    marginTop: '-10px',
  },
  Dashboard_profilePicture: {
    height: '125px',
    width: '125px',
    backgroundColor: COLORS.primaryBlue,
    border: `1px solid ${COLORS.white}`,
    borderRadius: '4px',
    margin: '25px auto',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
  },
  Dashboard_gameContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    zIndex: 15,
  },
  Dashboard_sideImage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '10',
    height: '200px',
    width: '200px',
  },
  Dashboard_badgesContainer: {
    marginTop: '30px',
    marginBottom: '20px',
    width: '100%',
    textAlign: 'center',
  },
  Dashboard_badgesHeader: {
    color: COLORS.white,
    fontWeight: 'bold',
    fontSize: '18px',
  },
  Dashboard_badgesGrid: {
    color: COLORS.white,
    fontSize: '14px',
  },
  Dashboard_scheduleContainer: {
    backgroundColor: COLORS.white,
    backgroundImage: `url("${largeBackgroundPattern}")`,
    backgroundRepeat: 'repeat',
    backgroundSize: '50%',
    minHeight: '700px',
    paddingBottom: '100px',
    marginBottom: '-80px',
  },
  Dashboard_scheduleHeader: {
    color: COLORS.primaryBlue,
    fontWeight: 800,
    fontSize: '36px',
    letterSpacing: '2px',
    textAlign: 'center',
    paddingTop: '40px',
    marginTop: '0',
  },
  Dashboard_schedule: {
    marginBottom: '50px',
  },
  Dashboard_scheduleGrid: {
    color: COLORS.black,
    fontWeight: 'bold',
  },
  Dashboard_gridHeader: {
    color: COLORS.gray,
    fontSize: '24px',
    fontWeight: 'bold',
    letterSpacing: '2px',
    marginTop: '10px',
    marginBottom: '10px',
    [MOBILE_MEDIA_QUERY]: {
        textAlign: 'center',
    },
  },
  Dashboard_scheduleColumn: {
    fontWeight: 'bold',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  Dashboard_badge: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  Dashboard_link: {
    color: COLORS.black,
    cursor: 'pointer',
  },
  Dashboard_inverseLink: {
    color: COLORS.white,
    cursor: 'pointer',
  },
  Dashboard_mapHeader: {
    color: COLORS.white,
    textAlign: 'center',
    paddingTop: '20px',
    [MOBILE_MEDIA_QUERY]: {
      paddingTop: '0',
      fontSize: '24px',
    },
  },
  Dashboard_checkInButtonContainer: {
    textAlign: 'center',
    paddingTop: '25px',
  },
  Dashboard_checkInButton: {
    backgroundColor: COLORS.primaryBlue,
  },
  Dashboard_checkInButtonInverse: {
    backgroundColor: COLORS.white,
    color: COLORS.black,
  },
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastBoxScore: {},
      mostSeenOpponent: undefined,
    };
  }

  async componentDidMount() {
    const {
      auth: {
        isAuthenticated,
        user,
      },
      fetchUser,
    } = this.props;

    if (!isAuthenticated) {
      this.props.history.push("/");
    }

    const fetchedUser = await fetchUser(user._id);
    if (fetchedUser && fetchedUser.games && fetchedUser.games.length > 0) {

      const opponentCount = {};
      let mostSeenOpponentCount = 0;
      let mostSeenOpponent = undefined;
      fetchedUser.games.forEach(game => {
        if (opponentCount[game.opponentName]) {
          opponentCount[game.opponentName] += 1;
        } else {
          opponentCount[game.opponentName] = 1;
        }
        if (opponentCount[game.opponentName] > mostSeenOpponentCount) {
          mostSeenOpponent = game.opponentName;
        }
      })

      this.setState({
        lastBoxScore: fetchedUser.games[fetchedUser.games.length - 1],
        mostSeenOpponent,
      });
    }
  }

  render() {
    const {
      auth: {
        user,
      },
      fetchedUser,
      window: {
        isMobile,
      },
    } = this.props;

    const {
      lastBoxScore,
      mostSeenOpponent,
    } = this.state;

    const maxBadges = 4;

    return (
      <div>
        <div className={classnames(css(styles.Dashboard_container), "row container")}>
          <div className={classnames(css(styles.Dashboard_heroContainer), "col s12 m12 l8")}>
            <h4 className={css(styles.Dashboard_mapHeader)}>2021-2022 Games</h4>
            <GameMap />
            <div className={css(styles.Dashboard_checkInButtonContainer)}>
              <Link to="/games">
                <Button className={classnames(css(styles.Dashboard_checkInButtonInverse), "btn btn-large hoverable white accent-3")}>
                  Check Into Past Games
                </Button>
              </Link>
            </div>
          </div>
          <div className={classnames(css(styles.Dashboard_sideStats), "col l4 hide-on-med-and-down")}>
            <div
              className={css(styles.Dashboard_profilePicture)}
              style={{
                position: 'relative',
                backgroundImage: fetchedUser.image ? `url(${fetchedUser.image.secure_url})` : undefined,
              }}
            >
              {!fetchedUser.image && <FontAwesomeIcon icon={faUser} color='#FFFFFF' size='5x' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
            </div>
            <div className={css(styles.Dashboard_userName)}>
              {user.name}
            </div>
            {fetchedUser.roaringRiotMember &&
              <div className={css(styles.Dashboard_smallText)}>
                Roaring Riot Member
              </div>}
            <div className={css(styles.Dashboard_gameContainer)}>
              {lastBoxScore._id &&
                <>
                  <Link className={css(styles.Dashboard_inverseLink)} to={`/games/${lastBoxScore.season}/${lastBoxScore.week}`}>
                    <div className={css(styles.Dashboard_gameCount)}>
                      {`${TEAM_ABBREVIATION_MAP[lastBoxScore.teamName]} ${lastBoxScore.gameLocation} ${TEAM_ABBREVIATION_MAP[lastBoxScore.opponentName]}`}
                    </div>
                  </Link>
                  <div className={css(styles.Dashboard_smallText)}>
                    LAST CHECK IN
                  </div>
                  <br />
                  <div className={css(styles.Dashboard_gameCount)}>
                    {mostSeenOpponent}
                  </div>
                  <div className={css(styles.Dashboard_smallText)}>
                    RIVAL OPPONENT
                  </div>
                  <br />
                </>}
              <div className={css(styles.Dashboard_gameCount)}>
                {fetchedUser.homeGameCount}
              </div>
              <div className={css(styles.Dashboard_smallText)}>
                HOME GAMES
              </div>
              <br />
              <div className={css(styles.Dashboard_gameCount)}>
                {fetchedUser.awayGameCount}
              </div>
              <div className={css(styles.Dashboard_smallText)}>
                AWAY GAMES
              </div>
              <br />
            </div>
            <img className={css(styles.Dashboard_sideImage)} src={grayClaw} alt="Gray Claw" />
            <div className={css(styles.Dashboard_badgesContainer)}>
              <div className={css(styles.Dashboard_badgesHeader)}>
                BADGES EARNED
              </div>
              <div className={classnames(css(styles.Dashboard_badgesGrid), 'row')}>
                {fetchedUser.badges && fetchedUser.badges.length > 0 ? (
                  fetchedUser.badges.slice(0, maxBadges).map((badge) => {
                    return (
                      <div className={classnames(css(styles.Dashboard_badge), 'col s6')} key={`badge-${badge.badgeType}`}>
                        <img width="50px" src={badgeIcons(badge.badgeType)} alt="Badge" />
                        <div>{badge.details}</div>
                      </div>
                    );
                  })
                  ) : (
                    <div>None</div>
                  )}
              </div>
              {fetchedUser.badges && fetchedUser.badges.length > maxBadges &&
                <Link to="/profile">
                  <div className={css(styles.Dashboard_extraBadges)}>+ {fetchedUser.badges.length - maxBadges} MORE</div>
                </Link>}
            </div>
          </div>
        </div>
        <div
          className={css(styles.Dashboard_scheduleContainer)}
        >
          <div className="container">
            <div className={css(styles.Dashboard_scheduleHeader)}>
              2020 PANTHERS SCHEDULE
            </div>
            <div className={css(styles.Dashboard_schedule)}>
              <div className={css(styles.Dashboard_gridHeader)}>
                REGULAR SEASON
              </div>
              <div className={css(styles.Dashboard_schedule)}>
                {isMobile ? (
                  <>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        SEPT 13
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/1">RAIDERS v PANTHERS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        SEPT 20
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/2">PANTHERS v BUCCANEERS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        SEPT 27
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/3">PANTHERS v CHARGERS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        OCT 4
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/4">CARDINALS v PANTHERS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        OCT 11
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/5">PANTHERS v FALCONS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        OCT 18
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/6">BEARS v PANTHERS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        OCT 25
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/7">PANTHERS v SAINTS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        OCT 29
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/8">FALCONS v PANTHERS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        NOV 8
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/9">PANTHERS v CHIEFS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        NOV 15
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/10">BUCCANEERS v PANTHERS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        NOV 22
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/11">LIONS v PANTHERS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        NOV 29
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/12">PANTHERS v VIKINGS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        DEC 13
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/14">BRONCOS v PANTHERS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        DEC 20
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/15">PANTHERS v PACKERS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        DEC 27
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/16">PANTHERS v REDSKINS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s4 m4 l1")}>
                        JAN 3
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s8 m8 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/17">SAINTS v PANTHERS</Link>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        SEPT 8
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/1">RAIDERS v PANTHERS</Link>
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        NOV 8
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/9">PANTHERS v CHIEFS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        SEPT 20
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/2">PANTHERS v BUCCANEERS</Link>
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        NOV 15
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/10">BUCCANEERS v PANTHERS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        SEPT 27
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/3">PANTHERS v CHARGERS</Link>
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        NOV 22
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/11">LIONS v PANTHERS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        OCT 4
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/4">CARDINALS v PANTHERS</Link>
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        NOV 29
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/12">PANTHERS v VIKINGS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        OCT 11
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/5">PANTHERS v FALCONS</Link>
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        DEC 13
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/14">BRONCOS v PANTHERS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        OCT 18
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/6">BEARS v PANTHERS</Link>
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        DEC 20
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/15">PANTHERS v PACKERS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        OCT 25
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/7">PANTHERS v SAINTS</Link>
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        DEC 27
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/16">PANTHERS v REDSKINS</Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        OCT 29
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/8">FALCONS v PANTHERS</Link>
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), css(styles.Dashboard_noPadding), "col s2 m2 l1")}>
                        JAN 3
                      </div>
                      <div className={classnames(css(styles.Dashboard_scheduleColumn), "col s4 m4 l5 center-align")}>
                        <Link className={css(styles.Dashboard_link)} to="/games/2020-2021/17">SAINTS v PANTHERS</Link>
                      </div>
                    </div>
                  </>
                )}

                <div className={css(styles.Dashboard_checkInButtonContainer)}>
                  <Link to="/games">
                    <Button className={classnames(css(styles.Dashboard_checkInButton), "btn btn-large hoverable blue accent-3")}>
                      Check Into Past Games Here!
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  fetchedUser: PropTypes.object.isRequired,
  fetchBoxScoreById: PropTypes.func.isRequired,
  window: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  fetchedUser: state.user.fetchedUser,
  window: state.window,
});

export default connect(
  mapStateToProps,
  {
    fetchUser,
    fetchBoxScoreById,
  }
)(Dashboard);
