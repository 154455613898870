import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { css, StyleSheet } from "aphrodite";
import { Button, TextInput, Checkbox } from "react-materialize";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons';

import { registerUser } from "../../actions/authActions";
import COLORS from "../../utils/colors";

const styles = StyleSheet.create({
  Register_container: {
    backgroundColor: COLORS.white,
    paddingBottom: '50px',
    paddingTop: '50px',
    marginTop: '40px',
    marginBottom: '40px',
  },
  Register_checkbox: {
    textAlign: 'right',
    width: '100%',
    marginBottom: '10px',
    marginTop: '-10px',
  },
  Register_socialButton: {
    border: `1px solid ${COLORS.primaryBlue}`,
    borderRadius: '5px',
    height: '54px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Register_socialContainer: {
    margin: 'auto',
  },
})

class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      errors: {},
      roaringRiot: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors,
      });
    }
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onCheckboxClicked = e => {
    this.setState({ [e.target.value]: e.target.checked });
  };

  onSubmit = e => {
    e.preventDefault();
    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      roaringRiot: this.state.roaringRiot,
    };

    this.props.registerUser(newUser, this.props.history);
  };

  render() {
    const { windowDimensions: { isMobile } } = this.props;
    const { errors } = this.state;

    return (
      <div className={classnames(css(styles.Register_container), "container")}>
        <div className="row">
          <div className="col s8 offset-s2">
            <Link to="/" className="btn-flat waves-effect">
              <i className="material-icons left">keyboard_backspace</i> Back to
              home
            </Link>
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h4>
                Join <b>The Riot Record</b>
              </h4>
              <p className="grey-text text-darken-1">
                Already have an account? <Link to="/login">Log in</Link>
              </p>
            </div>
            <form noValidate onSubmit={this.onSubmit}>
              <div className="input-field col s12">
                <TextInput
                  onChange={this.onChange}
                  value={this.state.name}
                  error={errors.name}
                  id="name"
                  type="text"
                  label="Name"
                  s={12}
                  className={classnames("", {
                    invalid: errors.name
                  })}
                />
              </div>
              <div className="input-field col s12">
                <TextInput
                  onChange={this.onChange}
                  value={this.state.email}
                  error={errors.email}
                  id="email"
                  email
                  validate
                  s={12}
                  label="Email"
                  className={classnames("", {
                    invalid: errors.email
                  })}
                />
              </div>
              <div className="input-field col s12">
                <TextInput
                  onChange={this.onChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                  password
                  s={12}
                  label="Password"
                  className={classnames("", {
                    invalid: errors.password
                  })}
                />
              </div>
              <div className="input-field col s12">
                <TextInput
                  onChange={this.onChange}
                  value={this.state.password2}
                  error={errors.password2}
                  id="password2"
                  password
                  s={12}
                  label="Confirm Password"
                  className={classnames("", {
                    invalid: errors.password2
                  })}
                />
              </div>
              <div className={classnames(css(styles.Register_checkbox), "col s12")}>
                <Checkbox value="roaringRiot" label="Are you a Roaring Riot Member already?" onChange={this.onCheckboxClicked} />
              </div>
              <div className="col s12" style={{ alignItems: 'center' }}>
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem"
                  }}
                  type="submit"
                  className="btn btn-large hoverable blue accent-3"
                >
                  Sign up
                </Button>
              </div>
              <div style={{ margin: '10px 20px', textAlign: 'center' }}>or</div>
              <div className="col s12" style={{ alignItems: 'center' }}>
                <div className={css(styles.Register_socialContainer)}>
                  <div onClick={() => window.location.href = "/api/auth/twitter" } className={classnames(css(styles.Register_socialButton), "btn-flat waves-effect")}>
                    <FontAwesomeIcon icon={faTwitter} color={COLORS.primaryBlue} size='lg' style={{ marginRight: isMobile ? '5px' : '10px' }} />
                    {isMobile ? "Register" : "Register on Twitter"}
                  </div>
                </div>
              </div>
              <div style={{ margin: '10px 20px', textAlign: 'center' }}>or</div>
              <div className="col s12" style={{ alignItems: 'center' }}>
                <div onClick={() => window.location.href = "/api/auth/facebook" } className={classnames(css(styles.Register_socialButton), "btn-flat waves-effect")}>
                  <FontAwesomeIcon icon={faFacebookF} color={COLORS.primaryBlue} size='lg' style={{ marginRight: isMobile ? '5px' : '10px' }} />
                  {isMobile ? "Register" : "Register on Facebook"}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  windowDimensions: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  windowDimensions: state.window,
});

export default connect(
  mapStateToProps,
  { registerUser }
)(withRouter(Register));
