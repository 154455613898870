import React, { Component } from 'react';
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import axios from "axios";
import { css } from "aphrodite";
import { Icon, Dropdown } from "react-materialize";
import isEmpty from "is-empty";
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from 'react-responsive-carousel';
import ReactSelect from 'react-select';
import { toast } from "react-toastify";

import styles from "./Games.styles";
import COLORS from "../../utils/colors";

class MemoriesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedMemory: undefined,
      memoryFriends: [],
      currentSlide: 0,
      startTagging: false,
      currentTag: {},
      memoryUsers: {},
    }
  }

  componentDidMount = async () => {
    const { selectedBoxScore } = this.props;

    if (selectedBoxScore?._id) {
      await this.getMemories(selectedBoxScore);
    }
  }

  componentDidUpdate = async (prevProps) => {
    const { selectedBoxScore } = this.props;

    if (prevProps.selectedBoxScore?._id !== selectedBoxScore?._id && selectedBoxScore?._id) {
       await this.getMemories(selectedBoxScore);
    }
  }

  getMemories = async (selectedBoxScore) => {
    const { fetchMemories, fetchListUsers } = this.props;

    const memories = await fetchMemories(selectedBoxScore._id);
    const memoryAdders = memories.length > 0 ? await fetchListUsers(_.uniq(memories.map(m => m.userId))) : [];
    const memoryUsers = {}
    memoryAdders.forEach((u) => {
      memoryUsers[u._id] = u;
    });

    this.setState({ memoryUsers });

    return memories;
  }

  onOpenTagging = async () => {
    const {
      fetchFriends,
      auth: {
        user,
      },
    } = this.props;

    await fetchFriends(user._id);
    this.setState({ startTagging: true })
  }

  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
        this.setState({
            currentSlide: index,
        });
    }
  };

  onTaggingOverlayClick = (e) => {
    const {
      isAuthenticated,
    } = this.props;

    if (!isAuthenticated) {
      return;
    }

    let offsetX = e.target.offsetLeft;
    let offsetY = e.target.offsetTop;
    let elParent = e.target.offsetParent;

    while (elParent !== null) {
      offsetX += elParent.offsetLeft;
      offsetY += elParent.offsetTop;
      elParent = elParent.offsetParent;
    }

    const xPercent = Math.round((e.pageX - offsetX) / e.target.offsetWidth * 100);
    const yPercent = Math.round((e.pageY - offsetY) / e.target.offsetHeight * 100);

    this.setState({
      currentTag: { xPercent, yPercent },
    });
  };

  onTagSelect = async (opt) => {
    const { tagFriend, fetchListUsers } = this.props;
    const { currentTag, expandedMemory } = this.state;

    const memory = await tagFriend(expandedMemory._id, opt.value, currentTag.xPercent, currentTag.yPercent);
    const memoryFriends = await fetchListUsers(memory.taggedUsers.map(friend => friend.userId)) || [];

    this.setState({ currentTag: {}, expandedMemory: memory, memoryFriends });
  }

  onDeleteTag = async (memoryId, userId) => {
    const { deleteTag, fetchListUsers } = this.props;
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this tag?')) {
      const memory = await deleteTag(memoryId, userId);
      const memoryFriends = await fetchListUsers(memory.taggedUsers.map(friend => friend.userId)) || [];

      this.setState({ currentTag: {}, expandedMemory: memory, memoryFriends });
    }
  }

  handleUploadPic = () => {
    this.refs.fileUploader.click();
  };

  onPicChange = async (e) => {
    const { selectedBoxScore } = this.state;

    const files = Array.from(e.target.files);
    this.setState({ uploading: true });

    const formData = new FormData();
    const errs = [];

    // #1 There are too many files!
    if (files.length > 1) {
      const msg = 'Only 1 image can be uploaded at a time';
      return toast.error(msg);
    }

    const types = ['image/png', 'image/jpeg', 'image/gif'];

    files.forEach((file, i) => {
      // #2 Catching wrong file types on the client
      if (types.every(type => file.type !== type)) {
        errs.push(`'${file.type}' is not a supported format`);
      }

      // #3 Catching files that are too large on the client ~5MB
      if (file.size > 5000000) {
        errs.push(`'${file.name}' is too large, please pick a smaller file`);
      }

      formData.append(i, file);
    });

    formData.append('boxScoreId', selectedBoxScore._id);

    if (errs.length) {
      return errs.forEach(err => toast.error(err));
    }

    await fetch(`/api/memories/image-upload`, {
      method: 'POST',
      body: formData,
    })
    .then(res => {
      if (!res.ok) {
        throw res;
      }
      return res.json();
    })
    .then(images => {
      this.setState({
        uploading: false,
        images,
      });
      toast.success('Successfully added a memory!');

      this.getMemories(selectedBoxScore).then((memories) => {
        this.updateCurrentSlide(memories.length - 1);
      });
    })
    .catch(err => {
      toast.error(err.message);
      this.setState({ uploading: false });
    });
  };

  showPictureFrame = async (memory) => {
    const { fetchListUsers } = this.props;

    const memoryFriends = await fetchListUsers(memory.taggedUsers.map(friend => friend.userId)) || [];
    this.setState({
      expandedMemory: memory,
      memoryFriends,
    });
  }

  handleDeleteMemory = async (memory) => {
    const { selectedBoxScore } = this.state;

    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this memory forever?')) {
      try {
        await axios.delete(`/api/memories/${memory._id}`)
        toast.success('The memory was deleted successfully');
        await this.getMemories(selectedBoxScore);
        this.updateCurrentSlide(0);
        this.setState({ expandedMemory: undefined, memoryFriends: [] });
        console.log("done!");
      } catch {
        toast.error('Something went wrong with that request. Please try again later.');
      }
    }
  }

  render() {
    const {
      selectedBoxScore,
      isAuthenticated,
      user,
      memories,
      friends,
    } = this.props;
    const {
      expandedMemory,
      memoryFriends,
      startTagging, 
      currentTag,
      currentSlide,
      memoryUsers,
    } = this.state;

    return (
      <>
        <ReactModal
          style={{
            overlay: {
              margin: 'auto',
              width: '100%',
              border: `1px solid gray`,
              backgroundColor: 'rgb(0, 0, 0, 0.7)',
              overflow: 'hidden',
              zIndex: '100',
            },
            content: {
              position: 'absolute',
              margin: 'auto',
              top: '40px',
              left: '40px',
              right: '40px',
              bottom: '40px',
              background: '#fff',
              overflowY: 'scroll',
              WebkitOverflowScrolling: 'touch',
              outline: 'none',
              padding: '20px',
              maxWidth: '80%',
              height: 'fit-content',
            }
          }}
          isOpen={expandedMemory !== undefined}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          onRequestClose={() => this.setState({ expandedMemory: undefined, memoryFriends: [] })}
        >
          {expandedMemory && expandedMemory.image && (
            <>
              <div className={css(styles.Games_closeButton)} onClick={() => this.setState({ expandedMemory: undefined, memoryFriends: [] })}>
                <Icon>close</Icon>
              </div>
              <div
                className={css(styles.Games_memoryImageContainer)} 
              >
                {memoryFriends.map(mf => {
                  const tag = expandedMemory.taggedUsers.filter(t => t.userId === mf._id)[0];
                  return (
                    <div 
                      key={`memory-${mf._id}`}
                      className={css(startTagging ? styles.Games_tagPreview : styles.Games_tag)}
                      style={{
                        left: `${tag.locationXPercent}%`,
                        top: `${tag.locationYPercent}%`,
                      }}
                    >
                      {user._id === expandedMemory.userId &&
                        <FontAwesomeIcon
                          onClick={() => this.onDeleteTag(expandedMemory._id, tag.userId)}
                          className={css(styles.Games_tagDeleteIcon)}
                          icon={faTimesCircle}
                          color={COLORS.black}
                          size='1x'
                          style={{
                            position: 'absolute',
                            left: '-5px',
                            top: '-5px',
                            zIndex: 100,
                          }}
                        />}
                      {mf.name}
                    </div>
                  );
                })}
                {!isEmpty(currentTag) && 
                  <div 
                    style={{
                      position: 'absolute',
                      top: `${currentTag.yPercent}%`,
                      left: `${currentTag.xPercent}%`,
                      zIndex: 2000,
                      width: '250px',
                    }}
                  >
                    <ReactSelect 
                      onChange={this.onTagSelect}
                      options={friends.filter(f => {
                        return !(memoryFriends.map(mf => mf._id)).includes(f._id)
                      }).map(friend => { 
                        return { label: friend.name, value: friend._id };
                      })} 
                    />
                  </div>}
                {startTagging &&
                  <div className={css(styles.Games_taggingOverlay)} onClick={this.onTaggingOverlayClick}>
                    <div 
                      className={css(styles.Games_tagCloseButton)} 
                      onClick={() => this.setState({ startTagging: false, currentTag: {} })}
                    >
                      Finished
                    </div>
                  </div>}
                <div 
                  className={css(styles.Games_memoryImage)} 
                  style={{  backgroundImage: `url(${expandedMemory.image.secure_url})` }}
                />
              </div>
              {user && user._id === expandedMemory.userId && 
                <Dropdown
                  className={css(styles.Games_pictureFrameMenuDropdown)}
                  id="picture-frame-options"
                  options={{
                    alignment: 'left',
                    autoTrigger: true,
                    closeOnClick: true,
                    constrainWidth: true,
                    container: null,
                    coverTrigger: true,
                    hover: false,
                    inDuration: 150,
                    onCloseEnd: null,
                    onCloseStart: null,
                    onOpenEnd: null,
                    onOpenStart: null,
                    outDuration: 250
                  }}
                  trigger={<div className={css(styles.Games_pictureFrameMenu)}><Icon style={{ marginTop: '20px' }}>menu</Icon></div>}
                >
                  <a href='#'>
                    <div className={css(styles.Games_menuOption)}  onClick={this.onOpenTagging}>
                      <Icon className={css(styles.Games_menuIcon)}>local_offer</Icon>&nbsp;Tag Friends
                    </div>
                  </a>
                  <a href='#'>
                    <div className={css(styles.Games_menuOption)} onClick={() => this.handleDeleteMemory(expandedMemory)}>
                      <Icon className={css(styles.Games_menuIcon)}>delete</Icon>&nbsp;Delete
                    </div>
                  </a>
                </Dropdown>}
              {memoryUsers[expandedMemory.userId] && <p className="legend right">Added by {memoryUsers[expandedMemory.userId].name}</p>}
            </>
          )}
        </ReactModal>
        {selectedBoxScore.winLoss && isAuthenticated ? (
          <div className="row">
            <div className="col center s12">
              <input type='file' id='single' onChange={this.onPicChange} ref="fileUploader" style={{display: "none"}} />
              <button
                onClick={this.handleUploadPic}
                style={{
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                  marginTop: "1rem"
                }}
                className="btn btn-large hoverable blue accent-3"
              >
                Add Your Memories
              </button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col center s12">
              Come back after the game is over to post your memories here!
            </div>
          </div>
        )}
        {selectedBoxScore.winLoss && memories && memories.length > 0 &&
          <div className="row">
            <div className="col center s12">
              <Carousel 
                className={css(styles.Games_memoryCarousel)}
                selectedItem={currentSlide}
                onChange={this.updateCurrentSlide}
              >
                {memories.map((memory, i) => {
                  return (
                    <div key={`memory-${i}`} onClick={() => this.showPictureFrame(memory)}>
                        <img 
                          alt={`Memory #${i}`} 
                          src={memory.image.secure_url} 
                        />
                        {memoryUsers[memory.userId] && <p className="legend">Added by {memoryUsers[memory.userId].name}</p>}
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>}
      </>
    )
  }
}

MemoriesContainer.defaultProps = {};

MemoriesContainer.propTypes = {
  selectedBoxScore: PropTypes.object.isRequired,
  fetchFriends: PropTypes.func.isRequired,
  fetchListUsers: PropTypes.func.isRequired,
  fetchMemories: PropTypes.func.isRequired,
  tagFriend: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  friends: PropTypes.arrayOf(PropTypes.object).isRequired,
  memories: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MemoriesContainer;