import React, { Component } from "react";
import { css, StyleSheet } from "aphrodite";
import classnames from "classnames";

import COLORS from "../../utils/colors";

const styles = StyleSheet.create({
  Terms_container: {
    position: 'relative',
    marginBottom: '0',
    display: 'flex',
    minHeight: '800px',
    backgroundColor: COLORS.primaryBlue,
  },
  Terms_innerContainer: {
    marginTop: '100px',
    marginBottom: '100px',
    fontSize: '14px',
    color: COLORS.white,
  },
});

class Terms extends Component {
  render() {
    return (
      <div className={css(styles.Terms_container)}>
        <div className={classnames(css(styles.Terms_innerContainer), "row container")}>
          <h2>Terms of Service</h2>
            This document was last updated on October 14, 2019
            <br />
            <br />
            Please read these Terms of Service (this “Agreement”) carefully. Your use or access of the Site (as defined below) constitutes your consent to this Agreement.
            <br />
            <br />
            This Agreement is between you and The Riot Record (“Company” or “we” or “us”) concerning your use of (including any access to) Company’s Site, currently located at theriotrecord.com, mobile applications and web applications (together with any materials and services available therein, and successor Site(s) or application(s) thereto, the “Site”). This Agreement hereby incorporates by this reference any additional terms and conditions with respect to the Site posted by Company to the Site, or otherwise made available to you by Company, including without limitation during the creation, acquisition, transer, or modification of certain digital assets, our online and/or mobile services, and software provided on or in connection with those services.
            <br />
            <br />
            By clicking or tapping any button or box marked “accept,” “agree” or “OK” (or a similar term) in connection with this Agreement, or by accessing or using the Site, you agree to be bound by this Agreement and affirm that you are of legal age to enter into this Agreement where you live and have the legal capacity to enter into this Agreement.
            <br />
            <br />
            If you are an individual accessing or using the Site on behalf of, or for the benefit of, any corporation, partnership or other entity with which you are associated (an “Organization”), then you are agreeing to this Agreement on behalf of yourself and such Organization, and you represent and warrant that you have the legal authority to bind such Organization to this Agreement. References to “you” and “your” in this Agreement will refer to both the individual using the Site and to any such Organization.
            <br />
            <br />
            THIS SITE IS OFFERED AND AVAILABLE TO USERS WHO ARE 18 YEARS OF AGE OR OLDER, OR, IF THE REGISTERED USER IS A CORPORATION, GOVERNMENTAL ORGANIZATION OR OTHER LEGAL ENTITY, YOU HAVE THE RIGHT, POWER AND AUTHORITY TO ENTER INTO THIS AGREEMENT ON BEHALF OF THE REGISTERED USER AND BIND THE REGISTERED USER TO ITS TERMS. IF THE REGISTERED USER DOES NOT AGREE TO THE TERMS OF THIS AGREEMENT, THE RIOT RECORD WILL NOT AND DOES NOT PROVIDE THE SERVICE AND YOU MUST NOT USE THE SITE OR THE SERVICE.
            <br />
            <br />
            <h5>Services</h5>
            Through use of the Site, individuals can interact with other fans of the Carolina Panthers football team to check into games, earn virtual badges, and compete on social leaderboards.
            <br />
            <br />
            THE SITE IS A PLATFORM.
            <br />
            <br />
            <h5>Rules of Conduct</h5>
            <br />
            <br />
            In connection with the use of the Site, you must not:
            <br />
            <br />
            Post, transmit or otherwise make available through or in connection with the Site any materials that are or may be: (a) threatening, harassing, degrading, hateful or intimidating, or otherwise fail to respect the rights and dignity of others; (b) defamatory, libelous, fraudulent or otherwise tortious; (c) obscene, indecent, pornographic or otherwise objectionable; or (d) protected by copyright, trademark, trade secret, right of publicity or privacy or any other proprietary right, without the express prior written consent of the applicable owner.
            <br />
            <br />
            Post, transmit or otherwise make available through or in connection with the Site any virus, worm, Trojan horse, Easter egg, time bomb, spyware, scareware, malware or other computer code, file or program that is or is potentially harmful or invasive or intended to damage or hijack the operation of, or to monitor the use of, any hardware, software or equipment (each, a “Virus”).
            <br />
            <br />
            Use the Site for any commercial purpose, or for any purpose that is fraudulent or otherwise tortious or unlawful.
            <br />
            <br />
            Harvest or collect information about users of the Site.
            <br />
            <br />
            Use the Site to transmit through or in connection with the Site, any spam, chain letters or other unsolicited communications.
            <br />
            <br />
            Interfere with or disrupt the operation of the Site or the servers or networks used to make the Site available, including by hacking or defacing any portion of the Site (including any content available thereby); or violate any requirement, procedure or policy of such servers or networks.
            <br />
            <br />
            Restrict or inhibit any other person from legal use of the Site.
            <br />
            <br />
            Reproduce, modify, adapt, translate, create derivative works of, sell, rent, lease, loan, timeshare, distribute or otherwise exploit any portion of (or any use of) the Site except as expressly authorized herein, without Company’s express prior written consent.
            <br />
            <br />
            Reverse engineer, decompile or disassemble any portion of the Site, except to the extent such restriction is expressly prohibited by applicable law.
            <br />
            <br />
            Remove any copyright, trademark or other proprietary rights notice from the Site.
            <br />
            <br />
            Frame or mirror any portion of the Site, or otherwise incorporate any portion of the Site into any product or service, without Company’s express prior written consent.
            <br />
            <br />
            Systematically download and store Site content.
            <br />
            <br />
            Use any robot, spider, site search/retrieval application or other manual or automatic device to retrieve, index, “scrape,” “data mine” or otherwise gather Site content (including Submissions), or reproduce or circumvent the navigational structure or presentation of the Site, without Company’s express prior written consent. Notwithstanding the foregoing, and subject to compliance with applicable law and any instructions posted in the robots.txt file located in the Site’s root directory, Company grants to the operators of public search engines permission to use spiders to copy materials from the Site for the sole purpose of (and solely to the extent necessary for) creating publicly available, searchable indices of such materials, but not caches or archives of such materials. Company reserves the right to revoke such permission either generally or in specific cases, at any time and without notice.
            <br />
            <br />
            Activity which would violate, or assist in violation of, any law, statute, ordinance, or regulation, sanctions programs administered in any jurisdiction the Site operates, including but not limited to the U.S. Department of Treasury's Office of Foreign Assets Control ("OFAC"), or which would involve proceeds of any unlawful activity; publish, distribute or disseminate any unlawful material or information.
            <br />
            <br />
            Use the Site for any purpose that could be construed as gambling or facilitating such, including but not limited to lotteries, sports forecasting or odds making, fantasy sports leagues with cash prizes, internet gaming, contests, sweepstakes or games of chance.
            <br />
            <br />
            <h5>Access to the Site and the Services</h5>
            License. Subject to these Terms, The Riot Record grants you a non-transferable, non-exclusive, revocable, limited license to use and access the Site and related content, materials, information (collectively, the "Content") solely for your own personal, noncommercial use. Any other use of the Site or Content is expressly prohibited and all other right, title, and interest in the Site or Content is exclusively the property of The Riot Record and its licensors.
            <br />
            <br />
            Certain Restrictions. The rights granted to you in these Terms are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site, whether in whole or in part, or any content displayed on the Site; (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site or Services in order to build a similar or competitive website, product, or service; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means. Unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms. All copyright and other proprietary notices on the Site (or on any content displayed on the Site) must be retained on all copies thereof.
            <br />
            <br />
            Modification. The Riot Record reserves the right, at any time, to modify, suspend, or discontinue the Site and the Services (in whole or in part) with or without notice to you. You agree that The Riot Record will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Site or any part thereof.
            <br />
            <br />
            Site and Service Availability. The Riot Record shall use commercially reasonable efforts to attempt to provide the Site and the Services on a twenty-four (24) hours a day, seven (7) days a week basis. You acknowledge and agree that from time to time this site may be inaccessible or inoperable for any reason including, but not limited to, equipment malfunctions; periodic maintenance, repairs or replacements that The Riot Record undertakes from time to time; or causes beyond our reasonable control or that are not reasonably foreseeable including, but not limited to, interruption or failure of telecommunication or digital transmission links, hostile network attacks, network congestion or other failures. You acknowledge and agree that The Riot Record has no control over the availability of this Site or Services on a continuous or uninterrupted basis, and that The Riot Record assumes no liability to You or any other party with regard to such, including but not limited to loss of revenue.
            <br />
            <br />
            Customer Support. The Site and the Services are provided “as-is” and The Riot Record does not commit to providing any Customer Support in connection with the Services and is under no obligation to do so. However, we are happy to discuss any challenges or questions you may have about the Services or the Site and may offer assistance on a case-by-case basis. If you have questions about any aspect of the Services you can send an email to justinkchen14@gmail.com.
            <br />
            <br />
            Ownership. You acknowledge that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Site and its content are owned by The Riot Record or Set’s suppliers. Neither these Terms (nor your access to the Site) transfers to you or any third party any rights, title or interest in or to such intellectual property rights, except for the limited access rights expressly set forth in these Terms. The Riot Record and its suppliers reserve all rights not granted in these Terms. There are no implied licenses granted under these Terms.
            <br />
            <br />
            <h5>Risks</h5>
            Our privacy policy is a part of this Agreement. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.
            <br />
            <br />
            <h5>Resources; Third Party Resources.</h5>
            The Site may make available information, data, materials, services, functionality or other resources (collectively, “Resources”), as well as references and links to such Resources. Resources may be made available by Company or by third parties, and may be made available for any purpose, including for general information purposes. We make no representations as to the accuracy, validity, timeliness, completeness, reliability, integrity, quality, legality, usefulness or safety of any or all of the Resources and the Site, or any intellectual property rights therein. Resources are subject to change at any time without notice. To the fullest extent permitted under applicable law, we disclaim all liability and responsibility arising from any reliance placed on any Resources by you or any other user of the Site, or by anyone who may be informed of the content of any Resources. We neither control nor endorse, nor have any obligation to monitor Resources made available by third parties (“Third Party Resources”), and we may block or disable access to any Third Party Resources (in whole or part) through the Site at any time. Certain Third Party Resources may, among other things, be inaccurate, misleading or deceptive. Nothing in this Agreement will be deemed to be a representation or warranty by Company with respect to any Third Party Resources.
            <br />
            <br />
            Your use of Third Party Resources is at your own risk and is subject to any additional terms, conditions and policies applicable to such Third Party Resources (such as terms of service or privacy policies of the providers of such Third Party Resources).
            <br />
            <br />
            <h5>Warranties and Disclaimers</h5>
            We have put in a great deal of effort to deliver you Services that we hope you find valuable, but there are certain things we can’t promise about them. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR ACCESS TO AND USE OF THE SITE AND ANY CONTENT IS AT YOUR SOLE RISK. OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS, NEITHER SET LABS INC. NOR ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS (“SET LABS INC. PARTIES”) MAKE ANY WARRANTY, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICE OR CONTENT. THE SITE AND CONTENT ARE PROVIDED “AS IS.” THE COMPANY DOES NOT WARRANT THAT: (1) THE OPERATION OF THE SERVICE OR CONTENT WILL BE UNINTERRUPTED, ERROR-FREE, OR VIRUS-FREE; (2) THE FUNCTIONS CONTAINED IN THE SITE OR CONTENT WILL BE ACCURATE OR MEET YOUR REQUIREMENTS; OR (3) ANY DEFECTS IN THE SITE OR CONTENT WILL BE CORRECTED. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE ALSO DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES, SO SOME DISCLAIMERS IN THIS SECTION MAY NOT APPLY TO YOU.
            <br />
            <br />
            <h5>Limitation of Liability</h5>
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE COMPANY BE RESPONSIBLE TO YOU FOR ANY: (1) LOSS OF USE, DATA, BUSINESS OR PROFITS; (2) FINANCIAL LOSSES; OR (3) INDIRECT, SPECIAL CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES. THIS WILL BE WHETHER OR NOT THE COMPANY PARTIES HAVE BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS, NOTHING IN THESE TERMS EXCLUDES OR LIMITS LIABILITY FOR FRAUD, FRAUDULENT MISREPRESENTATION, OR FOR DEATH OR PERSONAL INJURY CAUSED BY GROSS NEGLIGENCE.
            <br />
            <br />
            <h5>Indemnification</h5>
            You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of this Agreement or your use of the Site, including, but not limited to any use of the Site's content, services and products other than as expressly authorized in this Agreement or your use of any information obtained from the Site.
            <br />
            <br />
            <h5>Governing Law and Venue</h5>
            These Terms and your access to and use of the Site will be governed by the laws of the State of Washington, U.S.A., without regard to conflict of law rules or principles (whether of the State of Washington or any other jurisdiction) that would cause the application of the laws of any other jurisdiction. Any Dispute between the parties that is not subject to arbitration or cannot be heard in small claims court shall be resolved in the state or federal courts of the State of Washington and the United States, respectively, sitting in King County, Washington.
            <br />
            <br />
            <h5>Designated Countries Privacy Rights</h5>
            This Section only applies to Users and Customers of the Site that are located in the European Economic Area, United Kingdom and/or Switzerland (collectively, the “Designated Countries”) at the time of data collection. We may ask you to identify which country you are located in when you use some of the Site, or we may rely on your IP address to identify which country you are located in. Where we rely only on your IP address, we cannot apply the terms of this Section to any User or Customer that masks or otherwise obfuscates their location information so as not to appear located in the Designated Countries. If any terms in this Section conflict with other terms contained in this Policy, the terms in this Section shall apply to Users and Customers in the Designated Countries.
            <br />
            <br />
            We are a data controller with regard to any personal information collected from Customers or Users of its Site. A “data controller” is an entity that determines the purposes for which and the manner in which any personal information is processed. Any third parties that act as our service providers are “data processors” that handle your personal information in accordance with our instructions.
            <br />
            <br />
            You may object to our processing at any time and as permitted by applicable law if we process your personal information on the legal basis of consent, contract or legitimate interests. We can continue to process your personal information if it is necessary for the defense of legal claims, or for any other exceptions permitted by applicable law.
            <br />
            <br />
            If we process your personal information based on a contract with you or based on your consent, or the processing is carried out by automated means, you may request to receive your personal information in a structured, commonly used and machine-readable format, and to have us transfer your personal information directly to another “controller”, where technically feasible, unless exercise of this right adversely affects the rights and freedoms of others.
            <br />
            <br />
            If you believe we have infringed or violated your privacy rights, please contact us at justinkchen14@gmail.com so that we can work to resolve your concerns. You also have a right to lodge a complaint with a competent supervisory authority situated in a Member State of your habitual residence, place of work, or place of alleged infringement.
            <br />
            <br />
            <h5>Changes to this Agreement</h5>
            We reserve the right to change this Agreement at any time upon notice. We may give notice by posting the updated Agreement on the Site or by any other reasonable means. You can review the most current version of this Agreement at any time. This Agreement in effect at the time of your use of the Site apply. Updated versions of the Agreement are binding on you with respect to your use of the Site on or after the date indicated in the updated Agreement. If you do not agree to the updated Agreement, you must stop using the Site. Your continued use of the Site after the date of the updated Agreement will constitute your acceptance of the updated Agreement.
            <br />
            <br />
            <h5>Entire Agreement</h5>
            This Agreement and Privacy Policy constitute the sole and entire agreement between you and the Company with respect to the Site and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Site.
            <br />
            <br />
            This Agreement does not, and will not be construed to, create any partnership, joint venture, employer-employee, agency or franchisor-franchisee relationship between you and the Company.
        </div>
      </div>
    );
  }
}

export default Terms;
