import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { css } from "aphrodite";
import _ from "lodash";
import classnames from "classnames";
import ReactModal from "react-modal";
import { Select, Button, Collection, Row, Col, CollectionItem, Checkbox, Collapsible, CollapsibleItem, Icon } from "react-materialize";
import moment from "moment";
import isEmpty from "is-empty";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCertificate } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from "react-tooltip";

import {
  fetchMemories,
  fetchBoxScores,
  fetchBoxScoreById,
  fetchOffensiveStats,
  fetchDefensiveStats,
  checkIntoGame,
  removeGame,
  tagFriend,
  deleteTag,
} from "../../actions/boxScoreActions";
import {
  fetchUser,
  fetchListUsers,
  fetchFriends,
} from "../../actions/userActions";
import COLORS from "../../utils/colors";
import { getGameBannerDismissal, dismissGameBanner } from "../../utils/localStorageHelpers";
import { getStadiumImg } from "../../utils/stadiumHelpers";
import { TEAMS } from "../../utils/constants";
import styles from './Games.styles';
import riotRecordLogo from "../../img/logos/riot-record-logo.png";

import MemoriesContainer from './MemoriesContainer';
import PaginatedUserList from '../shared/PaginatedUserList';

class Games extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boxScoresBySeason: {},
      selectedSeason: '',
      selectedBoxScore: {},
      selectedSeat: {},
      attendedTailgate: false,
      offensiveStats: [],
      defensiveStats: [],
      topPasser: {},
      topRusher: {},
      topReceiver: {},
      topOppPasser: {},
      topOppRusher: {},
      topOppReceiver: {},
      currentPageNumber: 1,
      gameBannerDismissed: getGameBannerDismissal(),
      fetchedAttendees: {},
    };
  }

  async componentDidMount() {
    const { fetchBoxScores, match } = this.props;

    const boxScores = await fetchBoxScores([TEAMS.CAROLINA_PANTHERS]);

    if (match && match.params.season && match.params.week) {
      const selectedBoxScore = boxScores.find((boxScore) => {
        return boxScore.season === match.params.season && String(boxScore.week) === String(match.params.week);
      })
      await this.onChangeGame(selectedBoxScore._id, match.params.season, match.params.week);

      // Prepopulate with selected box score if set in url params
      this.setState({
        selectedSeason: match.params.season,
      });
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.boxScores !== this.props.boxScores) {
      const boxScoresBySeason = {};
      this.props.boxScores.forEach((boxScore) => {
        if (boxScoresBySeason[boxScore.season]) {
          boxScoresBySeason[boxScore.season].push(boxScore);
        } else {
          boxScoresBySeason[boxScore.season] = [boxScore];
        }
      });
      this.setState({ boxScoresBySeason });
    }

    if (!prevProps.auth.isAuthenticated && this.props.auth.isAuthenticated) {
      await this.props.fetchUser(this.props.auth.user._id);
    }

    if (prevState.startTagging && !this.state.startTagging) {
      this.setState({ currentTag: {} });
    }

    if (prevState.selectedBoxScore._id !== this.state.selectedBoxScore._id) {  
      if (this.state.selectedBoxScore?.attendees && this.state.selectedBoxScore.attendees.length > 0) {
        const attendees = this.state.selectedBoxScore.attendees;
        const chunkSize = 20;
        for (let i = 0; i < attendees.length; i += chunkSize) {
          const chunk = attendees.slice(i, i + chunkSize);
          const users = await this.props.fetchListUsers(chunk.map(a => a.userId));
        
          const newFetchedAttendees = {}
          users.forEach(user => {
            newFetchedAttendees[user._id] = user
          });
          this.setState({
            fetchedAttendees: {
              ...this.state.fetchedAttendees,
              ...newFetchedAttendees,
            }
          })
        }
      }
    }

    ReactTooltip.rebuild();
  };

  onChangeSeason = async (e) => {
    const { boxScoresBySeason } = this.state;

    const selectedSeason = e.target.value;

    this.setState({
      selectedSeason,
    });

    await this.onChangeGame(boxScoresBySeason[selectedSeason][0]._id, selectedSeason, 1);
  };

  onChangeGame = async (gameId, season, week) => {
    const {
      fetchBoxScoreById,
      fetchOffensiveStats,
      fetchDefensiveStats,
      history,
    } = this.props;

    const selectedBoxScore = await fetchBoxScoreById(gameId);
    const offensiveStats = await fetchOffensiveStats(gameId);
    const defensiveStats = await fetchDefensiveStats(gameId);
    this.updateTopPerformer(selectedBoxScore, offensiveStats);
    
    history.push(`/games/${season}/${week}`);

    this.setState({
      offensiveStats,
      defensiveStats,
      selectedBoxScore,
      attendedTailgate: false,
      selectedSeat: {}, // Use the seat from boxScore if the user attended it
    });
  };

  onCheckIn = async (e) => {
    e.preventDefault();

    const {
      checkIntoGame,
      fetchUser,
      fetchBoxScoreById,
      auth: {
        isAuthenticated,
        user,
      }
    } = this.props;

    if (isAuthenticated) {
      const { selectedBoxScore, selectedSeat, attendedTailgate } = this.state;

      await checkIntoGame(selectedBoxScore, selectedSeat, attendedTailgate);

      // Refresh game state
      const newBoxScore = await fetchBoxScoreById(selectedBoxScore._id);
      this.setState({
        selectedBoxScore: newBoxScore,
        selectedSeat: {}, // Use the seat from boxScore if the user attended it
      });

      // Refresh the user state
      await fetchUser(user._id);
    }
  };

  onRemoveGame = async (e) => {
    e.preventDefault();

    const {
      removeGame,
      fetchUser,
      fetchBoxScoreById,
      auth: {
        isAuthenticated,
        user,
      }
    } = this.props;

    if (isAuthenticated) {
      const { selectedBoxScore } = this.state;

      await removeGame(selectedBoxScore);

      // Refresh game state
      const newBoxScore = await fetchBoxScoreById(selectedBoxScore._id);
      this.setState({
        selectedBoxScore: newBoxScore,
        selectedSeat: {},
      });

      // Refresh the user state
      await fetchUser(user._id);
    }
  };

  onCheckboxClicked = e => {
    this.setState({ [e.target.value]: e.target.checked });
  };

  updateTopPerformer = (selectedBoxScore, offensiveStats) => {
    if (selectedBoxScore.winLoss) {
      const topPasser = offensiveStats.filter((stat) => stat.team === 'CAR').sort((player1, player2) => {
        return player2.passYds - player1.passYds;
      })[0];
      const topRusher = offensiveStats.filter((stat) => stat.team === 'CAR').sort((player1, player2) => {
        return player2.rushYds - player1.rushYds;
      })[0];
      const topReceiver = offensiveStats.filter((stat) => stat.team === 'CAR').sort((player1, player2) => {
        return player2.recYds - player1.recYds;
      })[0];
      const topOppPasser = offensiveStats.filter((stat) => stat.team !== 'CAR').sort((player1, player2) => {
        return player2.passYds - player1.passYds;
      })[0];
      const topOppRusher = offensiveStats.filter((stat) => stat.team !== 'CAR').sort((player1, player2) => {
        return player2.rushYds - player1.rushYds;
      })[0];
      const topOppReceiver = offensiveStats.filter((stat) => stat.team !== 'CAR').sort((player1, player2) => {
        return player2.recYds - player1.recYds;
      })[0];

      this.setState({
        topPasser,
        topRusher,
        topReceiver,
        topOppPasser,
        topOppRusher,
        topOppReceiver,
      });
    }
  };

  onStadiumClick = (e, alreadyAttendedGame) => {
    const {
      auth: {
        isAuthenticated,
      }
    } = this.props;

    const {
      selectedSeason,
    } = this.state;

    if (alreadyAttendedGame || !isAuthenticated || !selectedSeason) {
      return;
    }

    let offsetX = e.target.offsetLeft;
    let offsetY = e.target.offsetTop;
    let elParent = e.target.offsetParent;

    while (elParent !== null) {
      offsetX += elParent.offsetLeft;
      offsetY += elParent.offsetTop;
      elParent = elParent.offsetParent;
    }

    const xPercent = Math.round((e.pageX - offsetX) / e.target.width * 100);
    const yPercent = Math.round((e.pageY - offsetY) / e.target.height * 100);

    this.setState({
      selectedSeat: { xPercent, yPercent },
    });
  };

  renderCheckInButton = (alreadyAttendedGame) => {
    const {
      auth: {
        isAuthenticated,
      },
      gameCheckInSubmitting,
      removeGameSubmitting,
      window: {
        isMobile,
      },
    } = this.props;

    const {
      selectedBoxScore,
      selectedSeason,
      selectedSeat,
    } = this.state;

    const mapDirection = isMobile ? "↑" : "→";

    if (selectedSeason && selectedBoxScore && selectedBoxScore._id && isAuthenticated) {
      return (
        !alreadyAttendedGame ? (
          <form noValidate onSubmit={this.onCheckIn} className={css(styles.Games_checkinButton)}>
            <button
              style={{
                borderRadius: "3px",
                letterSpacing: "1.5px",
                marginTop: "1rem"
              }}
              type="submit"
              disabled={gameCheckInSubmitting || isEmpty(selectedSeat)}
              className="btn btn-large hoverable blue accent-3"
            >
              {isEmpty(selectedSeat) ? `Select Seat ${mapDirection}` : "Check In"}
            </button>
          </form>
        ) : (
          <form noValidate onSubmit={this.onRemoveGame} className={css(styles.Games_checkinButton)}>
            <button
              style={{
                width: "250px",
                borderRadius: "3px",
                letterSpacing: "1.5px",
                marginTop: "1rem"
              }}
              type="submit"
              disabled={removeGameSubmitting}
              className="btn btn-large hoverable blue accent-3"
            >
              Remove Checkin
            </button>
          </form>
        )
      );
    }
  }

  render() {
    const {
      fetchedUser,
      window: {
        isTablet,
      },
      auth: {
        isAuthenticated,
        user,
      },
      fetchFriends,
      fetchListUsers,
      fetchMemories,
      tagFriend,
      deleteTag,
      memories,
      friends,
    } = this.props;

    const {
      boxScoresBySeason,
      selectedBoxScore,
      selectedSeason,
      selectedSeat,
      topPasser,
      topRusher,
      topReceiver,
      topOppPasser,
      topOppRusher,
      topOppReceiver,
      gameBannerDismissed,
      fetchedAttendees,
    } = this.state;

    const alreadyAttendedGames = fetchedUser && fetchedUser.games ? fetchedUser.games.map(game => game._id) : [];
    const alreadyAttendedGame = alreadyAttendedGames.includes(selectedBoxScore._id);

    const tailgateEligible = selectedSeason && Number(selectedSeason.split('-')[0]) >= 2008;

    return (
      <div className={css(styles.Games_container)}>
        <div>             
          <ReactModal
            style={{
              overlay: {
                margin: 'auto',
                width: '100%',
                border: `1px solid gray`,
                backgroundColor: 'rgb(0, 0, 0, 0.7)',
                overflow: 'hidden',
                zIndex: '100',
              },
              content: {
                position: 'absolute',
                margin: 'auto',
                top: '40px',
                left: '40px',
                right: '40px',
                bottom: '40px',
                background: '#fff',
                overflowY: 'scroll',
                WebkitOverflowScrolling: 'touch',
                outline: 'none',
                padding: '20px',
                maxWidth: '80%',
                height: 'fit-content',
              }
            }}
            isOpen={!gameBannerDismissed}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
          >
            <div>                                    
              <div className="center">
                <img width="150" src={riotRecordLogo} alt="Riot Record Logo" />
                <br />
                <br />
                <h4>Check in to any game in Panthers history here!<br />Build your history and personal stats!</h4>
                <br />
              </div>
              <div className={classnames(css(styles.Games_instructions), "container")}>
                <ol className="left">
                  <li>Select the season you attended a game</li>
                  <li>Choose the game that you went to from the list on the left</li>
                  <li>Select where you sat in the stadium by clicking the map</li>
                  <li>Don’t forget to mark if you attended the corresponding Roaring Riot tailgate!</li>
                </ol>
              </div>
              <div className="col s12 right" style={{ marginTop: "50px" }}>
                <Button flat waves="teal" modal="close" onClick={() => {
                  dismissGameBanner();
                  this.setState({ gameBannerDismissed: true });
                }}>
                  Continue
                </Button>
              </div>
            </div>
          </ReactModal>
        </div>
        <div className="row container">
          <div className="col s12 m4">
            <div className="row">
              <div className={classnames(css(styles.Games_header), "col s12")}>Check In</div>
            </div>
            <div className="row">
              <Select options={{ classes: css(styles.Games_dropdown) }} value={selectedSeason} onChange={this.onChangeSeason} m={12} s={12}>
                <option value="" disabled>Pick a season</option>
                {Object.keys(boxScoresBySeason).map((season) => {
                  return (
                    <option key={`season-${season}`} value={season}>
                      {season}
                    </option>
                  );
                })}
              </Select>
            </div>
            {selectedSeason &&
              <Row>
                <Col s={12}>
                    <Collection>
                      {boxScoresBySeason[selectedSeason].map((boxScore) => {
                        const weekName = Number(boxScore.week) ? `Week ${boxScore.week}` : boxScore.week;
                        return (
                          <CollectionItem key={`box-score-${boxScore._id}`} className={css(styles.Games_collectionItem, boxScore._id === selectedBoxScore._id && styles.Games_collectionItemActive)} onClick={() => this.onChangeGame(boxScore._id, selectedSeason, boxScore.week)}>
                            {`${alreadyAttendedGames.includes(boxScore._id) ? "★  " : ""}${weekName}: ${boxScore.teamName.split(' ').slice(-1)[0]} ${boxScore.gameLocation === 'N' ? 'vs' : boxScore.gameLocation} ${boxScore.opponentName.split(' ').slice(-1)[0]}`}
                          </CollectionItem>
                        );
                      })}
                    </Collection>
                </Col>
              </Row>
            }
            {!isTablet && !alreadyAttendedGame && tailgateEligible && selectedBoxScore && selectedBoxScore._id && isAuthenticated &&
              <div className={classnames(css(styles.Games_checkbox), "col s12")}>
                <Checkbox label="" className="inverted" value="attendedTailgate" onChange={this.onCheckboxClicked} />
                <span className={css(styles.Games_checkboxLabel)}>Attended the Roaring Riot Tailgate?</span>
              </div>
            }
            {!isTablet && this.renderCheckInButton(alreadyAttendedGame)}
          </div>
          <div className="col s12 m8" style={{ marginTop: '50px' }}>
            {selectedBoxScore && selectedBoxScore.stadium && <div className={css(styles.Games_stadiumBanner)}>{selectedBoxScore.stadium}</div>}
            {selectedBoxScore && selectedBoxScore._id && !alreadyAttendedGame &&
              <div className={css(styles.Games_instructionOverlay)}>
                {isAuthenticated ? 'Click on the map below to mark your seat location!' : 'Log in to mark your seat selection!'}
              </div>}
            <div
              className={css(styles.Games_stadiumImageContainer)}
              onClick={(e) => this.onStadiumClick(e, alreadyAttendedGame)}
            >
              {!isEmpty(selectedSeat) &&
                <FontAwesomeIcon
                  icon={faStar}
                  color={COLORS.yellow}
                  size='1x'
                  style={{
                    position: 'absolute',
                    left: `${selectedSeat.xPercent}%`,
                    top: `${selectedSeat.yPercent}%`,
                    transform: 'translate(-50%, -50%)',
                    pointerEvents: 'none',
                    zIndex: 100,
                  }}
                />}
              {selectedBoxScore && selectedBoxScore.attendees &&
                selectedBoxScore.attendees.map((attendee) => {
                  return (
                    <FontAwesomeIcon
                      data-tip={fetchedAttendees[attendee.userId]?.name}
                      key={`attendee-${attendee._id}`}
                      icon={attendee.userId === fetchedUser._id && attendee.attendedTailgate ? faCertificate : faStar}
                      color={attendee.userId === fetchedUser._id ? COLORS.yellow : COLORS.white}
                      size='1x'
                      style={{
                        position: 'absolute',
                        left: `${attendee.locationXPercent}%`,
                        top: `${attendee.locationYPercent}%`,
                        transform: 'translate(-50%, -50%)',
                        zIndex: attendee.userId === fetchedUser._id ? 100 : 50,
                      }}
                    />
                  );
                })}
              <img
                className={css(styles.Games_stadiumImage)}
                src={getStadiumImg(selectedBoxScore.stadium, selectedBoxScore.opponentName)}
                alt="Stadium"
              />
            </div>
          {isTablet && !alreadyAttendedGame && tailgateEligible && selectedBoxScore && selectedBoxScore._id && isAuthenticated &&
            <div className={classnames(css(styles.Games_checkbox), "col s12")}>
              <Checkbox
                label=""
                className="inverted"
                value="attendedTailgate"
                onChange={this.onCheckboxClicked}
              />
              <span className={css(styles.Games_checkboxLabel)}>Attended the Roaring Riot Tailgate?</span>
            </div>
          }
          {isTablet && this.renderCheckInButton(alreadyAttendedGame)}
          {selectedBoxScore && selectedBoxScore._id && (
            <div className={classnames(css(styles.Games_boxScoreInfo), "row")}>
              <Collapsible accordion={false}>
                <CollapsibleItem
                  expanded={false}
                  header="Game Info"
                  icon={<Icon>list</Icon>}
                  node="div"
                >
                  <div className={classnames("row")}>
                    <div className={classnames("col center s12")}>
                      {moment(String(selectedBoxScore.gameDate).split('T')[0]).format("MMMM Do YYYY")}
                    </div>
                    <div className={classnames("col center s12")}>
                      {selectedBoxScore.gameTime}
                    </div>
                    <div className={classnames("col center s12")}>
                      {selectedBoxScore.teamName} {selectedBoxScore.gameLocation} {selectedBoxScore.opponentName}
                    </div>
                    {selectedBoxScore.winLoss &&
                      <div className={classnames("col center s12")}>
                        {selectedBoxScore.pointsScored} - {selectedBoxScore.pointsAgainst} ({selectedBoxScore.winLoss} {selectedBoxScore.isOvertime && "(OT)"})
                      </div>}

                    {selectedBoxScore.winLoss &&
                      <>
                        <div className={classnames(css(styles.Games_extraStatsContainer), "col center s12")}>
                          <h5>Top Performers</h5>
                          <b>{selectedBoxScore.teamName}</b>
                          <div className="row">
                            <div className="col left m4">
                              Passing
                            </div>
                            <div className="col left m4">
                              {topPasser.player}
                            </div>
                            <div className="col right m4">
                              {topPasser.passYds} yards
                            </div>
                          </div>
                          <div className="row">
                            <div className="col left m4">
                              Rushing
                            </div>
                            <div className="col left m4">
                              {topRusher.player}
                            </div>
                            <div className="col right m4">
                              {topRusher.rushYds} yards
                            </div>
                          </div>
                          <div className="row">
                            <div className="col left m4">
                              Receiving
                            </div>
                            <div className="col left m4">
                              {topReceiver.player}
                            </div>
                            <div className="col right m4">
                              {topReceiver.recYds} yards
                            </div>
                          </div>
                          <b>{selectedBoxScore.opponentName}</b>
                          <div className="row">
                            <div className="col left m4">
                              Passing
                            </div>
                            <div className="col left m4">
                              {topOppPasser.player}
                            </div>
                            <div className="col right m4">
                              {topOppPasser.passYds} yards
                            </div>
                          </div>
                          <div className="row">
                            <div className="col left m4">
                              Rushing
                            </div>
                            <div className="col left m4">
                              {topOppRusher.player}
                            </div>
                            <div className="col right m4">
                              {topOppRusher.rushYds} yards
                            </div>
                          </div>
                          <div className="row">
                            <div className="col left m4">
                              Receiving
                            </div>
                            <div className="col left m4">
                              {topOppReceiver.player}
                            </div>
                            <div className="col right m4">
                              {topOppReceiver.recYds} yards
                            </div>
                          </div>
                        </div>
                        <div className={classnames(css(styles.Games_extraStatsContainer), "col center s12")}>
                          <h5>Scoring Plays</h5>
                          {!isTablet &&
                            <div className="row">
                              <div className="col left m4">
                                <b>Time</b>
                              </div>
                              <div className="col left m4">
                                <b>Play</b>
                              </div>
                              <div className="col left m4">
                                <b>Score</b>
                              </div>
                            </div>
                          }
                          {selectedBoxScore.scoringPlays && selectedBoxScore.scoringPlays.map((scoringPlay, i) => {
                            return (
                              <div className="row" key={`scoring-play-${scoringPlay.time}-${i}`}>
                                <div className="col left s12 m4">
                                  {scoringPlay.quarter} - {scoringPlay.time}
                                </div>
                                <div className="col left s12 m4">
                                  {scoringPlay.team}: {scoringPlay.description}
                                </div>
                                <div className="col right s12 m4">
                                  {scoringPlay.visitingTeamScore} - {scoringPlay.homeTeamScore}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>}
                  </div>
                </CollapsibleItem> 
                <CollapsibleItem
                  expanded={!selectedBoxScore.winLoss}
                  header="Attendees"
                  icon={<Icon>group</Icon>}
                  node="div"
                >
                  <PaginatedUserList
                    headerMessage={selectedBoxScore.winLoss ? "Who else went?" : "Who else is going?"}
                    emptyMessage={"No one has checked in yet! Be the first!"}
                    userIds={selectedBoxScore?.attendees?.map(attendee => attendee.userId) || []}
                    numUsersToShow={16}
                  />
                </CollapsibleItem>
                <CollapsibleItem
                  expanded={!!selectedBoxScore.winLoss}
                  header="Memories"
                  icon={<Icon>camera_roll</Icon>}
                  node="div"
                >
                  <MemoriesContainer   
                    selectedBoxScore={selectedBoxScore}
                    fetchFriends={fetchFriends}
                    fetchListUsers={fetchListUsers}
                    fetchMemories={fetchMemories}
                    tagFriend={tagFriend}
                    deleteTag={deleteTag}
                    isAuthenticated={isAuthenticated}
                    user={user}
                    friends={friends}
                    memories={memories}
                  />
                </CollapsibleItem>
              </Collapsible>
            </div>
          )}
          </div>
        </div>
      </div>
    );
  }
}

Games.defaultProps = {};

Games.propTypes = {
  auth: PropTypes.object.isRequired,
  fetchBoxScores: PropTypes.func.isRequired,
  fetchBoxScoreById: PropTypes.func.isRequired,
  fetchOffensiveStats: PropTypes.func.isRequired,
  fetchDefensiveStats: PropTypes.func.isRequired,
  fetchListUsers: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  tagFriend: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
  fetchedUser: PropTypes.object.isRequired,
  fetchMemories: PropTypes.func.isRequired,
  memories: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadingMemories: PropTypes.bool.isRequired,
  checkIntoGame: PropTypes.func.isRequired,
  removeGame: PropTypes.func.isRequired,
  boxScores: PropTypes.arrayOf(PropTypes.object).isRequired,
  gameCheckInSubmitting: PropTypes.bool.isRequired,
  removeGameSubmitting: PropTypes.bool.isRequired,
  window: PropTypes.object.isRequired,
  loadingFriends: PropTypes.bool.isRequired,
  friends: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  memories: state.boxScore.gameMemories,
  boxScores: state.boxScore.boxScores,
  window: state.window,
  fetchedUser: state.user.fetchedUser,
  auth: state.auth,
  loadingMemories: state.boxScore.loadingMemories,
  gameCheckInSubmitting: state.boxScore.gameCheckInSubmitting,
  removeGameSubmitting: state.boxScore.removeGameSubmitting,
  loadingFriends: state.user.loadingFriends,
  friends: state.user.friends,
});

export default connect(
  mapStateToProps,
  {
    fetchBoxScores,
    fetchBoxScoreById,
    fetchOffensiveStats,
    fetchDefensiveStats,
    fetchListUsers,
    fetchMemories,
    fetchUser,
    checkIntoGame,
    removeGame,
    fetchFriends,
    tagFriend,
    deleteTag,
  }
)(Games);
