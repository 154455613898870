import React from "react";

const AuthenticationLoader = () => {
  return (
    <div className="center-align container" style={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
      <div className="progress white secondary-blue">
          <div className="indeterminate"></div>
      </div>
    </div>
  );
}

export default AuthenticationLoader;
