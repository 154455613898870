import {
  LOAD_BOX_SCORES,
  SET_BOX_SCORES,
  LOAD_BOX_SCORE,
  SET_BOX_SCORE,
  SET_OFFENSIVE_STATS,
  SET_DEFENSIVE_STATS,
  GAME_CHECK_IN_SUBMITTING,
  GAME_CHECK_IN,
  REMOVE_GAME,
  REMOVE_GAME_SUBMITTING,
  LOAD_MEMORIES,
  SET_MEMORIES,
  TAG_FRIEND,
} from "../actions/types";

const initialState = {
  loadingBoxScores: false,
  loadingMemories: false,
  boxScores: [],
  offensiveStatsById: {},
  defensiveStatsById: {},
  gameCheckInSubmitting: false,
  checkedInGames: [],
  removeGameSubmitting: false,
  boxScoreById: {},
  boxScoreLoading: {},
  gameMemories: [],
  taggingFriend: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_MEMORIES:
      return {
        ...state,
        loadingMemories: action.payload,
      };
    case SET_MEMORIES:
      return {
        ...state,
        loadingMemories: false,
        gameMemories: action.payload,
      };
    case LOAD_BOX_SCORES:
      return {
        ...state,
        loadingBoxScores: !!action.payload,
      };
    case SET_BOX_SCORES:
      return {
        ...state,
        boxScores: action.payload,
      };
    case LOAD_BOX_SCORE:
      return {
        ...state,
        boxScoreLoading: {
          ...state.boxScoreLoading,
          [action.payload.id]: action.payload.loading,
        }
      };
    case SET_BOX_SCORE:
      return {
        ...state,
        boxScoreById: {
          ...state.boxScoreById,
          [action.payload._id]: action.payload.boxScore,
        },
      };
    case SET_OFFENSIVE_STATS:
      return {
        ...state,
        offensiveStatsById: {
          ...state.offensiveStatsById,
          [action.payload.id]: action.payload.stats,
        },
      };
    case SET_DEFENSIVE_STATS:
      return {
        ...state,
        defensiveStatsById: {
          ...state.defensiveStatsById,
          [action.payload.id]: action.payload.stats,
        },
      };
    case GAME_CHECK_IN_SUBMITTING:
      return {
        ...state,
        gameCheckInSubmitting: action.payload,
      };
    case GAME_CHECK_IN:
      const newGamesList = state.checkedInGames.slice();

      if (state.checkedInGames.includes(action.payload)) {
        newGamesList.push(action.payload)
      }
      return {
        ...state,
        checkedInGames: newGamesList,
      };
    case REMOVE_GAME:
      return {
        ...state,
        checkedInGames: state.checkedInGames.filter(el => el !== action.payload),
      };
    case REMOVE_GAME_SUBMITTING:
      return {
        ...state,
        removeGameSubmitting: action.payload,
      };
    case TAG_FRIEND:
      return {
        ...state,
        taggingFriend: action.payload,
      };
    default:
      return state;
  }
}
