import aceBoogieBadge from "../img/icons/AceBoogie.svg";
import birdsBadge from "../img/icons/Birds.svg";
import lightsBadge from "../img/icons/Lights.svg";
import playoffsBadge from "../img/icons/Playoffs.svg";
import runCMCBadge from "../img/icons/RunCMC.svg";
import riotBadge from "../img/icons/Riot.svg";
import bojanglesBadge from "../img/icons/Bojangles.svg";
import rainBadge from "../img/icons/Rain.svg";
import thievesBadge from "../img/icons/Thieves.svg";
import lukeBadge from "../img/icons/Luke.svg";
import peppersBadge from "../img/icons/Peppers.svg";
import topCatBadge from "../img/icons/TopCat.svg";
import spiderBadge from "../img/icons/Spider.svg";
import supermanBadge from "../img/icons/Superman.svg";
import hallBadge from "../img/icons/Hall.svg";
import iceUpBadge from "../img/icons/IceUp.svg";
import londonBadge from "../img/icons/London.svg";
import clemsonBadge from "../img/icons/Clemson.svg";
import newtonsOnFireBadge from "../img/icons/NewtonsOnFire.svg";
import badLuckBadge from "../img/icons/BadLuck.svg";
import firstBOABadge from "../img/icons/FirstBOA.svg";
import firstCheckInBadge from "../img/icons/FirstCheckIn.svg";
import firstWinBadge from "../img/icons/FirstWin.svg";
import winnersCircleBadge from "../img/icons/WinnersCircle.svg";
import thanksgivingBadge from "../img/icons/Thanksgiving.svg";
import thundercatsBadge from "../img/icons/Thundercats.svg";
import smittyBadge from "../img/icons/Smitty.svg";
import sacksBadge from "../img/icons/Sacks.svg";
import catchCMCBadge from "../img/icons/Catch22.svg";
import rush1000Badge from "../img/icons/1000Rush.svg";
import qb1000Badge from "../img/icons/QB1000.svg";
import doYouRiotBadge from "../img/icons/DoYouRiot.svg";
import riotRecordBadge from "../img/icons/RiotRecord.svg";
import saintsBadge from "../img/icons/Saints.svg";
import catFightBadge from "../img/icons/CatFight.svg";

const badgeIcons = (badgeType) => {
  switch (badgeType) {
    case 'first_time_check_in':
      return firstCheckInBadge;
    case 'first_win':
      return firstWinBadge;
    case 'home_game':
      return firstBOABadge;
    case 'bad_luck_charm':
      return badLuckBadge;
    case 'winners_circle_level_1':
    case 'winners_circle_level_2':
    case 'winners_circle_level_3':
    case 'winners_circle_level_4':
    case 'winners_circle_level_5':
      return winnersCircleBadge;
    case 'memorialized':
      return clemsonBadge;
    case 'superman_level_1':
    case 'superman_level_2':
    case 'superman_level_3':
      return supermanBadge;
    case 'ace_boogie_level_1':
    case 'ace_boogie_level_2':
    case 'ace_boogie_level_3':
      return aceBoogieBadge;
    case 'run_cmc_level_1':
    case 'run_cmc_level_2':
    case 'run_cmc_level_3':
      return runCMCBadge;
    case 'hall_of_honor_2019':
      return hallBadge;
    case 'ice_up_son_level_1':
    case 'ice_up_son_level_2':
    case 'ice_up_son_level_3':
      return iceUpBadge;
    case 'bojangles_connection_level_1':
    case 'bojangles_connection_level_2':
    case 'bojangles_connection_level_3':
      return bojanglesBadge;
    case 'top_cat':
      return topCatBadge;
    case 'under_the_lights_level_1':
    case 'under_the_lights_level_2':
    case 'under_the_lights_level_3':
      return lightsBadge;
    case 'yes_you_riot':
      return riotBadge;
    case 'bird_catcher':
      return birdsBadge;
    case 'talkin_bout_playoffs':
      return playoffsBadge;
    case 'luuuuke':
      return lukeBadge;
    case 'pep_squad':
      return peppersBadge;
    case 'spidey_sense':
      return spiderBadge;
    case 'downpour':
      return rainBadge;
    case 'turkey_time':
      return thanksgivingBadge;
    case 'keep_pounding_london':
      return londonBadge;
    case 'thieves_ave_level_1':
    case 'thieves_ave_level_2':
    case 'thieves_ave_level_3':
      return thievesBadge;
    case 'newtons_on_fire_level_1':
    case 'newtons_on_fire_level_2':
    case 'newtons_on_fire_level_3':
      return newtonsOnFireBadge;
    case 'thundercat':
      return thundercatsBadge;
    case 'smitty':
      return smittyBadge;
    case '1000_yard_back':
      return rush1000Badge;
    case 'sack_master_level_1':
    case 'sack_master_level_2':
    case 'sack_master_level_3':
      return sacksBadge;
    case 'catch_cmc_level_1':
    case 'catch_cmc_level_2':
    case 'catch_cmc_level_3':
      return catchCMCBadge;
    case 'qb_1000':
      return qb1000Badge;
    case 'cat_fight':
      return catFightBadge;
    case 'f#ck_the_saints_level_1':
    case 'f#ck_the_saints_level_2':
    case 'f#ck_the_saints_level_3':
      return saintsBadge;
    case 'do_you_riot_level_1':
    case 'do_you_riot_level_2':
    case 'do_you_riot_level_3':
      return doYouRiotBadge;
    default:
      return riotRecordBadge;
  }
};

const badgeLevel = (badge) => {
  const badge_split = badge.badgeType.split('_');
  if (badge_split.length > 2 && badge_split[badge_split.length - 2] === 'level') {
    const level = Number(badge_split[badge_split.length - 1]);
    return level;
  } else {
    return 1;
  }
}

const flattenBadges = (userBadges) => {
  const badgeGroups = {};
  if (userBadges && userBadges.length > 0) {
    userBadges.forEach((badge) => {
      if (badge.group === 'default') {
        if (!badgeGroups[badge.group]) {
          badgeGroups[badge.group] = []
        }
        badgeGroups[badge.group] = badgeGroups[badge.group].concat(badge);
      } else {
        const level = badgeLevel(badge);
        if ((badgeGroups[badge.group] && level > badgeLevel(badgeGroups[badge.group])) || !badgeGroups[badge.group]) {
          badgeGroups[badge.group] = badge;
        }
      }
    });
  }
  return Object.values(badgeGroups).reduce((acc, val) => acc.concat(val), []);
}

export {
  badgeLevel,
  badgeIcons,
  flattenBadges,
};
