const PLAYOFF_SORTING = {
  "Wild Card": 0,
  "Division": 1,
  "Conf. Champ.": 2,
  "SuperBowl": 3,
};

const sortBoxScores = (boxScores) => {
  boxScores.sort((boxScore1, boxScore2) => {
    // Sort by most recent season first (i.e. 2018-2019, 2017-2018, etc.)
    if (boxScore1.season > boxScore2.season) return -1;
    if (boxScore1.season < boxScore2.season) return 1;

    // If the season number is the same between both items, sort by week in ascending order
    // (i.e. Week 1-17, then Wild Card, Division, Conf. Champ., then SuperBowl)
    const boxScore1WeekNum = Number(boxScore1.week);
    const boxScore2WeekNum = Number(boxScore2.week);
    if (boxScore1WeekNum && boxScore2WeekNum) {
      return boxScore1WeekNum - boxScore2WeekNum;
    } else if (boxScore1WeekNum) {
      return -1;
    } else if (boxScore2WeekNum) {
      return 1;
    } else {
      return PLAYOFF_SORTING[boxScore1.week] - PLAYOFF_SORTING[boxScore2.week];
    }
  });

  return boxScores;
}

export {
  sortBoxScores,
};
