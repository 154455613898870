import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import userReducer from "./userReducer";
import boxScoreReducer from "./boxScoreReducer";
import windowDimensionReducer from "./windowDimensionReducer";
import activityReducer from "./activityReducer";

export default combineReducers({
  auth: authReducer,
  activity: activityReducer,
  errors: errorReducer,
  user: userReducer,
  window: windowDimensionReducer,
  boxScore: boxScoreReducer,
});
