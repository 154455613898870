import React, { PureComponent } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { css, StyleSheet } from "aphrodite";
import classnames from "classnames";
import { slide as Menu } from "react-burger-menu";

import riotRecordLogo from "../../img/logos/riot-record-logo.png";
import backgroundPattern from "../../img/backgrounds/background-pattern.png";
import COLORS from "../../utils/colors";
import WINDOW_DIMENSIONS from "../../utils/windowDimensions";
import { logoutUser } from "../../actions/authActions";

const { LARGE_DESKTOP_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  Navbar_container: {
    height: '120px',
  },
  Navbar_strip: {
    marginTop: '10px',
    backgroundColor: COLORS.lightGray,
    backgroundImage: `url("${backgroundPattern}")`,
    backgroundRepeat: 'repeat',
  },
  Navbar_wrapper: {
    width: '100%',
  },
  Navbar_logo: {
    marginTop: '-12px',
    [LARGE_DESKTOP_MEDIA_QUERY]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  Navbar_menuItem: {
    color: COLORS.black,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginLeft: '15px',
    fontSize: '18px',
    letterSpacing: '2px',
    cursor: 'pointer',
  },
  Navbar_menuIcon: {
    color: COLORS.black,
    top: '-70px',
    left: '25px',
    position: 'relative',
  },
  Navbar_menuButton: {
    color: COLORS.black,
    cursor: 'pointer',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginLeft: '40px',
    fontSize: '18px',
    letterSpacing: '2px',
    border: `1px solid ${COLORS.primaryBlue}`,
    backgroundColor: COLORS.white,
    opacity: '0.5',
    padding: '10px 20px 5px',
    justifyContent: 'center',
    height: '60px',
    ':hover': {
      opacity: '0.8',
    },
  },
  Navbar_links: {
    [LARGE_DESKTOP_MEDIA_QUERY]: {
      display: 'none',
    },
  },
  Navbar_hamburger: {
    display: 'none',
    [LARGE_DESKTOP_MEDIA_QUERY]: {
      display: 'initial',
      textAlign: 'center',
    },
  },
  Navbar_burgerMenuButton: {
    color: COLORS.black,
    display: 'flex',
    cursor: 'pointer',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginTop: '30px',
    fontSize: '18px',
    letterSpacing: '2px',
    border: `1px solid ${COLORS.primaryBlue}`,
    backgroundColor: COLORS.white,
    opacity: '0.5',
    padding: '10px 20px 5px',
    justifyContent: 'center',
    height: '60px',
    ':hover': {
      opacity: '0.8',
    },
  },
  Navbar_topStrip: {
    height: '10px',
    backgroundColor: COLORS.primaryBlue,
    position: 'absolute',
    width: '100%',
    top: '-10px',
    zIndex: '-1',
  },
});

const burgerStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '20px',
    height: '20px',
    left: '30px',
    top: '60px',
  },
  bmBurgerBars: {
    background: '#373a47',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    top: '20px',
    right: '20px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '110%',
    top: '-10px',
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    backgroundColor: '#373a47',
    padding: '0.8em',
    boxShadow: 'none',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

class Navbar extends PureComponent {
  render() {
    const {
      auth: {
        isAuthenticated,
        loadingApp,
      },
      logoutUser,
    } = this.props;

    return (
      <>
        <div className={css(styles.Navbar_hamburger)}>
          <Menu styles={burgerStyles} disableAutoFocus noOverlay>
            <a href="/about">About</a>
            <br />
            <a href="/games">Games</a>
            <br />
            <a href="/leaderboard">Leaderboard</a>
            <br />
            {isAuthenticated &&
              <>
                <a href="/profile">Profile</a>
                <br />
              </>}
            {isAuthenticated ? (
              <div
                className={classnames(css(styles.Navbar_burgerMenuButton), "valign-wrapper")}
                onClick={logoutUser}
              >
                Log Out
              </div>
            ) : (
              <a
                className={classnames(css(styles.Navbar_burgerMenuButton), "valign-wrapper")}
                href="/login"
              >
                Log In
              </a>
            )}
          </Menu>
        </div>
        <div className={css(styles.Navbar_container)}>
          <nav className={classnames(css(styles.Navbar_strip), "z-depth-0 nav-extended")}>
            <div className={classnames(css(styles.Navbar_container), "valign-wrapper container")}>
              <div className={classnames(css(styles.Navbar_wrapper), "nav-wrapper")}>
                <Link to={isAuthenticated ? "/dashboard" : "/"} className={classnames(css(styles.Navbar_logo), "brand-logo")}>
                  <img src={riotRecordLogo} height="100px" alt="Riot Record Logo" />
                </Link>
                <ul className={classnames(css(styles.Navbar_links), "right")}>
                  <li><a className={css(styles.Navbar_menuItem)} href="/about">About</a></li>
                  <li><a className={css(styles.Navbar_menuItem)} href="/games">Games</a></li>
                  <li><a className={css(styles.Navbar_menuItem)} href="/leaderboard">Leaderboard</a></li>
                  {isAuthenticated && <li><a className={css(styles.Navbar_menuItem)} href="/profile">Profile</a></li>}
                  <li>
                    {isAuthenticated ? (
                      <div
                        className={classnames(css(styles.Navbar_menuButton), "valign-wrapper")}
                        onClick={logoutUser}
                      >
                        Log Out
                      </div>
                    ) : (
                      <a
                        className={classnames(css(styles.Navbar_menuButton), "valign-wrapper")}
                        href="/login"
                      >
                        Log In
                      </a>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className={css(styles.Navbar_topStrip)} />
        </div>
      </>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(Navbar);
