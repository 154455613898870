export const GET_ERRORS = "GET_ERRORS";

// authActions
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_LOADING_APP = "SET_LOADING_APP";

// userActions
export const USER_FETCHED = "USER_FETCHED";
export const USER_LOADING = "USER_LOADING";
export const USER_LIST_LOADING = "USER_LIST_LOADING";
export const SET_LEADERBOARD = "SET_LEADERBOARD";
export const LOAD_LEADERBOARD = "LOAD_LEADERBOARD";
export const RECEIVE_BADGES = "RECEIVE_BADGES";
export const DISMISS_BADGE_MODAL = "DISMISS_BADGE_MODAL";
export const LOAD_FRIENDS = "LOAD_FRIENDS";
export const SET_FRIENDS = "SET_FRIENDS";

// windowDimensionActions
export const UPDATE_WINDOW_DIMENSION = 'UPDATE_WINDOW_DIMENSION';
export const UPDATE_SCROLL_POSITION = 'UPDATE_SCROLL_POSITION';

// boxScoreActions
export const LOAD_BOX_SCORES = "LOAD_BOX_SCORES";
export const SET_BOX_SCORES = "SET_BOX_SCORES";
export const GAME_CHECK_IN_SUBMITTING = "GAME_CHECK_IN_SUBMITTING";
export const GAME_CHECK_IN = "GAME_CHECK_IN";
export const REMOVE_GAME_SUBMITTING = "REMOVE_GAME_SUBMITTING";
export const REMOVE_GAME = "REMOVE_GAME";
export const LOAD_BOX_SCORE = "LOAD_BOX_SCORE";
export const SET_BOX_SCORE = "SET_BOX_SCORE";
export const SET_OFFENSIVE_STATS = "SET_OFFENSIVE_STATS";
export const SET_DEFENSIVE_STATS = "SET_DEFENSIVE_STATS";
export const LOAD_MEMORIES = "LOAD_MEMORIES";
export const SET_MEMORIES = "SET_MEMORIES";
export const TAG_FRIEND = "TAG_FRIEND";

// activityActions
export const LOAD_ACTIVITIES = "LOAD_ACTIVITIES";
export const SET_ACTIVITIES = "SET_ACTIVITIES";