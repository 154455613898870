import axios from "axios";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { openSesame } from "../utils/localStorageHelpers";
import { payloadActionGenerator } from '../utils/reduxHelpers';

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_LOADING_APP,
} from "./types";

const setLoadingApp = payloadActionGenerator(SET_LOADING_APP);

// Register User
export const registerUser = (userData, history) => async (dispatch) => {
  await axios
    .post("/api/users/register", userData)
    .then(res => {
      toast.info("Welcome to the Riot Record!");
      history.push("/login");
    }) // re-direct to login on successful register
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    });
};

// Login - get user token
export const loginUser = userData => async (dispatch) => {
  await axios
    .post("/api/users/login", userData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      // localStorage.setItem("jwtToken", token);
      // // Set token to Auth header
      // setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      toast.info("Welcome back! Keep Pounding!");
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Start forgot password flow and send recovery email
export const startForgotPassword = userData => async (dispatch) => {
  await axios
    .post("/api/users/forgot-password", userData)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Reset password
export const startResetPassword = (userData, history) => async (dispatch) => {
  await axios
    .post("/api/users/reset-password", userData)
    .then(res => history.push("/login")) // re-direct to login on successful change
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Verify JWT token
export const verifyToken = () => async (dispatch) => {
  dispatch(setLoadingApp(true)); 
  try {
    // Verify JWT token (stored in cookies)
    const response = await axios.get("/api/verify");
    dispatch(setCurrentUser(response.data));
    dispatch(setLoadingApp(false)); 
    
    // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (response.exp < currentTime) {
      // Logout user
      dispatch(logoutUser());
      // Redirect to login
      window.location.href = "/login";
    }
  } catch (err) {
    dispatch(setCurrentUser({}));
    dispatch(setLoadingApp(false)); 
    console.log(`Unauthorized JWT token: ${err}`);
  }
}

// Verify site password
export const verifySitePassphrase = (passphrase) => async (dispatch) => {
  if (!passphrase) {
    return false;
  }
  return await axios
    .post("/api/open-sesame", { passphrase })
    .then(res => {
      if (res.data.success) {
        openSesame(passphrase);
        return true;
      }
    })
    .catch(err => {
      console.log("Incorrect passphrase");
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
      return false;
    });
}

// Set logged in user
export const setCurrentUser = payloadActionGenerator(SET_CURRENT_USER);

// Log user out
export const logoutUser = () => async (dispatch) => {
  // Make call to /api/users/logout to clearCookie('jwt')
  await axios
    .post("/api/users/logout")
    .then(res => {
      // Set current user to empty object {} which will set isAuthenticated to false
      dispatch(setCurrentUser({}));
    });
  // Remove token from local storage
  // localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  // setAuthToken(false);
};
