import {
  USER_FETCHED,
  USER_LOADING,
  USER_LIST_LOADING,
  SET_LEADERBOARD,
  LOAD_LEADERBOARD,
  RECEIVE_BADGES,
  DISMISS_BADGE_MODAL,
  LOAD_FRIENDS,
  SET_FRIENDS,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  fetchedUser: {},
  loadingUser: false,
  loadingUserList: false,
  loadingLeaderboard: false,
  leaderboard: {},
  newBadges: [],
  loadingFriends: false,
  friends: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case USER_FETCHED:
      return {
        ...state,
        fetchedUser: action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        loadingUser: !!action.payload,
      };
    case USER_LIST_LOADING:
      return {
        ...state,
        loadingUserList: !!action.payload,
      };
    case SET_LEADERBOARD:
      return {
        ...state,
        leaderboard: action.payload,
      }
    case LOAD_LEADERBOARD:
      return {
        ...state,
        loadingLeaderboard: !!action.payload,
      }
    case RECEIVE_BADGES:
      return {
        ...state,
        newBadges: action.payload,
      }
    case DISMISS_BADGE_MODAL:
      return {
        ...state,
        newBadges: [],
      }
    case SET_FRIENDS:
      return {
        ...state,
        friends: action.payload || [],
      };
    case LOAD_FRIENDS:
      return {
        ...state,
        loadingFriends: !!action.payload,
      };
    default:
      return state;
  }
}
