import axios from "axios";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

import { emptyActionGenerator, payloadActionGenerator } from '../utils/reduxHelpers';
import { setCurrentUser } from './authActions';
import {
  USER_FETCHED,
  USER_LOADING,
  USER_LIST_LOADING,
  SET_LEADERBOARD,
  LOAD_LEADERBOARD,
  LOAD_FRIENDS,
  SET_FRIENDS,
  RECEIVE_BADGES,
  DISMISS_BADGE_MODAL,
  GET_ERRORS,
} from "./types";

// User loading
export const setUserLoading = payloadActionGenerator(USER_LOADING);
export const setFetchedUser = payloadActionGenerator(USER_FETCHED);
export const setUserListLoading = payloadActionGenerator(USER_LIST_LOADING);

// Leaderboard loading
export const setLeaderboardLoading = payloadActionGenerator(LOAD_LEADERBOARD);
export const setLeaderboard = payloadActionGenerator(SET_LEADERBOARD);

export const receiveBadges = payloadActionGenerator(RECEIVE_BADGES);
export const dismissBadgeModal = emptyActionGenerator(DISMISS_BADGE_MODAL);

// Friends loading
export const setFriendsLoading = payloadActionGenerator(LOAD_FRIENDS);
export const setFriends = payloadActionGenerator(SET_FRIENDS);

// Login - get user token
export const fetchUser = (id) => async (dispatch) => {
  dispatch(setUserLoading(true));
  return await axios
    .get(`/api/users/show/${id}`)
    .then(res => {
      // Set fetched user
      dispatch(setFetchedUser(res.data));
      dispatch(setUserLoading(false));
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setUserLoading(false));
    });
};

export const fetchLeaderboard = () => async (dispatch) => {
  dispatch(setLeaderboardLoading(true));
  return await axios
    .get('/api/users/leaderboard')
    .then(res => {
      dispatch(setLeaderboard(res.data));
      dispatch(setLeaderboardLoading(false));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setLeaderboardLoading(false));
    });
};

export const fetchListUsers = (userIds) => async (dispatch) => {
  if (userIds.length === 0) { 
    return []; 
  }

  return await axios
    .get('/api/users/list', {
      params: {
        ids: userIds,
      }
    })
    .then(res => {
      return res.data.users;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateUser = (id, updateData) => async (dispatch) => {
  dispatch(setUserListLoading(true));
  return await axios
    .put(`/api/users/update/${id}`, updateData)
    .then((res) => {
      const { token } = res.data;
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setUserListLoading(false));
      dispatch(setCurrentUser(decoded));
      toast.success("Update successful");
      return true;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setUserListLoading(false));
      return false;
    });
}

export const fetchFriends = (userId) => async (dispatch) => {
  dispatch(setFriendsLoading(true));
  return await axios
    .get(`/api/friendLists/${userId}`)
    .then(res => {
      const friends = res.data.friends;

      dispatch(setFriends(friends));
      dispatch(setFriendsLoading(false));

      return friends;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setFriendsLoading(false))
    });
};

export const addFriend = (friendId) => async (dispatch) => {
  dispatch(setFriendsLoading(true));
  return await axios
    .post('/api/friendLists/add', { friendId })
    .then(res => {
      const friends = res.data.friends;

      dispatch(setFriends(friends));
      dispatch(setFriendsLoading(false));

      return friends;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setFriendsLoading(false))
    });
};

export const removeFriend = (friendId) => async (dispatch) => {
  dispatch(setFriendsLoading(true));
  return await axios
    .delete(`/api/friendLists/${friendId}`)
    .then(res => {
      const friends = res.data.friends;

      dispatch(setFriends(friends));
      dispatch(setFriendsLoading(false));

      return friends;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setFriendsLoading(false))
    });
};
