import React, { Component } from "react";
import axios from "axios";
import { css, StyleSheet } from "aphrodite";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import ReactTooltip from 'react-tooltip';
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {
  verifyToken,
} from "./actions/authActions";
import {
  receiveBadges,
} from "./actions/userActions";
import {
  updateWindowDimension,
  updateScrollPosition,
} from "./actions/windowDimensionActions";

import store from "./store";
import "./App.css";

import ScrollToTop from "./ScrollToTop";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import Profile from "./components/profile/Profile";
import PublicProfile from "./components/profile/PublicProfile";
import Leaderboard from "./components/leaderboard/Leaderboard";
import Games from "./components/games/Games";
import About from "./components/about/About";
import Terms from "./components/about/Terms";
import Privacy from "./components/about/Privacy";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import FailedConnection from "./components/auth/FailedConnection";
import SuccessfulConnection from "./components/auth/SuccessfulConnection";
import BadgeDisplayModal from "./components/badges/BadgeDisplayModal";

import COLORS from "./utils/colors";

const styles = StyleSheet.create({
  App_container: {
    height: 'fit-content',
    position: 'relative',
    backgroundColor: COLORS.primaryBlue,
  },
  App_toastContainer: {
    background: 'black',
  },
  App_progressContainer: {
    background: `repeating-radial-gradient(circle at center, ${COLORS.primaryBlue}, black 30px)`,
  },
  App_openSesameModal: {
    width: '100%',
    top: '2%',
    height: '96%',
    maxHeight: '96%',
  },
  App_submitButton: {
    backgroundColor: COLORS.primaryBlue,
    width: '100%',
  },
  App_inputField: {
    margin: 'auto',
    width: '100%',
  },
  App_modalRow: {
    maxWidth: '60%',
    textAlign: 'center',
  },
});

class App extends Component {
  state = {
    verified: true,
    showBadges: false,
  };

  async componentDidMount() {
    // Listen for window resizing, and update `windowDimension` in Redux
    window.addEventListener('resize', () => store.dispatch(updateWindowDimension()));
    window.addEventListener('scroll', () => store.dispatch(updateScrollPosition()));
    if (window.outerWidth > 0 && window.outerHeight > 0) {
      // Sometimes window hasn't fully loaded yet so we shouldn't set to 0 in those
      // cases
      store.dispatch(updateWindowDimension());
    }
    axios.interceptors.response.use((response) => {
      // Do something with response data
      if (response.data.newBadges && response.data.newBadges.length > 0) {
        store.dispatch(receiveBadges(response.data.newBadges));
        this.setState({ showBadges: true });
      }
      return response;
    }, function (error) {
      // Do something with response error
      return Promise.reject(error);
    });

    await store.dispatch(verifyToken());
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const {
      verified,
      showBadges,
    } = this.state;

    return (
      <>
        <ReactTooltip />
        {verified &&
          <Provider store={store}>
            {showBadges && 
              <BadgeDisplayModal open={showBadges} onClose={() => this.setState({ showBadges: false })} />}

            <Router>
              <ScrollToTop>
                <div className={css(styles.App_container)}>
                  <Navbar />
                  <Route exact path="/" component={Landing} />
                  <Route exact path="/failed-connection" component={FailedConnection} />
                  <Route exact path="/successful-connection" component={SuccessfulConnection} />
                  <Route exact path="/terms" component={Terms} />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route exact path="/register" component={Register} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/leaderboard" component={Leaderboard} />
                  <Route exact path="/games/:season/:week" component={Games} />
                  <Route exact path="/games" component={Games} />
                  <Route exact path="/about" component={About} />
                  <Route exact path="/forgot-password" component={ForgotPassword} />
                  <Route exact path="/reset-password" component={ResetPassword} />
                  <Route exact path="/public-profile/:id" component={PublicProfile} />
                  <Switch>
                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    <PrivateRoute exact path="/profile" component={Profile} />
                  </Switch>
                  <Footer />
                </div>
              </ScrollToTop>
            </Router>

            <ToastContainer
              toastClassName={css(styles.App_toastContainer)}
              progressClassName={css(styles.App_progressContainer)}
            />
          </Provider>}
      </>
    );
  }
}

export default App;
