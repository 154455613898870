import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { css } from "aphrodite";
import _ from "lodash";
import classnames from "classnames";
import { Icon } from "react-materialize";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import {
  fetchListUsers,
} from "../../actions/userActions";

import { StyleSheet } from "aphrodite";
import COLORS from "../../utils/colors";
import WINDOW_DIMENSIONS from "../../utils/windowDimensions";

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  PaginatedUserList_container: {
    marginTop: '20px',
  },
  PaginatedUserList_profilePicture: {
    position: 'relative',
    height: '100px',
    width: '100px',
    margin: 'auto',
    backgroundColor: COLORS.primaryBlue,
    borderRadius: '4px',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    border: `1px solid ${COLORS.primaryBlue}`,
    minWidth: '100px',
    [MOBILE_MEDIA_QUERY]: {
      height: '75px',
      width: '75px',
      minWidth: '75px',
    },
  },
  PaginatedUserList_userBox: {
    whiteSpace: 'nowrap',
    overFlowX: 'hidden',
  },
  PaginatedUserList_paginationBar: {
    textAlign :'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }, 
  PaginatedUserList_paginationBarArrow: {
    cursor: 'pointer',
  },
});

class PaginatedUserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUsersPage: [],
      currentPageNumber: 1,
    };
  }

  componentDidMount = async () => {
    const { userIds, numUsersToShow, fetchListUsers } = this.props;

    const currentUsersPageIds = userIds.slice(0, numUsersToShow);
    const currentUsersPage = await fetchListUsers(currentUsersPageIds);

    this.setState({
      currentUsersPage,
      currentPageNumber: 1,
    });
  }

  componentDidUpdate = async (prevProps) => {
    const { userIds, numUsersToShow, fetchListUsers } = this.props;

    if (!_.isEqual(prevProps.userIds, userIds)) {
      const currentUsersPageIds = userIds.slice(0, numUsersToShow);
      const currentUsersPage = await fetchListUsers(currentUsersPageIds);

      this.setState({
        currentUsersPage,
        currentPageNumber: 1,
      });
    }
  }

  onPrevPage = async () => {
    const { currentPageNumber } = this.state;
    const { fetchListUsers, numUsersToShow, userIds } = this.props;

    if (currentPageNumber > 1 && userIds) {
      const sampleUsers = userIds.slice((currentPageNumber - 2) * numUsersToShow, (currentPageNumber - 1) * numUsersToShow);
      const currentUsersPage = await fetchListUsers(sampleUsers);
      
      this.setState({
        currentPageNumber: currentPageNumber - 1,
        currentUsersPage,
      });
    }
  }

  onNextPage = async () => {
    const { currentPageNumber } = this.state;
    const { fetchListUsers, numUsersToShow, userIds } = this.props;

    const sampleUsers = userIds.slice(currentPageNumber * numUsersToShow, (currentPageNumber + 1) * numUsersToShow);
    
    if (sampleUsers.length > 0) {
      const currentUsersPage = await fetchListUsers(sampleUsers);
      
      this.setState({
        currentPageNumber: currentPageNumber + 1,
        currentUsersPage,
      });
    }
  }
  
  render() {
    const {
      emptyMessage,
      headerMessage,
      loadingUserList,
      userIds,
      numUsersToShow,
    } = this.props;
    const {
      currentUsersPage,
      currentPageNumber,
    } = this.state;

    const totalPages = Math.ceil(parseFloat(userIds.length) / numUsersToShow);

    return (
      <div className={classnames("row")}>
        <div className={classnames(css(styles.PaginatedUserList_container), "col center s12")}>
          <h5>{headerMessage}</h5>
          <br />
          <div className="row">
            {currentUsersPage.length > 0 ? (
              <div>
                <div className="row">
                {loadingUserList ? (
                  <div className="indeterminate"></div>
                ) : (
                  currentUsersPage.map((user, i) => {
                    return (
                      <div key={`user-${user._id}-${i}`}>
                        <div className={classnames(css(styles.PaginatedUserList_userBox), "col center s6 m3")}>
                          <Link to={`/public-profile/${user._id}`}>
                            <div
                              className={css(styles.PaginatedUserList_profilePicture)}
                              style={{ backgroundImage: user.image ? `url(${user.image.secure_url})` : undefined }}
                            >
                              {!user.image && <FontAwesomeIcon icon={faUser} color='#FFFFFF' size='4x' style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} />}
                            </div>
                          </Link>
                          {user.name}
                        </div>
                      </div>
                    );
                  })
                )}
                </div>
                <div className={classnames(css(styles.PaginatedUserList_paginationBar), "row")}>
                  
                  <div onClick={() => { if (currentPageNumber !== 1) this.onPrevPage(); }} >
                    <Icon className={css(styles.PaginatedUserList_paginationBarArrow, currentPageNumber === 1 && styles.PaginatedUserList_disabled)}>
                      chevron_left
                    </Icon>
                  </div>
                  {currentPageNumber}
                  <div onClick={() => { if (currentPageNumber !== totalPages) this.onNextPage(); }}>
                    <Icon className={css(styles.PaginatedUserList_paginationBarArrow, currentPageNumber === totalPages && styles.PaginatedUserList_disabled)}>
                      chevron_right
                    </Icon>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col center s12">
                {emptyMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PaginatedUserList.defaultProps = {};

PaginatedUserList.propTypes = {
  userIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  numUsersToShow: PropTypes.number.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  headerMessage: PropTypes.string.isRequired,
  fetchListUsers: PropTypes.func.isRequired,
  loadingUserList: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  loadingUserList: state.user.loadingUserList,
});

export default connect(
  mapStateToProps,
  {
    fetchListUsers,
  }
)(PaginatedUserList);