import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { css, StyleSheet } from "aphrodite";
import classnames from "classnames";
import { Button, Textarea, TextInput, Checkbox } from "react-materialize";
import ReactModal from "react-modal";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons';

import COLORS from "../../utils/colors";
import {
  updateUser,
  fetchUser,
} from "../../actions/userActions";

const styles = StyleSheet.create({
  ProfileEditModal_container: {

  },
  ProfileEditModal_sectionHeader: {
    marginBottom: '40px',
  },
  ProfileEditModal_socialButton: {
    border: `1px solid ${COLORS.primaryBlue}`,
    borderRadius: '5px',
    height: '54px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '240px',
  },
  ProfileEditModal_socialContainer: {
    marginTop: '10px',
  },
});

const NAME_LENGTH = 50;
const LOCATION_LENGTH = 50;
const BIO_LENGTH = 250;

class ProfileEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.auth.user.email || '',
      password: '',
      password2: '',
      oldPassword: '',
      name: props.user.name || '',
      about: props.user.about || '',
      location: props.user.location || '',
      roaringRiot: props.user.roaringRiotMember,
      twitter: props.user.twitter,
      facebook: props.user.facebook,
      errors: {},
      changePasswordOpen: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.user !== this.props.user) {
      this.setState({
        name: this.props.user.name || '',
        about: this.props.user.about || '',
        location: this.props.user.location || '',
        roaringRiot: this.props.user.roaringRiotMember,
        twitter: this.props.user.twitter,
        facebook: this.props.user.facebook,
      });
    }
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors
      });
    }
  };

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  closeAndReset = async () => {
    const { user, onClose } = this.props;
    this.setState({
      errors: {},
      password: '',
      password2: '',
      oldPassword: '',
      changePasswordOpen: false,
    });
    await fetchUser(user._id);
    onClose();
  };

  onSubmit = async (e) => {
    const {
      user,
      updateUser,
      fetchUser,
    } = this.props;
    const {
      name,
      about,
      location,
      email,
      oldPassword,
      password,
      password2,
      roaringRiot,
    } = this.state;

    e.preventDefault();

    const updateData = {
      name,
      about,
      location,
      email,
      oldPassword,
      password,
      password2,
      roaringRiot,
    };

    const success = await updateUser(user._id, updateData);
    await fetchUser(user._id);

    if (success) {
      await this.closeAndReset();
    }
  };

  onCheckboxClicked = e => {
    this.setState({ [e.target.value]: e.target.checked });
  };

  disconnectTwitter = async () => {
    const {
      user,
      fetchUser,
    } = this.props;

    this.setState({ socialButtonEnabled: false });

    return await axios
      .get('/api/auth/twitter/disconnect')
      .then(async (res) => {
        await fetchUser(user._id);
        this.setState({ socialButtonEnabled: true });
      })
      .catch(err => {
        this.setState({ socialButtonEnabled: true });
      });
  };

  disconnectFacebook = async () => {
    const {
      user,
      fetchUser,
    } = this.props;

    this.setState({ facebookButtonEnabled: false });

    return await axios
      .get('/api/auth/facebook/disconnect')
      .then(async (res) => {
        await fetchUser(user._id);
        this.setState({ facebookButtonEnabled: true });
      })
      .catch(err => {
        this.setState({ facebookButtonEnabled: true });
      });
  }

  render() {
    const {
      open,
    } = this.props;
    const {
      email,
      password,
      password2,
      oldPassword,
      name,
      about,
      location,
      errors,
      changePasswordOpen,
      roaringRiot,
      twitter,
      facebook,
    } = this.state;

    const validInput =
      name && name.length <= NAME_LENGTH &&
      location.length <= LOCATION_LENGTH &&
      about.length <= BIO_LENGTH;

    return (
      <ReactModal
        isOpen={open}
        style={{
          overlay: {
            margin: 'auto',
            width: '100%',
            border: `1px solid gray`,
            backgroundColor: 'rgb(0, 0, 0, 0.7)',
            overflow: 'hidden',
            zIndex: '10000000',
          },
          content: {
            position: 'absolute',
            margin: 'auto',
            top: '40px',
            left: '40px',
            right: '40px',
            bottom: '40px',
            background: '#fff',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: '20px',
            maxWidth: '80%',
          }
        }}
        onRequestClose={this.closeAndReset}
        className={css(styles.ProfileEditModal_container)}
      >
        <div>
          <form onSubmit={this.onSubmit}>
            <h4 className={css(styles.ProfileEditModal_sectionHeader)}>Account</h4>
            <div className="input-field col s12">
              <TextInput
                onChange={this.onChange}
                value={email}
                error={errors.email}
                id="email"
                email
                validate
                s={12}
                label="Email"
                className={classnames("", {
                  invalid: errors.email
                })}
              />
            </div>
            <div className="col s12">
              {changePasswordOpen ? (
                <>
                  <div className="input-field col s12">
                    <TextInput
                      onChange={this.onChange}
                      value={oldPassword}
                      error={errors.oldPassword}
                      id="oldPassword"
                      password
                      s={12}
                      label="Current Password"
                      className={classnames("", {
                        invalid: errors.oldPassword
                      })}
                    />
                  </div>
                  <div className="input-field col s12">
                    <TextInput
                      onChange={this.onChange}
                      value={password}
                      error={errors.password}
                      id="password"
                      password
                      s={12}
                      label="Password"
                      className={classnames("", {
                        invalid: errors.password
                      })}
                    />
                  </div>
                  <div className="input-field col s12">
                    <TextInput
                      onChange={this.onChange}
                      value={password2}
                      error={errors.password2}
                      id="password2"
                      password
                      s={12}
                      label="Confirm Password"
                      className={classnames("", {
                        invalid: errors.password2
                      })}
                    />
                  </div>
                </>
              ) : (
                <>
                  <Button
                    style={{
                      backgroundColor: COLORS.primaryBlue,
                      width: "240px",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      fontSize: "12px",
                      marginBottom: "20px",
                    }}
                    onClick={() => this.setState({ changePasswordOpen: !changePasswordOpen })}
                    className="btn btn-large hoverable accent-3"
                  >
                    Change Password
                  </Button>
                  <br />
                  <div className={css(styles.ProfileEditModal_socialContainer)}>
                    {!twitter ? (
                      <div onClick={() => window.location.href = "/api/auth/twitter" } className={classnames(css(styles.ProfileEditModal_socialButton), "btn-flat waves-effect")}>
                        <FontAwesomeIcon icon={faTwitter} color={COLORS.primaryBlue} size='lg' style={{ marginRight: '10px' }} />
                        Connect to Twitter
                      </div>
                    ) : (
                      <div onClick={this.disconnectTwitter} className={classnames(css(styles.ProfileEditModal_socialButton), "btn-flat waves-effect")}>
                        <FontAwesomeIcon icon={faTwitter} color={COLORS.primaryBlue} size='lg' style={{ marginRight: '10px' }} />
                        Disconnect
                      </div>
                    )}
                  </div>
                  <br />
                  <div className={css(styles.ProfileEditModal_socialContainer)}>
                    {!facebook ? (
                      <div onClick={() => window.location.href = "/api/auth/facebook" } className={classnames(css(styles.ProfileEditModal_socialButton), "btn-flat waves-effect")}>
                        <FontAwesomeIcon icon={faFacebookF} color={COLORS.primaryBlue} size='lg' style={{ marginRight: '10px' }} />
                        Connect to Facebook
                      </div>
                    ) : (
                      <div onClick={this.disconnectFacebook} className={classnames(css(styles.ProfileEditModal_socialButton), "btn-flat waves-effect")}>
                        <FontAwesomeIcon icon={faFacebookF} color={COLORS.primaryBlue} size='lg' style={{ marginRight: '10px' }} />
                        Disconnect
                      </div>
                    )}
                  </div>
                  <br />
                  <br />
                </>
              )}
            </div>
            <h4 className={css(styles.ProfileEditModal_sectionHeader)}>Details</h4>
            <div className="input-field col s12">
              <TextInput
                onChange={this.onChange}
                value={name}
                error={errors.name}
                id="name"
                type="text"
                label="Name"
                s={12}
                className={classnames("", {
                  invalid: !!errors.name
                })}
                data-length={NAME_LENGTH}
              />
            </div>
            <div className="input-field col s12">
              <TextInput
                onChange={this.onChange}
                value={location}
                error={errors.location}
                id="location"
                type="text"
                label="Location"
                s={12}
                className={classnames("", {
                  invalid: !!errors.location
                })}
                data-length={LOCATION_LENGTH}
              />
            </div>
            <div className="input-field col s12">
              <Textarea
                onChange={this.onChange}
                value={about}
                error={errors.about}
                id="about"
                label="Bio"
                s={12}
                className={classnames("", {
                  invalid: !!errors.about
                })}
                data-length={BIO_LENGTH}
              />
            </div>
            <div className={classnames("col s12")}>
              <Checkbox
                value="roaringRiot"
                label="Are you a Roaring Riot Member already?"
                onChange={this.onCheckboxClicked}
                checked={roaringRiot}
              />
            </div>
            <div className="col s12 right" style={{ marginBottom: "50px" }}>
              <Button
                style={{
                  width: "150px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                  marginTop: "1rem"
                }}
                disabled={!validInput}
                type="submit"
                className="btn btn-large hoverable blue accent-3"
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </ReactModal>
    );
  }
}

ProfileEditModal.defaultProps = {};

ProfileEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  {
    updateUser,
    fetchUser,
  }
)(ProfileEditModal);
