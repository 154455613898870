import axios from "axios";
import { toast } from 'react-toastify';

import { payloadActionGenerator } from '../utils/reduxHelpers';
import { TEAM_ABBREVIATION_MAP } from '../utils/constants';
import { sortBoxScores } from '../utils/boxScoreHelpers';

import {
  LOAD_BOX_SCORES,
  SET_BOX_SCORES,
  LOAD_BOX_SCORE,
  SET_BOX_SCORE,
  SET_OFFENSIVE_STATS,
  SET_DEFENSIVE_STATS,
  GAME_CHECK_IN,
  GAME_CHECK_IN_SUBMITTING,
  REMOVE_GAME,
  REMOVE_GAME_SUBMITTING,
  GET_ERRORS,
  LOAD_MEMORIES,
  SET_MEMORIES,
  TAG_FRIEND,
} from "./types";

// Box score loading
export const setBoxScoresLoading = payloadActionGenerator(LOAD_BOX_SCORES);
export const setBoxScores = payloadActionGenerator(SET_BOX_SCORES);
export const setBoxScoreLoading = payloadActionGenerator(LOAD_BOX_SCORE);
export const setBoxScore = payloadActionGenerator(SET_BOX_SCORE);
export const setOffensiveStats = payloadActionGenerator(SET_OFFENSIVE_STATS);
export const setDefensiveStats = payloadActionGenerator(SET_DEFENSIVE_STATS);
export const setMemoriesLoading = payloadActionGenerator(LOAD_MEMORIES);
export const setMemories = payloadActionGenerator(SET_MEMORIES);
export const taggingFriend = payloadActionGenerator(TAG_FRIEND);

export const gameCheckInSubmitting = payloadActionGenerator(GAME_CHECK_IN_SUBMITTING);
export const gameCheckIn = payloadActionGenerator(GAME_CHECK_IN);
export const removeGameSubmitting = payloadActionGenerator(REMOVE_GAME_SUBMITTING);
export const removeGameFromList = payloadActionGenerator(REMOVE_GAME);

// Fetch memories
export const fetchMemories = (boxScoreId) => async (dispatch) => {
  dispatch(setMemoriesLoading(true));
  return await axios
    .get(`/api/memories/game/${boxScoreId}`)
    .then(res => {
      const memories = res.data.memories;

      dispatch(setMemories(memories));

      return memories;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setMemoriesLoading(false));
    });
};

// Tag friend in memory
export const tagFriend = (memoryId, friendId, locationXPercent, locationYPercent) => async (dispatch) => {
  dispatch(taggingFriend(true));
  return await axios
    .post('/api/memories/tag', 
      {
        memoryId,
        friendId,
        locationXPercent,
        locationYPercent,
      }
    )
    .then(res => {
      dispatch(taggingFriend(false));
      return res.data.memory;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(taggingFriend(false));
    });
}

// Delete tag in memory
export const deleteTag = (memoryId, friendId) => async (dispatch) => {
  dispatch(taggingFriend(true));
  return await axios
    .delete(`/api/memories/tag/${friendId}?memoryId=${memoryId}`)
    .then(res => {
      dispatch(taggingFriend(false));
      return res.data.memory;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(taggingFriend(false));
    });
}

// Fetch box scores
export const fetchBoxScores = (boxScoreParameters) => async (dispatch) => {
  const boxScorePath = boxScoreParameters.map(p => encodeURI(p)).join('/');
  dispatch(setBoxScoresLoading(true));
  return await axios
    .get(`/api/boxScores/${boxScorePath}`)
    .then(res => {
      // Set fetched box scores
      const boxScores = res.data;

      const sortedScores = sortBoxScores(boxScores);

      dispatch(setBoxScores(sortedScores));
      dispatch(setBoxScoresLoading(false));

      return sortedScores;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setBoxScoresLoading(false));
    });
};

export const fetchOffensiveStats = (boxScoreId) => async (dispatch) => {
  dispatch(setBoxScoresLoading(true));
  return await axios
    .get(`/api/offensePlayerStats/game/${boxScoreId}`)
    .then(res => {
      const offensivePlayerStats = res.data;

      dispatch(setOffensiveStats({
        id: boxScoreId,
        stats: offensivePlayerStats,
      }));
      dispatch(setBoxScoresLoading(false));

      return offensivePlayerStats;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setBoxScoresLoading(false));
    });
};

export const fetchDefensiveStats = (boxScoreId) => async (dispatch) => {
  dispatch(setBoxScoresLoading(true));
  return await axios
    .get(`/api/defensePlayerStats/game/${boxScoreId}`)
    .then(res => {
      const defensivePlayerStats = res.data;

      dispatch(setDefensiveStats({
        id: boxScoreId,
        stats: defensivePlayerStats,
      }));
      dispatch(setBoxScoresLoading(false));

      return defensivePlayerStats;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setBoxScoresLoading(false));
    });
};

// Fetch single box score by id
export const fetchBoxScoreById = (id) => async (dispatch) => {
  dispatch(setBoxScoreLoading({ id, loading: true }));
  return await axios
    .get(`/api/boxScores/id/${id}`)
    .then(res => {
      // Set fetched box score
      const boxScore = res.data;

      dispatch(setBoxScore({ id, boxScore }));
      dispatch(setBoxScoreLoading({ id, loading: false }));

      return boxScore;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(setBoxScoreLoading({ id, loading: false }));
    });
};

export const checkIntoGame = (boxScore, selectedSeat, attendedTailgate) => async (dispatch) => {
  const gameData = {
    gameId: boxScore._id,
    team: TEAM_ABBREVIATION_MAP[boxScore.teamName],
    locationXPercent: selectedSeat.xPercent,
    locationYPercent: selectedSeat.yPercent,
    attendedTailgate,
  };
  dispatch(gameCheckInSubmitting(true));
  await axios
    .post("/api/boxScores/checkin", gameData)
    .then(res => {
      if (res.data && res.data.success) {
        toast("Successfully checked into the game! #KeepPounding!");
        dispatch(gameCheckInSubmitting(false));
        dispatch(gameCheckIn(boxScore._id));
      } else {
        toast.error("Something went wrong with that request. Please try again later.");
        dispatch(gameCheckInSubmitting(false));
      }
    })
    .catch(err => {
      if (err.response && err.response.data && err.response.data.error) {
        toast.error(err.response.data.error);
      } else {
        toast.error(err.message);
      }
      dispatch(gameCheckInSubmitting(false));
    });
};

export const removeGame = (boxScore) => async (dispatch) => {
  const gameData = { gameId: boxScore._id, team: TEAM_ABBREVIATION_MAP[boxScore.teamName] };
  dispatch(removeGameSubmitting(true));
  await axios
    .post("/api/boxScores/remove-checkin", gameData)
    .then(res => {
      if (res.data && res.data.success) {
        toast.success("Successfully removed this game!");
        dispatch(removeGameSubmitting(false));
        dispatch(removeGameFromList(boxScore._id));
      } else {
        toast.error("Something went wrong with that request. Please try again later.");
        dispatch(removeGameSubmitting(false));
      }
    })
    .catch(err => {
      if (err.response && err.response.data && err.response.data.error) {
        toast.error(err.response.data.error);
      } else {
        toast.error(err.message);
      }
      dispatch(removeGameSubmitting(false));
    });
};
