import React, { Component } from "react";
import { Link } from "react-router-dom";
import { css, StyleSheet } from "aphrodite";
import ReactTooltip from "react-tooltip";

import COLORS from "../../utils/colors";
import heroImage from "../../img/us-outline-no-dots.png";

const styles = StyleSheet.create({
  GameMap_heroImage: {
    width: '100%',
  },
  GameMap_imageWrapper: {
    width: '100%',
    height: 'inherit',
    position: 'relative',
    marginTop: '50px',
  },
  GameMap_mapDot: {
    position: 'absolute',
    width: '1%',
    minWidth: '8px',
    height: '1.5%',
    minHeight: '8px',
    backgroundColor: 'white',
    borderRadius: '100%',
    ':hover': {
      transform: 'scale(1.1)',
      boxShadow: `0 0 10px 2px #fff, 0 0 14px 3px ${COLORS.primaryBlue}`,
    },
  },
  GameMap_losAngeles: {
    top: '59%',
    left: '7%',
  },
  GameMap_losAngeles: {
    top: '59%',
    left: '7%',
  },
  GameMap_kansasCity: {
    top: '46%',
    left: '53%',
  },
  GameMap_washington: {
    top: '41%',
    left: '85%',
  },
  GameMap_newOrleans: {
    top: '80%',
    left: '61%',
  },
  GameMap_atlanta: {
    top: '63.5%',
    left: '73.5%',
  },
  GameMap_tampaBay: {
    top: '84.5%',
    left: '80%',
  },
  GameMap_greenBay: {
    top: '22.5%',
    left: '62.5%',
  },
  GameMap_minnesota: {
    top: '22%',
    left: '55%',
  },
  GameMap_carolina: {
    top: '57.8%',
    left: '81%',
  },
  GameMap_arizona: {
    top: '63.5%',
    left: '17.5%',
  },
  GameMap_buffalo: {
    top: '26%',
    left: '81%',
  },
  GameMap_dallas: {
    top: '72.5%',
    left: '49%',
  },
  GameMap_miami: {
    top: '95%',
    left: '85%',
  },
  GameMap_newYorkGiants: {
    top: '30%',
    left: '90%',
  },
  GameMap_houston: {
    top: '82.5%',
    left: '51.5%',
  },
  GameMap_sanFrancisco: {
    top: '40.5%',
    left: '2%',
  },
  GameMap_indianapolis: {
    top: '46%',
    left: '68%',
  },
})

class GameMap extends Component {
  render() {
    return (
      <div className={css(styles.GameMap_imageWrapper)}>
        <ReactTooltip />
        <img className={css(styles.GameMap_heroImage)} src={heroImage} alt="Hero" />
        <Link data-tip="Arizona Cardinals" className={css(styles.GameMap_mapDot, styles.GameMap_arizona)} to="/">&nbsp;</Link>
        <Link data-tip="Atlanta Falcons" className={css(styles.GameMap_mapDot, styles.GameMap_atlanta)} to="/">&nbsp;</Link>
        <Link data-tip="Buffalo Bills" className={css(styles.GameMap_mapDot, styles.GameMap_buffalo)} to="/">&nbsp;</Link>
        <Link data-tip="New Orleans Saints" className={css(styles.GameMap_mapDot, styles.GameMap_newOrleans)} to="/">&nbsp;</Link>
        <Link data-tip="Tampa Bay Buccaneers" className={css(styles.GameMap_mapDot, styles.GameMap_tampaBay)} to="/">&nbsp;</Link>
        <Link data-tip="Dallas Cowboys" className={css(styles.GameMap_mapDot, styles.GameMap_dallas)} to="/">&nbsp;</Link>
        <Link data-tip="Keep Pounding"className={css(styles.GameMap_mapDot, styles.GameMap_carolina )} to="/">&nbsp;</Link>
        <Link data-tip="Miami Dolphins" className={css(styles.GameMap_mapDot, styles.GameMap_miami)} to="/">&nbsp;</Link>
        <Link data-tip="New York Giants" className={css(styles.GameMap_mapDot, styles.GameMap_newYorkGiants)} to="/">&nbsp;</Link>
        <Link data-tip="Houston Texans" className={css(styles.GameMap_mapDot, styles.GameMap_houston)} to="/">&nbsp;</Link>
      </div>
    );
  }
}

export default GameMap;
