import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { css, StyleSheet } from "aphrodite";
import classnames from "classnames";

import { fetchLeaderboard } from "../../actions/userActions";
import COLORS from "../../utils/colors";

import largeBackgroundPattern from "../../img/backgrounds/large-background-pattern.png";

import LeaderboardTable from "./LeaderboardTable";

const styles = StyleSheet.create({
  Leaderboard_container: {
    position: 'relative',
    marginBottom: '0',
    display: 'flex',
    minHeight: '1000px',
    backgroundColor: COLORS.white,
    backgroundImage: `url("${largeBackgroundPattern}")`,
    backgroundRepeat: 'repeat',
    backgroundPosition: 'center center',
    backgroundSize: '50%',
  },
  Leaderboard_innerContainer: {

  },
});

class Leaderboard extends Component {
  async componentDidMount() {
    const { fetchLeaderboard } = this.props;

    await fetchLeaderboard();
  }

  render() {
    const {
      leaderboard,
    } = this.props;

    return (
      <div className={css(styles.Leaderboard_container)}>
        <div className={classnames(css(styles.Leaderboard_innerContainer), "row container")}>
          <div className="col s1" />
          <div className="col s12 m12 l5">
            <br />
            <br />
            <LeaderboardTable
              title="Games Attended"
              selector="gameCount"
              readableKey="Total Games"
              data={leaderboard.gameCountUsers}
            />
            <br />
            <br />
            <LeaderboardTable
              title="Most Wins"
              selector="winCount"
              readableKey="Total Wins"
              data={leaderboard.winCountUsers}
            />
            <br />
            <br />
            <LeaderboardTable
              title="Most Playoff Games"
              selector="playoffGameCount"
              readableKey="Playoff Games"
              data={leaderboard.playoffGameCountUsers}
            />
            <br />
            <br />
            <LeaderboardTable
              title="Most Points Scored"
              selector="pointsScoredCount"
              readableKey="Total Points"
              data={leaderboard.pointsScoredCountUsers}
            />
            <br />
            <br />
            <LeaderboardTable
              title="Most Sacks"
              selector="sackCount"
              readableKey="Total Sacks"
              data={leaderboard.sackCountUsers}
            />
          </div>
          <div className="col s1" />
          <div className="col s12 m12 l5">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <LeaderboardTable
              title="Touchdowns"
              selector="touchdownCount"
              readableKey="Touchdowns"
              data={leaderboard.touchdownCountUsers}
            />
            <br />
            <br />
            <LeaderboardTable
              title="Home Games"
              selector="homeGameCount"
              readableKey="Home Games"
              data={leaderboard.homeGameCountUsers}
            />
            <br />
            <br />
            <LeaderboardTable
              title="Most Passing Yards"
              selector="passingYardCount"
              readableKey="Total Passing Yards"
              data={leaderboard.passingYardCountUsers}
            />
            <br />
            <br />
            <LeaderboardTable
              title="Most Rushing Yards"
              selector="rushingYardCount"
              readableKey="Total Rushing Yards"
              data={leaderboard.rushingYardCountUsers}
            />
            <br />
            <br />
            <LeaderboardTable
              title="Most Interceptions"
              selector="interceptionCount"
              readableKey="Total Interceptions"
              data={leaderboard.interceptionCountUsers}
            />
          </div>
        </div>
      </div>
    );
  }
}

Leaderboard.propTypes = {
  fetchLeaderboard: PropTypes.func.isRequired,
  leaderboard: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  leaderboard: state.user.leaderboard,
});

export default connect(
  mapStateToProps,
  {
    fetchLeaderboard,
  }
)(Leaderboard);
