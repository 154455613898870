import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { css, StyleSheet } from "aphrodite";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import COLORS from "../../utils/colors";
import WINDOW_DIMENSIONS from "../../utils/windowDimensions";

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  LeaderboardTable_container: {
    position: 'relative',
    paddingBottom: '80px',
    minHeight: '500px',
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.primaryBlue}`,
  },
  LeaderboardTable_titleBanner: {
    backgroundColor: COLORS.primaryBlue,
    paddingTop: '5px',
    position: 'absolute',
    top: '10px',
    width: '110%',
    margin: 'auto',
    color: COLORS.white,
    fontSize: '32px',
    fontWeight: 600,
    textAlign: 'center',
    left: '-5%',
    textTransform: 'uppercase',
    [MOBILE_MEDIA_QUERY]: {
      fontSize: '26px',
    },
  },
  LeaderboardTable_dataContainer: {
    position: 'relative',
    top: '80px',
    width: '80%',
    margin: 'auto',
    color: COLORS.primaryBlue,
  },
  LeaderboardTable_dataRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  LeaderboardTable_profilePicture: {
    height: '100px',
    width: '100px',
    backgroundColor: COLORS.primaryBlue,
    borderRadius: '4px',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    border: `1px solid ${COLORS.primaryBlue}`,
    minWidth: '100px',
    [MOBILE_MEDIA_QUERY]: {
      height: '75px',
      width: '75px',
      minWidth: '75px',
    },
  },
  LeaderboardTable_dataDetails: {
    marginLeft: '25px',
    width: '75%',
  },
  LeaderboardTable_name: {
    fontSize: '24px',
    fontWeight: 600,
    textTransform: 'uppercase',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  LeaderboardTable_details: {
    fontSize: '18px',
  },
});

class LeaderboardTable extends Component {
  renderRow(dataRow, selector) {
    const {
      _id,
      name,
      image,
      [selector]: details,
    } = dataRow;
    const {
      readableKey,
    } = this.props;

    return (
      <div key={`row-${_id}`} className={css(styles.LeaderboardTable_dataRow)}>
        <Link to={`/public-profile/${_id}`}>
          <div
            className={css(styles.LeaderboardTable_profilePicture)}
            style={{ position: 'relative', backgroundImage: image ? `url(${image.secure_url})` : undefined }}
          >
            {!image && <FontAwesomeIcon icon={faUser} color='#FFFFFF' size='5x' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
          </div>
        </Link>
        <div className={css(styles.LeaderboardTable_dataDetails)}>
          <Link to={`/public-profile/${_id}`}>
            <div className={css(styles.LeaderboardTable_name)}>
              {name}
            </div>
          </Link>
          <div className={css(styles.LeaderboardTable_details)}>
            {readableKey}: {details}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      title,
      selector,
      data,
    } = this.props;

    return (
      <div className={css(styles.LeaderboardTable_container)}>
        <div className={css(styles.LeaderboardTable_titleBanner)}>
          {title}
        </div>
        <div className={css(styles.LeaderboardTable_dataContainer)}>
          {data.map((dataRow) => this.renderRow(dataRow, selector))}
        </div>
      </div>
    );
  }
}

LeaderboardTable.defaultProps = {
  data: [],
};

LeaderboardTable.propTypes = {
  title: PropTypes.string.isRequired,
  selector: PropTypes.string.isRequired,
  readableKey: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
};

export default LeaderboardTable;
