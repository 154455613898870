import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { css, StyleSheet } from "aphrodite";
import ReactModal from "react-modal";
import { Button } from "react-materialize";
import { TwitterShareButton } from 'react-twitter-embed';

import { badgeIcons } from "../../utils/badgeHelpers";
import { dismissBadgeModal } from "../../actions/userActions";

const styles = StyleSheet.create({
  BadgeDisplayModal_container: {
    textAlign: 'center',
  },
  BadgeDisplayModal_details: {
    paddingTop: '20px',
  },
  BadgeDisplayModal_badgeDetails: {
    fontWeight: 500,
    marginTop: '20px',
    marginBottom: '10px',
    fontSize: '24px',
  },
  BadgeDisplayModal_badgeDescription: {
    marginBottom: '20px',
  },
  BadgeDisplayModal_separator: {
    marginBottom: '60px',
  },
  BadgeDisplayModal_shareSection: {
    marginTop: '10px',
    marginBottom: '60px',
  }
});

class BadgeDisplayModal extends Component {
  render() {
    const {
      open,
      badges,
      onClose,
    } = this.props;

    return (
      <ReactModal
        style={{
          overlay: {
            margin: 'auto',
            width: '100%',
            border: `1px solid gray`,
            backgroundColor: 'rgb(0, 0, 0, 0.7)',
            overflow: 'hidden',
            zIndex: '10000000',
          },
          content: {
            position: 'absolute',
            margin: 'auto',
            top: '40px',
            left: '40px',
            right: '40px',
            bottom: '40px',
            background: '#fff',
            overflowY: 'scroll',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            padding: '20px',
            maxWidth: '80%',
          }
        }}
        isOpen={open}
        onRequestClose={() => {
          dismissBadgeModal();
          onClose();
        }}
      >
        <div className={css(styles.BadgeDisplayModal_container)}>
          <h4>New Badges Earned!</h4>
          {badges.map((badge, i) => {
            return (
              <div key={`badge-display-${i}`}>
                <div className={css(styles.BadgeDisplayModal_details)}>
                  <br />
                  <img width="250px" src={badgeIcons(badge.badgeType)} alt="Badge" />
                  <div className={css(styles.BadgeDisplayModal_badgeDetails)}>{badge.details}</div>
                  <div className={css(styles.BadgeDisplayModal_badgeDescription)}>{badge.description}</div>
                </div>
                <div className={css(styles.BadgeDisplayModal_shareSection)}>
                  <TwitterShareButton
                    url={"https://www.theriotrecord.com"}
                    options={{ text: `I earned the ${badge.details} badge on The Riot Record! Check out`, via: 'theriotrecord' }}
                  />
                </div>
                {i !== badges.length - 1 && <hr className={css(styles.BadgeDisplayModal_separator)} />}
              </div>
            );
          })}
          <div className="col s12 right" style={{ marginTop: "50px" }}>
            <Button flat waves="teal" modal="close" onClick={() => {
              dismissBadgeModal();
              onClose();
            }}>
              Continue
            </Button>
          </div>
        </div>
      </ReactModal>
    );
  }
}

BadgeDisplayModal.propTypes = {
  open: PropTypes.bool.isRequired,
  badges: PropTypes.arrayOf(PropTypes.object).isRequired,
  dismissBadgeModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  badges: state.user.newBadges,
});

export default connect(
  mapStateToProps,
  { dismissBadgeModal }
)(BadgeDisplayModal);
