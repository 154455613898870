import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AuthenticationLoader from "./AuthenticationLoader";

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  if (auth.isCheckingAuthentication) {
    return <AuthenticationLoader />
  } else {
    return (
      <Route
        {...rest}
        render={props =>
          (auth.isAuthenticated === true) ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
