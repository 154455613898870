import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { css, StyleSheet } from "aphrodite";
import classnames from "classnames";
import qs from "query-string";
import { toast } from "react-toastify";
import { Button, TextInput } from "react-materialize";

import { startResetPassword } from "../../actions/authActions";
import COLORS from "../../utils/colors";

const styles = StyleSheet.create({
  ResetPassword_outerContainer: {
    minHeight: '700px',
  },
  ResetPassword_container: {
    backgroundColor: COLORS.white,
    paddingBottom: '50px',
    paddingTop: '5px',
    marginTop: '40px',
  },
  ResetPassword_link: {
    color: COLORS.primaryBlue,
    cursor: 'pointer',
  },
})

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      password2: "",
      errors: {},
      hasSubmitted: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth !== this.props.auth) {
      if (this.props.auth.isAuthenticated) {
        this.props.history.push("/dashboard"); // push user to dashboard when they login
      }
    }
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors
      });
    }
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      code: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).code,
      password: this.state.password,
      password2: this.state.password2,
    };

    this.props.startResetPassword(userData, this.props.history);

    this.setState({ hasSubmitted: true });
    toast.success("Password reset successful! Please try logging in again!");
  };

  render() {
    const { errors, hasSubmitted } = this.state;

    return (
      <div className={css(styles.ResetPassword_outerContainer)}>
        <div className={classnames(css(styles.ResetPassword_container), "container")}>
          <div style={{ marginTop: "4rem" }} className="row">
            <div className="col s8 offset-s2">
              <Link to="/" className="btn-flat waves-effect">
                <i className="material-icons left">keyboard_backspace</i> Back to
                home
              </Link>
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <h4>
                  <b>Reset Password?</b>
                </h4>
              </div>
              {hasSubmitted ? (
                <>
                  <div className="col s12">Check your email for the next steps to recover your account</div>
                  <div className="col s12">
                    If you do not receive an email after waiting for more than 5 minutes,
                    <span
                      className={css(styles.ResetPassword_link)}
                      onClick={() => this.setState({ hasSubmitted: false, email: '' })}
                    > please try again.
                    </span>
                  </div>
                </>
              ) : (
                <form noValidate onSubmit={this.onSubmit}>
                  <div className="input-field col s12">
                    <TextInput
                      onChange={this.onChange}
                      value={this.state.password}
                      error={errors.password}
                      id="password"
                      password
                      label="New Password"
                      className={classnames("", {
                        invalid: errors.password
                      })}
                    />
                  </div>
                  <div className="input-field col s12">
                    <TextInput
                      onChange={this.onChange}
                      value={this.state.password2}
                      error={errors.password2}
                      id="password2"
                      password
                      label="Confirm Password"
                      className={classnames("", {
                        invalid: errors.password2
                      })}
                    />
                  </div>
                  <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                    <Button
                      style={{
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        marginTop: "1rem"
                      }}
                      type="submit"
                      className="btn btn-large hoverable blue accent-3"
                    >
                      Reset Password
                    </Button>
                  </div>
                  <div className="col s12 grey-text text-darken-1" style={{ marginTop: "20px" }}>
                    Remember your account password now? <Link to="/login">Login</Link> instead.
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  startResetPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { startResetPassword }
)(ResetPassword);
