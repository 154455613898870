import React, { PureComponent } from "react";
import { css, StyleSheet } from "aphrodite";
import classnames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

import roaringRiotLogo from "../../img/logos/roaring-riot-logo.png";
import COLORS from "../../utils/colors";
import WINDOW_DIMENSIONS from "../../utils/windowDimensions";

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  Footer_container: {
    height: '120px',
    backgroundColor: COLORS.white,
    borderTop: `4px solid ${COLORS.primaryBlue}`,
    marginTop: '20px',
    position: 'relative',
    width: '100%',
    bottom: '0',
  },
  Footer_wrapper: {
    width: '100%',
    marginTop: '-5px',
    position: 'relative',
    display: 'flex',
  },
  Footer_logo: {
    marginTop: '-12px',
    marginRight: '25px',
    fontWeight: 600,
    cursor: 'pointer',
  },
  Footer_problemLink: {
    marginTop: '-12px',
    marginRight: '25px',
    fontWeight: 600,
    cursor: 'pointer',
    [MOBILE_MEDIA_QUERY]: {
      marginRight: '10px',
    },
  },
  Footer_link: {
    position: 'relative',
    display: 'block',
    marginRight: '25px',
    fontWeight: 600,
    cursor: 'pointer',
  },
  Footer_menuItem: {
    color: COLORS.black,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginLeft: '15px',
    fontSize: '14px',
    letterSpacing: '2px',
    cursor: 'pointer',
  },
  Footer_icon: {
    color: COLORS.primaryBlue,
  },
  Footer_menuIcon: {
    marginTop: '-2px',
    cursor: 'pointer',
  },
  Footer_linkColumn: {
    marginLeft: '20px',
  },
});

class Footer extends PureComponent {
  render() {
    return (
      <footer className={classnames(css(styles.Footer_container), "page-footer")}>
        <div className="valign-wrapper container">
          <div className={css(styles.Footer_wrapper)}>
            <a href="https://www.roaringriot.com" target="_blank" rel="noopener noreferrer" className={classnames(css(styles.Footer_logo), "brand-logo")}>
              <img width="120px" src={roaringRiotLogo} alt="Roaring Riot Logo" />
            </a>
            <div className={classnames(css(styles.Footer_linkColumn), "hide-on-med-and-down")}>
              <a href="https://theriotreport.com" target="_blank" rel="noopener noreferrer" className={classnames(css(styles.Footer_link), "brand-logo hide-on-med-and-down")}>
                Report
              </a>
              <a href="https://theriotreport.com/the-riot-network/" target="_blank" rel="noopener noreferrer" className={classnames(css(styles.Footer_link), "brand-logo hide-on-med-and-down")}>
                Network
              </a>
            </div>
            <div className={classnames(css(styles.Footer_linkColumn), "hide-on-med-and-down")}>
              <a href="https://www.roaringriot.com/pages/the-riot-exchange#!/general" target="_blank" rel="noopener noreferrer" className={classnames(css(styles.Footer_link), "brand-logo hide-on-med-and-down")}>
                Exchange
              </a>
              <a href="https://www.roaringriotfoundation.org/" target="_blank" rel="noopener noreferrer" className={classnames(css(styles.Footer_link), "brand-logo hide-on-med-and-down")}>
                Foundation
              </a>
            </div>
          </div>
          <ul className="right" style={{ display: 'flex', marginTop: '0' }}>
            <li className={css(styles.Footer_menuIcon)}>
              <a href="https://forms.gle/V2BuQkovff1kEnst7" target="_blank" rel="noopener noreferrer" className={classnames(css(styles.Footer_problemLink), "brand-logo")}>
                Problem?
              </a>
              <br />
              <a href="/terms">Terms</a>
              <br />
              <a href="/privacy">Privacy Policy</a>
            </li>
            <li className={css(styles.Footer_menuIcon)}>
              <a href="https://www.facebook.com/RoaringRiot" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} color={COLORS.primaryBlue} size='lg' style={{ marginLeft: '40px' }} />
              </a>
            </li>
            <li className={css(styles.Footer_menuIcon)}>
              <a href="https://twitter.com/theriotrecord" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} color={COLORS.primaryBlue} size='lg' style={{ marginLeft: '20px' }} />
              </a>
            </li>
            <li className={css(styles.Footer_menuIcon)}>
              <a href="https://www.instagram.com/roaringriot" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} color={COLORS.primaryBlue} size='lg' style={{ marginLeft: '20px' }} />
              </a>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}

export default Footer;
