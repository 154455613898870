import {
  STADIUM_NAME_MAPPING,
  TEAM_STADIUM_MAPPING,
} from './constants';
import defaultStadiumImage from '../img/stadium-outline.png';

export const getStadiumImg = (stadiumName, opponentName) => {
  if (STADIUM_NAME_MAPPING[stadiumName]) {
    // If stadium name to image is found, use that
    return STADIUM_NAME_MAPPING[stadiumName];
  } else if (TEAM_STADIUM_MAPPING[opponentName] && TEAM_STADIUM_MAPPING[opponentName][0]) {
    // else if we can find the list of stadiums to teams, we use the first stadium on the list
    return STADIUM_NAME_MAPPING[TEAM_STADIUM_MAPPING[opponentName][0]] || defaultStadiumImage;
  } else {
    return STADIUM_NAME_MAPPING['Bank of America Stadium'];
  }
};
