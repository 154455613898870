import {
  LOAD_ACTIVITIES,
  SET_ACTIVITIES,
} from "../actions/types";

const initialState = {
  loadingActivities: false,
  fetchedActivities: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_ACTIVITIES:
      return {
        ...state,
        loadingActivities: !!action.payload,
      };
    case SET_ACTIVITIES:
      return {
        ...state,
        fetchedActivities: action.payload,
      };
    default:
      return state;
  }
}
