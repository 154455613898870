import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { css, StyleSheet } from "aphrodite";
import classnames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';

import { fetchUser, addFriend, removeFriend, fetchFriends } from "../../actions/userActions";
import COLORS from "../../utils/colors";
import { flattenBadges, badgeIcons } from "../../utils/badgeHelpers";
import WINDOW_DIMENSIONS from "../../utils/windowDimensions";

import blueClaw from "../../img/blue-claw.png";

import PaginatedUserList from "../shared/PaginatedUserList";
import AuthenticationLoader from "../private-route/AuthenticationLoader";

const { TABLET_LANDSCAPE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  PublicProfile_container: {
    position: 'relative',
    marginBottom: '0',
    display: 'flex',
    minHeight: '1000px',
    backgroundColor: COLORS.white,
  },
  PublicProfile_innerContainer: {
    backgroundImage: `url("${blueClaw}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '75%',
    position: 'relative',
  },
  PublicProfile_infoGrid: {
    backgroundColor: COLORS.primaryBlue,
    marginTop: '300px',
    color: COLORS.white,
  },
  PublicProfile_gridHeader: {
    fontSize: '24px',
    fontWeight: 600,
    textTransform: 'uppercase',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  PublicProfile_gridText: {
    fontSize: '18px',
  },
  PublicProfile_horizontalRule: {
    color: COLORS.white,
    width: '50%',
    borderStyle: 'solid',
  },
  PublicProfile_cell: {
    padding: '20px',
  },
  PublicProfile_innerCell: {
    padding: '20px',
    minHeight: '300px',
  },
  PublicProfile_addIcon: {
    color: COLORS.primaryBlue,
    backgroundColor: COLORS.white,
    borderRadius: '50px',
    left: '55%',
    marginTop: '260px',
    position: 'absolute',
    cursor: 'pointer',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      marginTop: '255px',
      left: '50%',
      transform: 'translate(-50%)',
    },
  },
  PublicProfile_uploadIcon: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    border: `2px solid ${COLORS.white}`,
    marginTop: '10px',
    width: '300px',
    height: '300px',
    textAlign: 'center',
    backgroundColor: COLORS.primaryBlue,
    color: COLORS.white,
    borderRadius: '300px',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      marginTop: '5px',
    },
  },
  PublicProfile_emptyBadges: {
    color: COLORS.white,
    marginTop: '20px',
    marginBottom: '20px',
  },
  PublicProfile_listItem: {
    listStyleType: 'disc',
    width: 'fit-content',
    margin: '10px auto',
    textAlign: 'left',
  },
  PublicProfile_friendLink: {
    position: 'absolute',
    right: '0',
    top: '275px',
    cursor: 'pointer',
  },
  PublicProfile_badge: {
    marginTop: '20px',
    marginBottom: '20px',
    display: 'inline-block',
    width: '25%',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      width: '50%',
    },
  },
  PublicProfile_smallText: {
    fontSize: '10px',
    letterSpacing: '2px',
    color: COLORS.white,
    textAlign: 'center',
    width: '100%',
  },
  PublicProfile_badgeDetails: {
    fontWeight: 500,
  },
});

class PublicProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currenUserFriendIds: [],
      friendIds: [],
      profileUserId: undefined,
    };
  }

  async componentDidMount() {
    const {
      fetchUser,
      fetchFriends,
      match,
      auth: {
        isAuthenticated,
        user,
      },
    } = this.props;

    if (!match?.params?.id) {
      this.props.history.push("/dashboard");
    }

    await fetchUser(match.params.id);
    const friends = await fetchFriends(match.params.id) || [];
    ReactTooltip.rebuild();

    let currentUserFriends = [];
    if (isAuthenticated) {
      currentUserFriends = await fetchFriends(user._id) || [];
    }

    this.setState({
      profileUserId: match.params.id,
      currenUserFriendIds: currentUserFriends.map(friend => friend._id),
      friendIds: friends.map(friend => friend._id),
    });
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const {
      auth: {
        isAuthenticated,
        user,
      },
      fetchFriends,
      match,
    } = this.props;
    const { profileUserId } = this.state;

    if (match?.params?.id !== prevProps.match?.params?.id && match?.params?.id) {
      this.setState({ profileUserId: match.params.id });
      ReactTooltip.rebuild();

      window.location.reload();
    }

    if (prevState.profileUserId !== profileUserId) {
      await fetchUser(profileUserId);
      const friends = await fetchFriends(profileUserId) || [];

      let currentUserFriends = [];
      if (isAuthenticated) {
        currentUserFriends = await fetchFriends(user._id) || [];
      }

      this.setState({
        currenUserFriendIds: currentUserFriends.map(friend => friend._id),
        friendIds: friends.map(friend => friend._id),
      });
    }

    if (!prevProps.auth.isAuthenticated && isAuthenticated) {
      const currentUserFriends = await fetchFriends(user._id);
      this.setState({ currenUserFriendIds: currentUserFriends.map(friend => friend._id) });
    }
  }

  addFriend = async () => {
    const {
      addFriend,
      match,
    } = this.props;

    const currentUserFriends = await addFriend(match.params.id);

    toast.success('Friend successfully added!');

    this.setState({ currenUserFriendIds: currentUserFriends.map(friend => friend._id) });
  }

  removeFriend = async () => {
    const {
      removeFriend,
      match,
    } = this.props;

    const currentUserFriends = await removeFriend(match.params.id);

    toast.success('Friend successfully removed!');

    this.setState({ friends: currentUserFriends.map(friend => friend.userId) });
  }

  render() {
    const {
      auth: {
        isAuthenticated,
        user,
      },
      fetchedUser,
      window: {
        isMobile,
      },
      match,
      loadingFriends,
      loadingUser,
    } = this.props;
    const { currenUserFriendIds, friendIds } = this.state;

    const userBadges = flattenBadges(fetchedUser.badges);
    const isFriend = currenUserFriendIds.includes(match.params.id);

    if (loadingUser) {
      return <AuthenticationLoader />
    }

    return (
      <div className={css(styles.PublicProfile_container)}>
        <div className={classnames(css(styles.PublicProfile_innerContainer), "container")}>
          <div className='buttons fadein'>
            <div className='button center-align'>
              <div
                className={classnames(css(styles.PublicProfile_uploadIcon), 'valign-wrapper center-align')}
                style={{
                  backgroundImage: fetchedUser.image ? `url(${fetchedUser.image.secure_url})` : undefined,
                  backgroundRepeat: 'no-repeat',
                }}
              >
                {!fetchedUser.image && <FontAwesomeIcon icon={faUser} color='#FFFFFF' size='10x' style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }} />}
              </div>
            </div>
          </div>

          {(isAuthenticated && user._id !== match.params.id) &&
            <div className={css(styles.PublicProfile_friendLink)} onClick={isFriend ? this.removeFriend : this.addFriend}>
              {loadingFriends ? (
                "...Loading..."
              ) : (
                <>
                  <FontAwesomeIcon icon={isFriend ? faMinusCircle : faPlusCircle} color={COLORS.lightBlack} size='1x' style={{ marginRight: '10px' }} />
                  {isFriend ? 'Remove Friend' : 'Add Friend'}
                </>
              )}
            </div>}
          <div className={css(styles.PublicProfile_infoGrid)}>
            <div className="row" style={{ marginBottom: '0', borderBottom: `2px solid ${COLORS.white}` }}>
              <div className={classnames(css(styles.PublicProfile_cell), "center-align col s12 m6")}>
                <div className={css(styles.PublicProfile_gridHeader)}>{fetchedUser.name}</div>
                {fetchedUser.roaringRiotMember &&
                  <div className={css(styles.PublicProfile_smallText)}>
                    Roaring Riot Member
                  </div>}
              </div>
              <div className={classnames(css(styles.PublicProfile_cell), "center-align col s12 m6")}>
                <div className={css(styles.PublicProfile_gridHeader)}>{fetchedUser.location || "Unknown Location"}</div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: '0', display: isMobile ? "block" : "flex" }}>
              <div className={classnames(css(styles.PublicProfile_innerCell), "center-align col s12 m6")} style={{ borderRight: isMobile ? "none" : `2px solid ${COLORS.white}` }}>
                <div className={css(styles.PublicProfile_gridHeader)}>Profile</div>
                <hr className={css(styles.PublicProfile_horizontalRule)} />
                <div className={css(styles.PublicProfile_gridText)}>
                  {fetchedUser.about || "No bio yet"}
                </div>
              </div>
              <div className={classnames(css(styles.PublicProfile_innerCell), "center-align col s12 m6")}>
                <div className={css(styles.PublicProfile_gridHeader)}>Statistics</div>
                <hr className={css(styles.PublicProfile_horizontalRule)} />
                <div className="col s12 m6">
                  <ul>
                    <li className={css(styles.PublicProfile_listItem)}>
                      {fetchedUser.winCount} Wins / {((fetchedUser.homeGameCount + fetchedUser.awayGameCount) - fetchedUser.winCount)} Losses ({parseFloat((fetchedUser.winCount * 100 / (fetchedUser.homeGameCount + fetchedUser.awayGameCount)) || 0).toFixed(2)}%)</li>
                    <li className={css(styles.PublicProfile_listItem)}>{fetchedUser.pointsScoredCount} Passing Yards</li>
                    <li className={css(styles.PublicProfile_listItem)}>{fetchedUser.rushingYardCount} Rushing Yards</li>
                  </ul>
                </div>
                <div className="col s12 m6">
                  <ul>
                    <li className={css(styles.PublicProfile_listItem)}>{fetchedUser.sackCount} Sacks Seen</li>
                    <li className={css(styles.PublicProfile_listItem)}>{fetchedUser.interceptionCount} Interceptions Seen</li>
                    <li className={css(styles.PublicProfile_listItem)}>{fetchedUser.touchdownCount} Sunday Giveaways <div>(Touchdowns Seen)</div></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row" style={{ borderTop: `2px solid ${COLORS.white}` }}>
              <div className={classnames(css(styles.PublicProfile_cell), "center-align col s12")}>
                <div className={css(styles.PublicProfile_gridHeader)}>Badges Earned</div>
                {userBadges.length > 0 ? (
                  <div>
                    {userBadges.map((badge) => {
                      return (
                        <div key={`badge-${badge._id}`} className={classnames(css(styles.PublicProfile_badge))}>
                          <img data-tip={badge.description} width="150px" src={badgeIcons(badge.badgeType)} alt="Badge" />
                          <div className={css(styles.PublicProfile_badgeDetails)}>{badge.details}</div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className={css(styles.PublicProfile_emptyBadges)}>No badges received yet</div>
                )}
              </div>
            </div>
            <PaginatedUserList
              userIds={friendIds}
              numUsersToShow={16}
              emptyMessage={"This user has not added any friends yet!"}
              headerMessage={"Friends"}
            />
          </div>
        </div>
      </div>
    );
  }
}

PublicProfile.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  window: PropTypes.object.isRequired,
  fetchedUser: PropTypes.object.isRequired,
  loadingFriends: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  window: state.window,
  auth: state.auth,
  fetchedUser: state.user.fetchedUser,
  loadingFriends: state.user.loadingFriends,
  loadingUser: state.user.loadingUser,
});

export default connect(
  mapStateToProps,
  {
    fetchUser,
    addFriend,
    removeFriend,
    fetchFriends,
  }
)(PublicProfile);
