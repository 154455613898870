import moment from 'moment';

const LOCAL_STORAGE_PROPERTIES = {
  termsOfService: 'termsOfService',
  introBanner: 'introBanner',
  gameBanner: 'gameBanner',
  openSesame: 'openSesame',
};

const TEST = 'test';

export function isLocalStorageEnabled() {
  try {
    localStorage.setItem(TEST, TEST);
    localStorage.removeItem(TEST);
    return true;
  } catch (err) {
    return false;
  }
}

function getLocalStorageItem(property: string) {
  if (!isLocalStorageEnabled()) return [];
  
  try {
    const localStorageItems = localStorage.getItem(property);
    return JSON.parse(localStorageItems) || [];
  } catch (err) {
    localStorage.setItem(property, JSON.stringify([]));
    return [];
  }
}

function addLocalStorageItem(property: string, newItem: any) {
  if (!isLocalStorageEnabled()) return;

  const localStorageItems = getLocalStorageItem(property) || [];
  localStorageItems.unshift(newItem);
  localStorage.setItem(property, JSON.stringify(localStorageItems));
}

export function getOpenSesame() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROPERTIES.openSesame) || '{}').passphrase;
}

export function openSesame(passphrase) {
  if (!isLocalStorageEnabled()) return;
  localStorage.setItem(LOCAL_STORAGE_PROPERTIES.openSesame, JSON.stringify({
    passphrase,
  }));
}

export function getTOSStatus() {
  return getLocalStorageItem(LOCAL_STORAGE_PROPERTIES.termsOfService);
}

export function acceptTOS() {
  addLocalStorageItem(LOCAL_STORAGE_PROPERTIES.termsOfService, {
    acceptedAt: moment()
  });
}

export function getGameBannerDismissal() {
  return getLocalStorageItem(LOCAL_STORAGE_PROPERTIES.gameBanner).length > 0;
}

export function dismissGameBanner() {
  addLocalStorageItem(LOCAL_STORAGE_PROPERTIES.gameBanner, {
    dismissedAt: moment()
  });
}

export function getIntroBannerDismissal() {
  return getLocalStorageItem(LOCAL_STORAGE_PROPERTIES.introBanner).length > 0;
}

export function dismissIntroBanner() {
  addLocalStorageItem(LOCAL_STORAGE_PROPERTIES.introBanner, {
    dismissedAt: moment()
  });
}
