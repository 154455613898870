import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { css, StyleSheet } from "aphrodite";
import { Button } from "react-materialize";
import ReactModal from "react-modal";

import GameMap from "../gameMap/GameMap";
import riotRecordLogo from "../../img/logos/riot-record-logo.png";
import COLORS from "../../utils/colors";
import WINDOW_DIMENSIONS from "../../utils/windowDimensions";
import { getIntroBannerDismissal, dismissIntroBanner } from "../../utils/localStorageHelpers";
import AuthenticationLoader from "../../components/private-route/AuthenticationLoader";

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  Landing_heroContainer: {
    position: 'relative',
  },
  Landing_heroBanner: {
    color: COLORS.white,
    textAlign: 'center',
  },
  Landing_registerButtonContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  Landing_registerButton: {
    width: "200px",
    borderRadius: "3px",
    letterSpacing: "1.5px",
  },
  Landing_heroText: {
    textAlign: 'center',
    paddingTop: '40px',
    paddingBottom: '40px',
    color: COLORS.white,
    width: '60%',
    margin: 'auto',
    fontSize: '20px',
    [MOBILE_MEDIA_QUERY]: {
      fontSize: '14px',
      width: '95%',
    },
  },
});

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      introBannerDismissed: getIntroBannerDismissal(),
    };
  }
  render() {
    const { 
      auth: {
        isAuthenticated,
        isCheckingAuthentication,
      },
    } = this.props;
    const { introBannerDismissed } = this.state;
    // If logged in and user navigates to landing page, should redirect them to dashboard
    if (isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    if (isCheckingAuthentication) {
      return <AuthenticationLoader />;
    }

    return (
      <div className="container">
        <div>
          <ReactModal
            style={{
              overlay: {
                margin: 'auto',
                width: '100%',
                border: `1px solid gray`,
                backgroundColor: 'rgb(0, 0, 0, 0.7)',
                overflow: 'hidden',
                zIndex: '10000000',
              },
              content: {
                position: 'absolute',
                margin: 'auto',
                top: '40px',
                left: '40px',
                right: '40px',
                bottom: '40px',
                background: '#fff',
                overflowY: 'scroll',
                WebkitOverflowScrolling: 'touch',
                outline: 'none',
                padding: '20px',
                maxWidth: '80%',
                height: 'fit-content',
              }
            }}
            isOpen={!introBannerDismissed}
          >
            <div>
              <div className="center">
                <img width="150" src={riotRecordLogo} alt="Riot Record Logo" />
                <br />
                <br />
                <h4>What Is The Riot Record?</h4>
                <br />
                <div>
                  Check in everytime you watch your favorite team play to earn badges, rewards and more!
                </div>
                <Link to="/about">
                  <Button
                      style={{
                        backgroundColor: COLORS.primaryBlue,
                        width: "200px",
                        borderRadius: "3px",
                        marginTop: "50px",
                        letterSpacing: "1.5px",
                        fontSize: "12px",
                      }}
                      className="btn btn-large hoverable accent-3"
                    >
                    Learn More
                  </Button>
                </Link>
              </div>
              <div className="col s12 right" style={{ marginTop: "50px" }}>
                <Button flat waves="teal" modal="close" onClick={() => {
                  dismissIntroBanner();
                  this.setState({ introBannerDismissed: true });
                }}>
                  Stop Showing
                </Button>
              </div>
            </div>
          </ReactModal>
        </div>
        <h2 className={css(styles.Landing_heroBanner)}>Start Recording Today!</h2>
        <div className={css(styles.Landing_heroContainer)}>
          <GameMap />
          <div className={css(styles.Landing_heroText)}>
            Think you’re the biggest Panthers fan in the world?
            Check in everytime you watch your favorite team play to earn badges, rewards and more!
            Move up The Riot Record leaderboards as you track your progress and show off your Panthers Pride!
            <h3>The Riot Record -- You Riot. Now Record It.</h3>
          </div>
        </div>
      </div>
    );
  }
}


Landing.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  null
)(Landing);
