import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { css, StyleSheet } from "aphrodite";
import classnames from "classnames";
import { Button, TextInput } from "react-materialize";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons';

import { loginUser } from "../../actions/authActions";
import COLORS from "../../utils/colors";

const styles = StyleSheet.create({
  Login_outerContainer: {
    minHeight: '700px',
  },
  Login_container: {
    backgroundColor: COLORS.white,
    paddingBottom: '50px',
    paddingTop: '5px',
    marginTop: '40px',
  },
  Login_socialButton: {
    border: `1px solid ${COLORS.primaryBlue}`,
    borderRadius: '5px',
    height: '54px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Login_socialContainer: {
    margin: 'auto',
  },
})

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {}
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth !== this.props.auth) {
      if (this.props.auth.isAuthenticated) {
        this.props.history.push("/dashboard"); // push user to dashboard when they login
      }
    }
    if (prevProps.errors !== this.props.errors) {
      this.setState({
        errors: this.props.errors
      });
    }
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(userData);
  };

  render() {
    const { windowDimensions: { isMobile } } = this.props;
    const { errors } = this.state;

    return (
      <div className={css(styles.Login_outerContainer)}>
        <div className={classnames(css(styles.Login_container), "container")}>
          <div style={{ marginTop: "4rem" }} className="row">
            <div className="col s8 offset-s2">
              <Link to="/" className="btn-flat waves-effect">
                <i className="material-icons left">keyboard_backspace</i> Back to
                home
              </Link>
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <h4>
                  Let’s <b>do this</b>
                </h4>
                <p className="grey-text text-darken-1">
                  Don't have an account? <Link to="/register">Register</Link>
                </p>
              </div>
              <form noValidate onSubmit={this.onSubmit}>
                <div className="input-field col s12">
                  <TextInput
                    onChange={this.onChange}
                    value={this.state.email}
                    error={errors.email}
                    id="email"
                    email
                    validate
                    s={12}
                    label="Email"
                    className={classnames("", {
                      invalid: errors.email || errors.emailnotfound
                    })}
                  />
                </div>
                <div className="input-field col s12">
                  <TextInput
                    onChange={this.onChange}
                    value={this.state.password}
                    error={errors.password}
                    id="password"
                    s={12}
                    password
                    label="Password"
                    className={classnames("", {
                      invalid: errors.password || errors.passwordincorrect
                    })}
                  />
                </div>
                <div className="col s12" style={{ alignItems: 'center' }}>
                  <Button
                    style={{
                      width: "100%",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      marginTop: "1rem"
                    }}
                    type="submit"
                    className="btn btn-large hoverable blue accent-3"
                  >
                    Login
                  </Button>
                </div>
                <div style={{ margin: '10px 20px', textAlign: 'center' }}>or</div>
                <div className="col s12" style={{ alignItems: 'center' }}>
                  <div className={css(styles.Login_socialContainer)}>
                    <div onClick={() => window.location.href = "/api/auth/twitter" } className={classnames(css(styles.Login_socialButton), "btn-flat waves-effect")}>
                      <FontAwesomeIcon icon={faTwitter} color={COLORS.primaryBlue} size='lg' style={{ marginRight: isMobile ? '5px' : '10px' }} />
                      {isMobile ? "Login" : "Login on Twitter"}
                    </div>
                  </div>
                </div>
                <div style={{ margin: '10px 20px', textAlign: 'center' }}>or</div>
                <div className="col s12" style={{ alignItems: 'center' }}>
                  <div className={css(styles.Login_socialContainer)}>
                    <div onClick={() => window.location.href = "/api/auth/facebook" } className={classnames(css(styles.Login_socialButton), "btn-flat waves-effect")}>
                      <FontAwesomeIcon icon={faFacebookF} color={COLORS.primaryBlue} size='lg' style={{ marginRight: isMobile ? '5px' : '10px' }} />
                      {isMobile ? "Login" : "Login on Facebook"}
                    </div>
                  </div>
                </div>
                <div className="col s12 grey-text text-darken-1" style={{ marginTop: "20px" }}>
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  windowDimensions: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  windowDimensions: state.window,
});

export default connect(
  mapStateToProps,
  { loginUser }
)(Login);
