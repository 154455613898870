import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { css, StyleSheet } from "aphrodite";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faEdit, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Collection, CollectionItem } from 'react-materialize';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { fetchUser, fetchFriends } from "../../actions/userActions";
import COLORS from "../../utils/colors";
import { flattenBadges, badgeIcons } from "../../utils/badgeHelpers";
import WINDOW_DIMENSIONS from "../../utils/windowDimensions";
import { sortBoxScores } from "../../utils/boxScoreHelpers";
import { getPublicProfile } from "../../utils/linkHelpers";

import blueClaw from "../../img/blue-claw.png";

import ProfileEditModal from "./ProfileEditModal";
import PaginatedUserList from "../shared/PaginatedUserList";

const { TABLET_LANDSCAPE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const styles = StyleSheet.create({
  Profile_container: {
    position: 'relative',
    marginBottom: '0',
    display: 'flex',
    minHeight: '1000px',
    backgroundColor: COLORS.white,
  },
  Profile_innerContainer: {
    backgroundImage: `url("${blueClaw}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '75%',
    position: 'relative',
  },
  Profile_infoGrid: {
    backgroundColor: COLORS.primaryBlue,
    marginTop: '300px',
    color: COLORS.white,
  },
  Profile_gridHeader: {
    fontSize: '24px',
    fontWeight: 600,
    textTransform: 'uppercase',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  Profile_gridText: {
    fontSize: '18px',
  },
  Profile_horizontalRule: {
    color: COLORS.white,
    width: '50%',
    borderStyle: 'solid',
  },
  Profile_cell: {
    padding: '20px',
  },
  Profile_innerCell: {
    padding: '20px',
    minHeight: '300px',
  },
  Profile_addIcon: {
    color: COLORS.primaryBlue,
    backgroundColor: COLORS.white,
    borderRadius: '50px',
    left: '55%',
    marginTop: '260px',
    position: 'absolute',
    cursor: 'pointer',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      marginTop: '255px',
      left: '50%',
      transform: 'translate(-50%)',
    },
  },
  Profile_uploadIcon: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    border: `2px solid ${COLORS.white}`,
    marginTop: '10px',
    width: '300px',
    height: '300px',
    textAlign: 'center',
    backgroundColor: COLORS.primaryBlue,
    color: COLORS.white,
    borderRadius: '300px',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      marginTop: '5px',
    },
  },
  Profile_emptyBadges: {
    color: COLORS.white,
    marginTop: '20px',
    marginBottom: '20px',
  },
  Profile_listItem: {
    listStyleType: 'disc',
    width: 'fit-content',
    margin: '10px auto',
    textAlign: 'left',
  },
  Profile_editLink: {
    position: 'absolute',
    right: '0',
    top: '275px',
    cursor: 'pointer',
  },
  Profile_badge: {
    marginTop: '20px',
    marginBottom: '20px',
    display: 'inline-block',
    width: '25%',
    [TABLET_LANDSCAPE_MEDIA_QUERY]: {
      width: '50%',
    },
  },
  Profile_myGamesSection: {
    textAlign: 'center',
    color: COLORS.black,
    marginTop: '40px',
  },
  Profile_gameGrid: {
    fontSize: '14px',
  },
  Profile_gameCell: {
    height: '42px',
  },
  Profile_gameCellEnd: {
    justifyContent: 'flex-end',
    height: '42px',
  },
  Profile_gameRow: {
    color: COLORS.black,
    ':hover': {
      backgroundColor: COLORS.lightGray,
    },
    cursor: 'pointer',
  },
  Profile_smallText: {
    fontSize: '10px',
    letterSpacing: '2px',
    color: COLORS.white,
    textAlign: 'center',
    width: '100%',
  },
  Profile_badgeDetails: {
    fontWeight: 500,
  },
  Profile_publicProfileLink: {
    position: 'absolute',
    display: 'flex',
    left: '0',
    top: '275px',
    cursor: 'pointer',
  },
  Profile_link: {
    color: COLORS.black,
    cursor: 'pointer',
  }
});

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      images: [],
      profileEditModalOpen: false,
      friendIds: [],
    };
  }

  async componentDidMount() {
    const {
      fetchUser,
      fetchFriends,
      auth: {
        isAuthenticated,
        user,
      },
    } = this.props;

    await fetchUser(user._id);
    ReactTooltip.rebuild();

    let friends = [];
    if (isAuthenticated) {
      friends = await fetchFriends(user._id);
    }

    this.setState({
      friendIds: friends.map(friend => friend._id),
    });
  }

  componentDidUpdate = async (prevProps) => {
    const {
      auth: {
        isAuthenticated,
        user,
      },
      fetchFriends,
    } = this.props;

    if (!prevProps.auth.isAuthenticated && isAuthenticated) {
      const friends = await fetchFriends(user._id);
      this.setState({ friends: friends.map(friend => friend.userId) });
    }
  }


  toggleProfileEditModalOpen = (openState) => {
    this.setState({
      profileEditModalOpen: openState,
    });
  };

  onPicChange = async (e) => {
    const {
      auth: {
        user,
      },
      fetchUser,
    } = this.props;

    const files = Array.from(e.target.files);
    this.setState({ uploading: true });

    const formData = new FormData();
    const errs = [];

    // #1 There are too many files!
    if (files.length > 1) {
      const msg = 'Only 1 image can be uploaded at a time';
      return toast.error(msg);
    }

    const types = ['image/png', 'image/jpeg', 'image/gif'];

    files.forEach((file, i) => {
      // #2 Catching wrong file types on the client
      if (types.every(type => file.type !== type)) {
        errs.push(`'${file.type}' is not a supported format`);
      }

      // #3 Catching files that are too large on the client ~5MB
      if (file.size > 5000000) {
        errs.push(`'${file.name}' is too large, please pick a smaller file`);
      }

      formData.append(i, file);
    });

    formData.append('userId', user._id);

    if (errs.length) {
      return errs.forEach(err => toast.error(err));
    }

    await fetch(`/api/users/image-upload`, {
      method: 'POST',
      body: formData,
    })
    .then(res => {
      if (!res.ok) {
        throw res;
      }
      return res.json();
    })
    .then(images => {
      this.setState({
        uploading: false,
        images,
      });
      toast.success('Successfully updated profile picture');
    })
    .catch(err => {
      toast.error(err.message);
      this.setState({ uploading: false });
    });

    // Reload user
    await fetchUser(user._id);
  };

  handleSwitchPic = () => {
    this.refs.fileUploader.click();
  };

  render() {
    const {
      fetchedUser,
      window: {
        isTablet,
      },
    } = this.props;
    const {
      uploading,
      profileEditModalOpen,
      friendIds,
    } = this.state;

    const myGames = sortBoxScores(fetchedUser.games || []);
    const userBadges = flattenBadges(fetchedUser.badges);

    return (
      <div className={css(styles.Profile_container)}>
        <div className={classnames(css(styles.Profile_innerContainer), "container")}>
          <div className={css(styles.Profile_publicProfileLink)}> 
            <a className={css(styles.Profile_link)} href={getPublicProfile(fetchedUser)} target="_blank" rel="noopener noreferrer">View Profile</a>
            <CopyToClipboard text={getPublicProfile(fetchedUser)} onCopy={() => toast.success('Copied URL to clipboard')}>
              <div>  
                <FontAwesomeIcon icon={faClipboardList} color={COLORS.lightBlack} size='1x' style={{ marginLeft: '10px' }} />
              </div>
            </CopyToClipboard>
          </div>
          <div className='buttons fadein'>
            <div className='button center-align'>
              <div
                className={classnames(css(styles.Profile_uploadIcon), 'valign-wrapper center-align')}
                style={{
                  backgroundImage: fetchedUser.image ? `url(${fetchedUser.image.secure_url})` : undefined,
                  backgroundRepeat: 'no-repeat',
                }}
              >
              {uploading &&
                <div style={{ margin: 'auto' }}>
                  <div className="preloader-wrapper active center-align">
                    <div className="spinner-layer spinner-blue-only">
                      <div className="circle-clipper left">
                        <div className="circle"></div>
                      </div><div className="gap-patch">
                        <div className="circle"></div>
                      </div><div className="circle-clipper right">
                        <div className="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>}
                {!fetchedUser.image && <FontAwesomeIcon icon={faImage} color='#FFFFFF' size='10x' style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }} />}
              </div>
              <i className={classnames(css(styles.Profile_addIcon), "medium material-icons")} onClick={this.handleSwitchPic}>add</i>
              <input type='file' id='single' onChange={this.onPicChange} ref="fileUploader" style={{display: "none"}} />
            </div>
          </div>

          <div className={css(styles.Profile_editLink)} onClick={() => this.toggleProfileEditModalOpen(true)}>
            <ProfileEditModal open={profileEditModalOpen} user={fetchedUser} onClose={() => this.toggleProfileEditModalOpen(false)} />
            <FontAwesomeIcon icon={faEdit} color={COLORS.lightBlack} size='1x' style={{ marginRight: '10px' }} />
            Edit Profile
          </div>
          <div className={css(styles.Profile_infoGrid)}>
            <div className="row" style={{ marginBottom: '0', borderBottom: `2px solid ${COLORS.white}` }}>
              <div className={classnames(css(styles.Profile_cell), "center-align col s12 m6")}>
                <div className={css(styles.Profile_gridHeader)}>{fetchedUser.name}</div>
                {fetchedUser.roaringRiotMember &&
                  <div className={css(styles.Profile_smallText)}>
                    Roaring Riot Member
                  </div>}
              </div>
              <div className={classnames(css(styles.Profile_cell), "center-align col s12 m6")}>
                <div className={css(styles.Profile_gridHeader)}>{fetchedUser.location || "Unknown Location"}</div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: '0', display: isTablet ? "block" : "flex" }}>
              <div className={classnames(css(styles.Profile_innerCell), "center-align col s12 m6")} style={{ borderRight: isTablet ? "none" : `2px solid ${COLORS.white}` }}>
                <div className={css(styles.Profile_gridHeader)}>Profile</div>
                <hr className={css(styles.Profile_horizontalRule)} />
                <div className={css(styles.Profile_gridText)}>
                  {fetchedUser.about || "No bio yet"}
                </div>
              </div>
              <div className={classnames(css(styles.Profile_innerCell), "center-align col s12 m6")}>
                <div className={css(styles.Profile_gridHeader)}>Personal Statistics</div>
                <hr className={css(styles.Profile_horizontalRule)} />
                <div className="col s12 m6">
                  <ul>
                    <li className={css(styles.Profile_listItem)}>
                      {fetchedUser.winCount} Wins / {((fetchedUser.homeGameCount + fetchedUser.awayGameCount) - fetchedUser.winCount)} Losses ({parseFloat((fetchedUser.winCount * 100 / (fetchedUser.homeGameCount + fetchedUser.awayGameCount)) || 0).toFixed(2)}%)</li>
                    <li className={css(styles.Profile_listItem)}>{fetchedUser.passingYardCount} Passing Yards</li>
                    <li className={css(styles.Profile_listItem)}>{fetchedUser.rushingYardCount} Rushing Yards</li>
                    <li className={css(styles.Profile_listItem)}>{fetchedUser.tailgateCount} Tailgates Attended</li>
                  </ul>
                </div>
                <div className="col s12 m6">
                  <ul>
                    <li className={css(styles.Profile_listItem)}>{fetchedUser.sackCount} Sacks Seen</li>
                    <li className={css(styles.Profile_listItem)}>{fetchedUser.interceptionCount} Interceptions Seen</li>
                    <li className={css(styles.Profile_listItem)}>{fetchedUser.touchdownCount} Sunday Giveaways <div>(Touchdowns Seen)</div></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row" style={{ borderTop: `2px solid ${COLORS.white}` }}>
              <div className={classnames(css(styles.Profile_cell), "center-align col s12")}>
                <div className={css(styles.Profile_gridHeader)}>Badges Earned</div>
                {userBadges.length > 0 ? (
                  <div>
                    {userBadges.map((badge) => {
                      return (
                        <div key={`badge-${badge._id}`} className={classnames(css(styles.Profile_badge))}>
                          <img data-tip={badge.description} width="150px" src={badgeIcons(badge.badgeType)} alt="Badge" />
                          <div className={css(styles.Profile_badgeDetails)}>{badge.details}</div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className={css(styles.Profile_emptyBadges)}>No badges received yet</div>
                )}
              </div>
            </div>
          </div>
          <PaginatedUserList
            userIds={friendIds}
            numUsersToShow={16}
            emptyMessage={"Find your friends and add them to your Friend List!"}
            headerMessage={"Your fellow Panthers Fans"}
          />
          {myGames.length > 0 &&
            <div className={css(styles.Profile_myGamesSection)}>
              <h4>My Games</h4>
              <br />
              <Collection className={css(styles.Profile_gameGrid)}>
                {myGames.map((game) => {
                  return (
                    <Link key={`game-${game._id}`} to={`/games/${game.season}/${game.week}`}>
                      <CollectionItem className={classnames(css(styles.Profile_gameRow), "row")}>
                        <div className={classnames("left-align col s12 m4")}>
                          <div>{game.season}</div>
                          <div>Week {game.week}</div>
                        </div>
                        <div className={classnames(css(styles.Profile_gameCell), "center-align valign-wrapper col s12 m4")}>
                          <div>{game.teamName} {game.gameLocation === 'vs' ? game.gameLocation : '@'} {game.opponentName}</div>
                        </div>
                        <div className={classnames(css(styles.Profile_gameCellEnd), "right-align valign-wrapper col s12 m4")}>
                          {game.winLoss ?
                            (
                              <div>{game.pointsScored} - {game.pointsAgainst} ({game.winLoss})</div>
                            ) : (
                              <div>{moment(String(game.gameDate).split('T')[0]).format("MMMM D, YYYY")}</div>
                            )
                          }
                        </div>
                      </CollectionItem>
                    </Link>
                  );
                })}
              </Collection>
            </div>
          }
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  fetchFriends: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  window: PropTypes.object.isRequired,
  fetchedUser: PropTypes.object.isRequired,
  friends: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => ({
  window: state.window,
  auth: state.auth,
  fetchedUser: state.user.fetchedUser,
  friends: state.user.friends,
});

export default connect(
  mapStateToProps,
  {
    fetchUser,
    fetchFriends,
  }
)(Profile);
