const colors = {
  primaryBlue: '#1397CF',
  white: '#FFFFFF',
  yellow: '#FFFF00',
  black: '#000000',
  gray: '#5F5F5F',
  lightGray: '#E4E4E4',
};

export default colors;
