import STADIUM_IMG_BANK_OF_AMERICA from '../img/stadiums/panthers-bank-of-america-stadium-50-gray.png';
import STADIUM_IMG_GEORGIA_DOME from '../img/stadiums/falcons-georgia-dome-20-gray.png';
import STADIUM_IMG_LEVIS_STADIUM from '../img/stadiums/49ers-levis-stadium-50-gray.png';
import STADIUM_IMG_SOLDIER_FIELD from '../img/stadiums/bears-soldier-field-50-gray.png';
import STADIUM_IMG_PAUL_BROWN_STADIUM from '../img/stadiums/bengals-paul-brown-stadium-50-gray.png';
import STADIUM_IMG_HIGHMARK_STADIUM from '../img/stadiums/bills-new-era-field-50-gray.png';
import STADIUM_IMG_EMPOWER_FIELD from '../img/stadiums/broncos-sports-authority-stadium-50-gray.png';
import STADIUM_IMG_FIRSTENERGY_STADIUM from '../img/stadiums/browns-firstenergy-stadium-50-gray.png';
import STADIUM_IMG_RAYMOND_JAMES_STADIUM from '../img/stadiums/buccaneers-raymond-james-stadium-50-gray.png';
import STADIUM_IMG_STATE_FARM_STADIUM from '../img/stadiums/cardinals-state-farm-stadium-50-gray.png';
import STADIUM_IMG_DIGNITY_HEALTH_SPORTS_PARK from '../img/stadiums/chargers-dignity-health-sports-park-50-gray.png';
import STADIUM_IMG_ARROWHEAD_STADIUM from '../img/stadiums/chiefs-arrowhead-stadium-50-gray.png';
import STADIUM_IMG_LUCAS_OIL_STADIUM from '../img/stadiums/colts-lucas-oil-stadium-50-gray.png';
import STADIUM_IMG_ATT_STADIUM from '../img/stadiums/cowboys-att-stadium-50-gray.png';
import STADIUM_IMG_HARD_ROCK_STADIUM from '../img/stadiums/dolphins-hard-rock-stadium-50-gray.png';
import STADIUM_IMG_LINCOLN_FINANCIAL_FIELD from '../img/stadiums/eagles-lincoln-financial-field-50-gray.png';
import STADIUM_IMG_MERCEDES_BENZ_STADIUM from '../img/stadiums/falcons-mercedes-benz-stadium-50-gray.png';
import STADIUM_IMG_METLIFE_STADIUM_GIANTS from '../img/stadiums/giants-metlife-stadium-50-gray.png';
import STADIUM_IMG_TIAA_BANK_FIELD from '../img/stadiums/jaguars-tiaa-bank-field-50-gray.png';
import STADIUM_IMG_METLIFE_STADIUM_JETS from '../img/stadiums/jets-metlife-stadium-50-gray.png';
import STADIUM_IMG_LAMBEAU_FIELD from '../img/stadiums/packers-lambeau-field-50-gray.png';
import STADIUM_IMG_MEMORIAL_STADIUM_CLEMSON from '../img/stadiums/panthers-clemson-memorial-stadium-50-gray.png';
import STADIUM_IMG_GILETTE_STADIUM from '../img/stadiums/patriots-gilette-stadium-50-gray.png';
import STADIUM_IMG_OAKLAND_COLISEUM from '../img/stadiums/raiders-oakland-coliseum-50-gray.png';
import STADIUM_IMG_LOS_ANGELES_MEMORIAL_COLISEUM from '../img/stadiums/rams-los-angeles-memorial-coliseum-50-gray.png';
import STADIUM_IMG_MT_BANK_STADIUM from '../img/stadiums/ravens-mt-bank-stadium-50-gray.png';
import STADIUM_IMG_FEDEX_FIELD from '../img/stadiums/redskins-fedex-field-50-gray.png';
import STADIUM_IMG_MERCEDES_BENZ_SUPERDOME from '../img/stadiums/saints-mercedes-benz-superdome-50-gray.png';
import STADIUM_IMG_CENTURYLINK_FIELD from '../img/stadiums/seahawks-centurylink-field-50-gray.png';
import STADIUM_IMG_HEINZ_FIELD from '../img/stadiums/steelers-heinz-field-50-gray.png';
import STADIUM_IMG_NRG_STADIUM from '../img/stadiums/texans-nrg-stadium-50-gray.png';
import STADIUM_IMG_NISSAN_STADIUM from '../img/stadiums/titans-nissan-stadium-50-gray.png';
import STADIUM_IMG_TOTTENHAM_HOTSPUR_STADIUM from '../img/stadiums/tottenham-hotspur-stadium-50-gray.png';
import STADIUM_IMG_US_BANK_STADIUM from '../img/stadiums/vikings-us-bank-stadium-50-gray.png';
import STADIUM_IMG_FORD_FIELD from '../img/stadiums/lions-ford-field-50-gray.png';
import STADIUM_IMG_TWA_DOME from '../img/stadiums/rams-twa-dome-20-gray.png';
import STADIUM_IMG_RFK_STADIUM from '../img/stadiums/redskins-rfk-stadium-20-gray.png';
import STADIUM_IMG_HUBERT_H_HUMPHREY_METRODOME from '../img/stadiums/vikings-mall-of-america-field-at-hubert-h-humphrey-metrodome-20-gray.png';
import STADIUM_IMG_VETERANS_STADIUM from '../img/stadiums/eagles-veterans-stadium-20-gray.png';
import STADIUM_IMG_HOUSTON_ASTRODOME from '../img/stadiums/texans-houston-astrodome-20-gray.png';
import STADIUM_IMG_3COM_PARK from '../img/stadiums/49ers-3com-park-20-gray.png';
import STADIUM_IMG_GIANTS_STADIUM from '../img/stadiums/giants-giants-stadium-20-gray.png';
import STADIUM_IMG_QUALCOMM_STADIUM from '../img/stadiums/chargers-qualcomm-stadium-20-gray.png';
import STADIUM_IMG_RCA_DOME from '../img/stadiums/colts-rca-dome-20-gray.png';
import STADIUM_IMG_SUN_DEVIL_STADIUM from '../img/stadiums/cardinals-sun-devil-stadium-20-gray.png';
import STADIUM_IMG_TCF_BANK_STADIUM from '../img/stadiums/vikings-tcf-bank-stadium-20-gray.png';
import STADIUM_IMG_TEXAS_STADIUM from '../img/stadiums/cowboys-texas-stadium-20-gray.png';
import STADIUM_IMG_THREE_RIVERS_STADIUM from '../img/stadiums/steelers-three-rivers-stadium-20-gray.png';
import STADIUM_IMG_TIGER_STADIUM_LSU from '../img/stadiums/saints-lsu-tiger-stadium-20-gray.png';
import STADIUM_IMG_SOFI_STADIUM from '../img/stadiums/rams-sofi-stadium-20-gray.png';
import STADIUM_IMG_ALLEGIANT_STADIUM from '../img/stadiums/raiders-allegiant-stadium-20-gray.png';

const TEAMS = {
  CAROLINA_PANTHERS: 'Carolina Panthers',
  ARIZONA_CARDINALS: 'Arizona Cardinals',
  ATLANTA_FALCONS: 'Atlanta Falcons',
  BALTIMORE_RAVENS: 'Baltimore Ravens',
  BUFFALO_BILLS: 'Buffalo Bills',
  CHICAGO_BEARS: 'Chicago Bears',
  CINCINNATI_BENGALS: 'Cincinnati Bengals',
  CLEVELAND_BROWNS: 'Cleveland Browns',
  DALLAS_COWBOYS: 'Dallas Cowboys',
  DENVER_BRONCOS: 'Denver Broncos',
  DETROIT_LIONS: 'Detroit Lions',
  GREEN_BAY_PACKERS: 'Green Bay Packers',
  HOUSTON_OILERS: 'Houston Oilers',
  HOUSTON_TEXANS: 'Houston Texans',
  INDIANAPOLIS_COLTS: 'Indianapolis Colts',
  JACKSONVILLE_JAGUARS: 'Jacksonville Jaguars',
  KANSAS_CITY_CHIEFS: 'Kansas City Chiefs',
  LOS_ANGELES_RAMS: 'Los Angeles Rams',
  MIAMI_DOLPHINS: 'Miami Dolphins',
  MINNESOTA_VIKINGS: 'Minnesota Vikings',
  NEW_ENGLAND_PATRIOTS: 'New England Patriots',
  NEW_ORLEANS_SAINTS: 'New Orleans Saints',
  NEW_YORK_GIANTS: 'New York Giants',
  NEW_YORK_JETS: 'New York Jets',
  OAKLAND_RAIDERS: 'Oakland Raiders',
  PHILADELPHIA_EAGLES: 'Philadelphia Eagles',
  PITTSBURGH_STEELERS: 'Pittsburgh Steelers',
  SAN_DIEGO_CHARGERS: 'San Diego Chargers',
  SAN_FRANCISCO_49ERS: 'San Francisco 49ers',
  SEATTLE_SEAHAWKS: 'Seattle Seahawks',
  ST_LOUIS_RAMS: 'St. Louis Rams',
  TAMPA_BAY_BUCCANEEERS: 'Tampa Bay Buccaneers',
  TENNESSEE_TITANS: 'Tennessee Titans',
  WASHINGTON_REDSKINS: 'Washington Redskins',
  LAS_VEGAS_RAIDERS: 'Las Vegas Raiders',
  WASHINGTON_FOOTBALL_TEAM: 'Washington Football Team',
  LOS_ANGELES_CHARGERS: 'Los Angeles Chargers',
};

const TEAM_ABBREVIATION_MAP = {
  [TEAMS.CAROLINA_PANTHERS]: 'CAR',
  [TEAMS.ARIZONA_CARDINALS]: 'ARI',
  [TEAMS.ATLANTA_FALCONS]: 'ATL',
  [TEAMS.BALTIMORE_RAVENS]: 'BAL',
  [TEAMS.BUFFALO_BILLS]: 'BUF',
  [TEAMS.CHICAGO_BEARS]: 'CHI',
  [TEAMS.CINCINNATI_BENGALS]: 'CIN',
  [TEAMS.CLEVELAND_BROWNS]: 'CLE',
  [TEAMS.DALLAS_COWBOYS]: 'DAL',
  [TEAMS.DENVER_BRONCOS]: 'DEN',
  [TEAMS.DETROIT_LIONS]: 'DET',
  [TEAMS.GREEN_BAY_PACKERS]: 'GNB',
  [TEAMS.HOUSTON_OILERS]: 'HOU',
  [TEAMS.HOUSTON_TEXANS]: 'HOU',
  [TEAMS.INDIANAPOLIS_COLTS]: 'IND',
  [TEAMS.JACKSONVILLE_JAGUARS]: 'JAX',
  [TEAMS.KANSAS_CITY_CHIEFS]: 'KAN',
  [TEAMS.LOS_ANGELES_RAMS]: 'LAR',
  [TEAMS.MIAMI_DOLPHINS]: 'MIA',
  [TEAMS.MINNESOTA_VIKINGS]: 'MIN',
  [TEAMS.NEW_ENGLAND_PATRIOTS]: 'NWE',
  [TEAMS.NEW_ORLEANS_SAINTS]: 'NOR',
  [TEAMS.NEW_YORK_GIANTS]: 'NYG',
  [TEAMS.NEW_YORK_JETS]: 'NYJ',
  [TEAMS.OAKLAND_RAIDERS]: 'OAK',
  [TEAMS.PHILADELPHIA_EAGLES]: 'PHI',
  [TEAMS.PITTSBURGH_STEELERS]: 'PIT',
  [TEAMS.SAN_DIEGO_CHARGERS]: 'SDG',
  [TEAMS.SAN_FRANCISCO_49ERS]: 'SFO',
  [TEAMS.SEATTLE_SEAHAWKS]: 'SEA',
  [TEAMS.ST_LOUIS_RAMS]: 'STL',
  [TEAMS.TAMPA_BAY_BUCCANEEERS]: 'TAM',
  [TEAMS.TENNESSEE_TITANS]: 'TEN',
  [TEAMS.WASHINGTON_REDSKINS]: 'WAS',
  [TEAMS.WASHINGTON_FOOTBALL_TEAM]: 'WAS',
  [TEAMS.LAS_VEGAS_RAIDERS]: 'LV',
  [TEAMS.LOS_ANGELES_CHARGERS]: 'LAC',
};

const ACTIVITY_TYPE = {
  GAME_CHECKIN: 'Game Checkin',
};

const STADIUM_3COM_PARK = '3Com Park';
const STADIUM_ARROWHEAD_STADIUM = 'Arrowhead Stadium';
const STADIUM_ATT_STADIUM = 'AT&T Stadium';
const STADIUM_BANK_OF_AMERICA = 'Bank of America Stadium';
const STADIUM_CANDLESTICK_PARK = 'Candlestick Park';
const STADIUM_CENTURYLINK_FIELD = 'CenturyLink Field';
const STADIUM_CLEVELAND_BROWNS_STADIUM = 'Cleveland Browns Stadium';
const STADIUM_COWBOYS_STADIUM = 'Cowboys Stadium';
const STADIUM_DIGNITY_HEALTH_SPORTS_PARK = 'Dignity Health Sports Park';
const STADIUM_DOLPHIN_STADIUM = 'Dolphin Stadium';
const STADIUM_DOME_AT_AMERICAS_CENTER = 'Dome at America\'s Center';
const STADIUM_EDWARD_JONES_DOME = 'Edward Jones Dome';
const STADIUM_ERICSSON_STADIUM = 'Ericsson Stadium';
const STADIUM_EVERBANK_FIELD = 'EverBank Field';
const STADIUM_FEDEX_FIELD = 'FedExField';
const STADIUM_FIRSTENERGY_STADIUM = 'FirstEnergy Stadium';
const STADIUM_FORD_FIELD = 'Ford Field';
const STADIUM_FOXBORO_STADIUM = 'Foxboro Stadium';
const STADIUM_GEORGIA_DOME = 'Georgia Dome';
const STADIUM_GIANTS_STADIUM = 'Giants Stadium';
const STADIUM_GILETTE_STADIUM = 'Gilette Stadium';
const STADIUM_HARD_ROCK_STADIUM = 'Hard Rock Stadium';
const STADIUM_HEINZ_FIELD = 'Heinz Field';
const STADIUM_HOUSTON_ASTRODOME = 'Houston Astrodome';
const STADIUM_HUBERT_H_HUMPHREY_METRODOME = 'Hubert H. Humphrey Metrodome';
const STADIUM_INVESCO_FIELD = 'Invesco Field at Mile High';
const STADIUM_JACK_KENT_COOKE_STADIUM = 'Jack Kent Cooke Stadium';
const STADIUM_JACKSONVILLE_MUNICIPAL_STADIUM = 'Jacksonville Municipal Stadium';
const STADIUM_LAMBEAU_FIELD = 'Lambeau Field';
const STADIUM_LEVIS_STADIUM = 'Levi\'s Stadium';
const STADIUM_LINCOLN_FINANCIAL_FIELD = 'Lincoln Financial Field';
const STADIUM_LOS_ANGELES_MEMORIAL_COLISEUM = 'Los Angeles Memorial Coliseum';
const STADIUM_LOUISIANA_SUPERDOME = 'Louisiana Superdome';
const STADIUM_LP_FIELD = 'LP Field';
const STADIUM_LUCAS_OIL_STADIUM = 'Lucas Oil Stadium';
const STADIUM_MALL_OF_AMERICA_FIELD = 'Mall of America Field';
const STADIUM_MEMORIAL_STADIUM_CLEMSON = 'Memorial Stadium (Clemson)';
const STADIUM_MERCEDES_BENZ_STADIUM = 'Mercedes-Benz Stadium';
const STADIUM_MERCEDES_BENZ_SUPERDOME = 'Mercedes-Benz Superdome';
const STADIUM_METLIFE_STADIUM_GIANTS = 'MetLife Stadium Giants';
const STADIUM_METLIFE_STADIUM_JETS = 'MetLife Stadium Jets';
const STADIUM_MILE_HIGH_STADIUM = 'Mile High Stadium';
const STADIUM_MT_BANK_STADIUM = 'M&T Bank Stadium';
const STADIUM_NETWORK_ASSOCIATES_COLISEUM = 'Network Associates Coliseum';
const STADIUM_NEW_ERA_FIELD = 'New Era Field';
const STADIUM_NEW_MEADOWLANDS_STADIUM = 'New Meadowlands Stadium';
const STADIUM_NISSAN_STADIUM = 'Nissan Stadium';
const STADIUM_NRG_STADIUM = 'NRG Stadium';
const STADIUM_OAKLAND_COLISEUM = 'Oakland-Alameda County Coliseum';
const STADIUM_PAUL_BROWN_STADIUM = 'Paul Brown Stadium';
const STADIUM_PRO_PLAYER_STADIUM = 'Pro Player Stadium';
const STADIUM_QUALCOMM_STADIUM = 'Qualcomm Stadium';
const STADIUM_QWEST_FIELD = 'Qwest Field';
const STADIUM_RALPH_WILSON_STADIUM = 'Ralph Wilson Stadium';
const STADIUM_RAYMOND_JAMES_STADIUM = 'Raymond James Stadium';
const STADIUM_RCA_DOME = 'RCA Dome';
const STADIUM_RELIANT_STADIUM = 'Reliant Stadium';
const STADIUM_RICH_STADIUM = 'Rich Stadium';
const STADIUM_ROBERT_F_KENNEDY_MEMORIAL_STADIUM = 'Robert F. Kennedy Memorial Stadium';
const STADIUM_SOLDIER_FIELD = 'Soldier Field';
const STADIUM_SPORTS_AUTHORITY_STADIUM = 'Sports Authority Field at Mile High';
const STADIUM_STATE_FARM_STADIUM = 'State Farm Stadium';
const STADIUM_SUN_DEVIL_STADIUM = 'Sun Devil Stadium';
const STADIUM_SUN_LIFE_STADIUM = 'Sun Life Stadium';
const STADIUM_TCF_BANK_STADIUM = 'TCF Bank Stadium';
const STADIUM_TEXAS_STADIUM = 'Texas Stadium';
const STADIUM_THREE_RIVERS_STADIUM = 'Three Rivers Stadium';
const STADIUM_TIAA_BANK_FIELD = 'TIAA Bank Field';
const STADIUM_TIGER_STADIUM_LSU = 'Tiger Stadium (LSU)';
const STADIUM_TOTTENHAM_HOTSPUR_STADIUM = 'Tottenham Stadium';
const STADIUM_TWA_DOME = 'TWA Dome';
const STADIUM_UNIVERSITY_OF_PHOENIX_STADIUM = 'University of Phoenix Stadium';
const STADIUM_US_BANK_STADIUM = 'US Bank Stadium';
const STADIUM_VETERANS_STADIUM = 'Veterans Stadium';
const STADIUM_HIGHMARK_STADIUM = 'Highmark Stadium';
const STADIUM_EMPOWER_FIELD = 'Empower Field at Mile High';
const STADIUM_SOFI_STADIUM = 'SoFi Stadium';
const STADIUM_ALLEGIANT_STADIUM = 'Allegiant Stadium';

// Map stadium name to img

const STADIUM_NAME_MAPPING = {
  [STADIUM_3COM_PARK]: STADIUM_IMG_3COM_PARK,
  [STADIUM_ARROWHEAD_STADIUM]: STADIUM_IMG_ARROWHEAD_STADIUM,
  [STADIUM_ATT_STADIUM]: STADIUM_IMG_ATT_STADIUM,
  [STADIUM_BANK_OF_AMERICA]: STADIUM_IMG_BANK_OF_AMERICA,
  [STADIUM_CANDLESTICK_PARK]: STADIUM_IMG_3COM_PARK,
  [STADIUM_CENTURYLINK_FIELD]: STADIUM_IMG_CENTURYLINK_FIELD,
  [STADIUM_CLEVELAND_BROWNS_STADIUM]: STADIUM_IMG_FIRSTENERGY_STADIUM,
  [STADIUM_COWBOYS_STADIUM]: STADIUM_IMG_ATT_STADIUM,
  [STADIUM_DIGNITY_HEALTH_SPORTS_PARK]: STADIUM_IMG_DIGNITY_HEALTH_SPORTS_PARK,
  [STADIUM_DOLPHIN_STADIUM]: STADIUM_IMG_HARD_ROCK_STADIUM,
  [STADIUM_DOME_AT_AMERICAS_CENTER]: STADIUM_IMG_TWA_DOME,
  [STADIUM_EDWARD_JONES_DOME]: STADIUM_IMG_TWA_DOME,
  [STADIUM_ERICSSON_STADIUM]: STADIUM_IMG_BANK_OF_AMERICA,
  [STADIUM_EVERBANK_FIELD]: STADIUM_IMG_TIAA_BANK_FIELD,
  [STADIUM_FEDEX_FIELD]: STADIUM_IMG_FEDEX_FIELD,
  [STADIUM_FIRSTENERGY_STADIUM]: STADIUM_IMG_FIRSTENERGY_STADIUM,
  [STADIUM_FORD_FIELD]: STADIUM_IMG_FORD_FIELD,
  [STADIUM_FOXBORO_STADIUM]: STADIUM_IMG_GILETTE_STADIUM,
  [STADIUM_GEORGIA_DOME]: STADIUM_IMG_GEORGIA_DOME,
  [STADIUM_GIANTS_STADIUM]: STADIUM_IMG_GIANTS_STADIUM,
  [STADIUM_GILETTE_STADIUM]: STADIUM_IMG_GILETTE_STADIUM,
  [STADIUM_HARD_ROCK_STADIUM]: STADIUM_IMG_HARD_ROCK_STADIUM,
  [STADIUM_HEINZ_FIELD]: STADIUM_IMG_HEINZ_FIELD,
  [STADIUM_HOUSTON_ASTRODOME]: STADIUM_IMG_HOUSTON_ASTRODOME,
  [STADIUM_HUBERT_H_HUMPHREY_METRODOME]: STADIUM_IMG_HUBERT_H_HUMPHREY_METRODOME,
  [STADIUM_INVESCO_FIELD]: STADIUM_IMG_EMPOWER_FIELD,
  [STADIUM_JACK_KENT_COOKE_STADIUM]: STADIUM_IMG_FEDEX_FIELD,
  [STADIUM_JACKSONVILLE_MUNICIPAL_STADIUM]: STADIUM_IMG_TIAA_BANK_FIELD,
  [STADIUM_LAMBEAU_FIELD]: STADIUM_IMG_LAMBEAU_FIELD,
  [STADIUM_LEVIS_STADIUM]: STADIUM_IMG_LEVIS_STADIUM,
  [STADIUM_LINCOLN_FINANCIAL_FIELD]: STADIUM_IMG_LINCOLN_FINANCIAL_FIELD,
  [STADIUM_LOS_ANGELES_MEMORIAL_COLISEUM]: STADIUM_IMG_LOS_ANGELES_MEMORIAL_COLISEUM,
  [STADIUM_LOUISIANA_SUPERDOME]: STADIUM_IMG_MERCEDES_BENZ_SUPERDOME,
  [STADIUM_LP_FIELD]: STADIUM_IMG_NISSAN_STADIUM,
  [STADIUM_LUCAS_OIL_STADIUM]: STADIUM_IMG_LUCAS_OIL_STADIUM,
  [STADIUM_MALL_OF_AMERICA_FIELD]: STADIUM_IMG_HUBERT_H_HUMPHREY_METRODOME,
  [STADIUM_MEMORIAL_STADIUM_CLEMSON]: STADIUM_IMG_MEMORIAL_STADIUM_CLEMSON,
  [STADIUM_MERCEDES_BENZ_STADIUM]: STADIUM_IMG_MERCEDES_BENZ_STADIUM,
  [STADIUM_MERCEDES_BENZ_SUPERDOME]: STADIUM_IMG_MERCEDES_BENZ_SUPERDOME,
  [STADIUM_METLIFE_STADIUM_GIANTS]: STADIUM_IMG_METLIFE_STADIUM_GIANTS,
  [STADIUM_METLIFE_STADIUM_JETS]: STADIUM_IMG_METLIFE_STADIUM_JETS,
  [STADIUM_MILE_HIGH_STADIUM]: STADIUM_IMG_EMPOWER_FIELD,
  [STADIUM_MT_BANK_STADIUM]: STADIUM_IMG_MT_BANK_STADIUM,
  [STADIUM_NEW_ERA_FIELD]: STADIUM_IMG_HIGHMARK_STADIUM,
  [STADIUM_NEW_MEADOWLANDS_STADIUM]: STADIUM_IMG_METLIFE_STADIUM_GIANTS,
  [STADIUM_NISSAN_STADIUM]: STADIUM_IMG_NISSAN_STADIUM,
  [STADIUM_NRG_STADIUM]: STADIUM_IMG_NRG_STADIUM,
  [STADIUM_OAKLAND_COLISEUM]: STADIUM_IMG_OAKLAND_COLISEUM,
  [STADIUM_PAUL_BROWN_STADIUM]: STADIUM_IMG_PAUL_BROWN_STADIUM,
  [STADIUM_PRO_PLAYER_STADIUM]: STADIUM_IMG_HARD_ROCK_STADIUM,
  [STADIUM_QUALCOMM_STADIUM]: STADIUM_IMG_QUALCOMM_STADIUM,
  [STADIUM_QWEST_FIELD]: STADIUM_IMG_CENTURYLINK_FIELD,
  [STADIUM_RALPH_WILSON_STADIUM]: STADIUM_IMG_HIGHMARK_STADIUM,
  [STADIUM_RAYMOND_JAMES_STADIUM]: STADIUM_IMG_RAYMOND_JAMES_STADIUM,
  [STADIUM_RCA_DOME]: STADIUM_IMG_RCA_DOME,
  [STADIUM_RELIANT_STADIUM]: STADIUM_IMG_NRG_STADIUM,
  [STADIUM_RICH_STADIUM]: STADIUM_IMG_HIGHMARK_STADIUM,
  [STADIUM_ROBERT_F_KENNEDY_MEMORIAL_STADIUM]: STADIUM_IMG_RFK_STADIUM,
  [STADIUM_SOLDIER_FIELD]: STADIUM_IMG_SOLDIER_FIELD,
  [STADIUM_SPORTS_AUTHORITY_STADIUM]: STADIUM_IMG_EMPOWER_FIELD,
  [STADIUM_STATE_FARM_STADIUM]: STADIUM_IMG_STATE_FARM_STADIUM,
  [STADIUM_SUN_DEVIL_STADIUM]: STADIUM_IMG_SUN_DEVIL_STADIUM,
  [STADIUM_SUN_LIFE_STADIUM]: STADIUM_IMG_HARD_ROCK_STADIUM,
  [STADIUM_TCF_BANK_STADIUM]: STADIUM_IMG_TCF_BANK_STADIUM,
  [STADIUM_TEXAS_STADIUM]: STADIUM_IMG_TEXAS_STADIUM,
  [STADIUM_THREE_RIVERS_STADIUM]: STADIUM_IMG_THREE_RIVERS_STADIUM,
  [STADIUM_TIAA_BANK_FIELD]: STADIUM_IMG_TIAA_BANK_FIELD,
  [STADIUM_TIGER_STADIUM_LSU]: STADIUM_IMG_TIGER_STADIUM_LSU,
  [STADIUM_TOTTENHAM_HOTSPUR_STADIUM]: STADIUM_IMG_TOTTENHAM_HOTSPUR_STADIUM,
  [STADIUM_TWA_DOME]: STADIUM_IMG_TWA_DOME,
  [STADIUM_UNIVERSITY_OF_PHOENIX_STADIUM]: STADIUM_IMG_STATE_FARM_STADIUM,
  [STADIUM_US_BANK_STADIUM]: STADIUM_IMG_US_BANK_STADIUM,
  [STADIUM_VETERANS_STADIUM]: STADIUM_IMG_VETERANS_STADIUM,
  [STADIUM_HIGHMARK_STADIUM]: STADIUM_IMG_HIGHMARK_STADIUM,
  [STADIUM_EMPOWER_FIELD]: STADIUM_IMG_EMPOWER_FIELD,
  [STADIUM_SOFI_STADIUM]: STADIUM_IMG_SOFI_STADIUM,
  [STADIUM_ALLEGIANT_STADIUM]: STADIUM_IMG_ALLEGIANT_STADIUM,
};

// Map full team name to list of stadiums in chronological order
const TEAM_STADIUM_MAPPING = {
  [TEAMS.CAROLINA_PANTHERS]: [
    STADIUM_BANK_OF_AMERICA,
    STADIUM_ERICSSON_STADIUM,
    STADIUM_MEMORIAL_STADIUM_CLEMSON,
  ],
  [TEAMS.ARIZONA_CARDINALS]: [
    STADIUM_STATE_FARM_STADIUM,
    STADIUM_UNIVERSITY_OF_PHOENIX_STADIUM,
    STADIUM_SUN_DEVIL_STADIUM,
  ],
  [TEAMS.ATLANTA_FALCONS]: [
    STADIUM_MERCEDES_BENZ_STADIUM,
    STADIUM_GEORGIA_DOME,
  ],
  [TEAMS.BALTIMORE_RAVENS]: [
    STADIUM_MT_BANK_STADIUM,
  ],
  [TEAMS.BUFFALO_BILLS]: [
    STADIUM_HIGHMARK_STADIUM,
    STADIUM_NEW_ERA_FIELD,
    STADIUM_RALPH_WILSON_STADIUM,
    STADIUM_RICH_STADIUM,
  ],
  [TEAMS.CHICAGO_BEARS]: [
    STADIUM_SOLDIER_FIELD,
  ],
  [TEAMS.CINCINNATI_BENGALS]: [
    STADIUM_PAUL_BROWN_STADIUM,
  ],
  [TEAMS.CLEVELAND_BROWNS]: [
    STADIUM_FIRSTENERGY_STADIUM,
    STADIUM_CLEVELAND_BROWNS_STADIUM,
  ],
  [TEAMS.DALLAS_COWBOYS]: [
    STADIUM_ATT_STADIUM,
    STADIUM_COWBOYS_STADIUM,
    STADIUM_TEXAS_STADIUM,
  ],
  [TEAMS.DENVER_BRONCOS]: [
    STADIUM_EMPOWER_FIELD,
    STADIUM_SPORTS_AUTHORITY_STADIUM,
    STADIUM_INVESCO_FIELD,
    STADIUM_MILE_HIGH_STADIUM,
  ],
  [TEAMS.DETROIT_LIONS]: [
    STADIUM_FORD_FIELD,
  ],
  [TEAMS.GREEN_BAY_PACKERS]: [
    STADIUM_LAMBEAU_FIELD,
  ],
  [TEAMS.HOUSTON_OILERS]: [
    STADIUM_HOUSTON_ASTRODOME,
  ],
  [TEAMS.HOUSTON_TEXANS]: [
    STADIUM_NRG_STADIUM,
    STADIUM_RELIANT_STADIUM,
  ],
  [TEAMS.INDIANAPOLIS_COLTS]: [
    STADIUM_LUCAS_OIL_STADIUM,
    STADIUM_RCA_DOME,
  ],
  [TEAMS.JACKSONVILLE_JAGUARS]: [
    STADIUM_TIAA_BANK_FIELD,
    STADIUM_EVERBANK_FIELD,
    STADIUM_JACKSONVILLE_MUNICIPAL_STADIUM,
  ],
  [TEAMS.KANSAS_CITY_CHIEFS]: [
    STADIUM_ARROWHEAD_STADIUM,
  ],
  [TEAMS.LOS_ANGELES_RAMS]: [
    STADIUM_SOFI_STADIUM,
    STADIUM_LOS_ANGELES_MEMORIAL_COLISEUM,
  ],
  [TEAMS.MIAMI_DOLPHINS]: [
    STADIUM_HARD_ROCK_STADIUM,
    STADIUM_SUN_LIFE_STADIUM,
    STADIUM_DOLPHIN_STADIUM,
    STADIUM_PRO_PLAYER_STADIUM,
  ],
  [TEAMS.MINNESOTA_VIKINGS]: [
    STADIUM_US_BANK_STADIUM,
    STADIUM_TCF_BANK_STADIUM,
    STADIUM_MALL_OF_AMERICA_FIELD,
    STADIUM_HUBERT_H_HUMPHREY_METRODOME,
  ],
  [TEAMS.NEW_ENGLAND_PATRIOTS]: [
    STADIUM_GILETTE_STADIUM,
    STADIUM_FOXBORO_STADIUM,
  ],
  [TEAMS.NEW_ORLEANS_SAINTS]: [
    STADIUM_MERCEDES_BENZ_SUPERDOME,
    STADIUM_TIGER_STADIUM_LSU,
    STADIUM_LOUISIANA_SUPERDOME,
  ],
  [TEAMS.NEW_YORK_GIANTS]: [
    STADIUM_METLIFE_STADIUM_GIANTS,
    STADIUM_NEW_MEADOWLANDS_STADIUM,
    STADIUM_GIANTS_STADIUM,
  ],
  [TEAMS.NEW_YORK_JETS]: [
    STADIUM_METLIFE_STADIUM_JETS,
    STADIUM_GIANTS_STADIUM,
  ],
  [TEAMS.OAKLAND_RAIDERS]: [
    STADIUM_OAKLAND_COLISEUM,
    STADIUM_NETWORK_ASSOCIATES_COLISEUM,
  ],
  [TEAMS.LAS_VEGAS_RAIDERS]: [
    STADIUM_ALLEGIANT_STADIUM,
  ],
  [TEAMS.PHILADELPHIA_EAGLES]: [
    STADIUM_LINCOLN_FINANCIAL_FIELD,
    STADIUM_VETERANS_STADIUM,
  ],
  [TEAMS.PITTSBURGH_STEELERS]: [
    STADIUM_HEINZ_FIELD,
    STADIUM_THREE_RIVERS_STADIUM,
  ],
  [TEAMS.LOS_ANGELES_CHARGERS]: [
    STADIUM_SOFI_STADIUM,
  ],
  [TEAMS.SAN_DIEGO_CHARGERS]: [
    STADIUM_QUALCOMM_STADIUM,
  ],
  [TEAMS.SAN_FRANCISCO_49ERS]: [
    STADIUM_LEVIS_STADIUM,
    STADIUM_CANDLESTICK_PARK,
    STADIUM_3COM_PARK,
  ],
  [TEAMS.SEATTLE_SEAHAWKS]: [
    STADIUM_CENTURYLINK_FIELD,
    STADIUM_QWEST_FIELD,
  ],
  [TEAMS.ST_LOUIS_RAMS]: [
    STADIUM_EDWARD_JONES_DOME,
    STADIUM_DOME_AT_AMERICAS_CENTER,
    STADIUM_TWA_DOME,
  ],
  [TEAMS.TAMPA_BAY_BUCCANEEERS]: [
    STADIUM_RAYMOND_JAMES_STADIUM,
  ],
  [TEAMS.TENNESSEE_TITANS]: [
    STADIUM_NISSAN_STADIUM,
    STADIUM_LP_FIELD,
  ],
  [TEAMS.WASHINGTON_REDSKINS]: [
    STADIUM_FEDEX_FIELD,
    STADIUM_JACK_KENT_COOKE_STADIUM,
    STADIUM_ROBERT_F_KENNEDY_MEMORIAL_STADIUM,
  ],
};

export {
  ACTIVITY_TYPE,
  TEAMS,
  TEAM_ABBREVIATION_MAP,

  // Stadiums
  STADIUM_NAME_MAPPING,
  TEAM_STADIUM_MAPPING,
  STADIUM_BANK_OF_AMERICA,
};
